import React from "react";
import emailimg from "../../assets/img/email.gif";

const Thankyou = () => {
  return (
    <section className="bg-white vh-lg-100 ">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center mt-4">
            <img style={{ width: "290px" }} src={emailimg} alt="" />
          </div>
          <div className="col-12" style={{ textAlign: "center" }}>
            <h3 className="mt-2 font-fam">
              We will send you instruction for resetting your password.{" "}
            </h3>
            <p className="mt-3 font-fam">
              Please check your email and click on the provided link to reset
              your password
            </p>

            <a
              href="/signin"
              style={{ fontSize: "18px" }}
              class="btn btn-primary mt-4"
            >
              Close
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Thankyou;
