import PropTypes from 'prop-types';
import React, { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Avatar,
  Typography,
  AvatarGroup,
  IconButton,
  Tooltip,
} from '@mui/material';
// utils
import Iconify from '../../../components/Iconify';
import { useDispatch } from 'react-redux';
import { toggleStatus } from '../../../redux/actions/chat';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../routes';
import { capitalize, startCase } from 'lodash';
import ConfirmModal from '../../../components/confirmModal/ConfirmModal';
import createAvatar from '../../../utils/createAvatar';
import ButtonPrimary from '../../../components/ButtonPrimary';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  flexShrink: 0,
  minHeight: 92,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
}));

// ----------------------------------------------------------------------

export default function ChatHeaderDetail({ conversation, openOnly }) {
  const [openConfirm, setOpenConfirm] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const toggleConfirm = () => {
    setOpenConfirm((prev) => !prev);
  };
  const { status } = conversation;
  const updateStatus = async () => {
    try {
      dispatch(
        toggleStatus(status === 'open' ? 'closed' : 'open', conversation.id)
      );
      toast.success('Status Updated Successfully');
      toggleConfirm();
      if (openOnly) {
        history.push(Routes.UserChatGeneral.path);
      }
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error(error?.message ?? 'Status update failed!');
    }
  };

  return (
    <RootStyle>
      <OneAvatar user={conversation?.user?.name || 'User'} subject={conversation.subject} />

      <Box sx={{ flexGrow: 1 }} />
      <ButtonPrimary onClick={toggleConfirm}>
        {status === 'closed' ? 'Reopen Ticket' : 'Close Ticket' }
      </ButtonPrimary>

      <ConfirmModal
        open={openConfirm}
        onClose={toggleConfirm}
        title={status === 'open' ? 'Close' : 'Reopen'}
        content={
          <>
            Are you sure want to{' '}
            <strong> {status === 'open' ? 'close' : 'reopen'} </strong> the
            ticket?
          </>
        }
        action={
          <ButtonPrimary onClick={updateStatus}>
            {status === 'open' ? 'Close' : 'Reopen'}
          </ButtonPrimary>
        }
      />
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

function OneAvatar({ user, subject }) {
  const name = startCase(user);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ position: 'relative' }}>
        <Avatar alt={name} sx={{ mr: 1, bgcolor: createAvatar(name).color }}>
          {createAvatar(name).name}
        </Avatar>
      </Box>
      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2">{capitalize(name)}</Typography>
        <Typography variant="body2" color={'textSecondary'}>
          {subject}
        </Typography>
      </Box>
    </Box>
  );
}

// ----------------------------------------------------------------------
