import React from 'react'
import { Typography } from "@mui/material";
const StyledTitle = ({children}) => {
    return (
        <Typography
          variant="subtitle2"
          sx={{
            wordBreak: { sm: "break-all", md: "initial" },
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {children}
        </Typography>
      );
}

export default StyledTitle