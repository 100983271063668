import React from "react";
import { ResponsiveContainer, AreaChart, XAxis, Area, Tooltip } from "recharts";
import CustomTooltip from "../CustomTooltip";

// Sample data format needed for chart
// const receiptChartData = [
//   { name: 'a', value: 10 },
//   { name: 'b', value: 20 },
//   { name: 'c', value: 15 },
//   { name: 'd', value: 30 },
// ];

const AreaChartComp = ({ bottom, data, label, height = "80%" }) => {
  return (
    <ResponsiveContainer height={height} width="100%">
      <AreaChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom,
        }}
      >
        <XAxis dataKey="name" />
        <Tooltip content={<CustomTooltip valueLabel={label} />} />
        <Area
          type="monotone"
          dataKey="value"
          strokeWidth={4}
          stroke="#00c5cc"
          fill="#00f6ff"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartComp;
