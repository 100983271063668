import React, { useState, useEffect } from "react";
import * as yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";

import { Dialog, Stack, Chip, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PreviewContainer from "../../../../../components/preview/PreviewContainer";
import { formatCurrency } from "../../../../../utils/common";
import ButtonSecondary from "../../../../../components/ButtonSecondary";
import ButtonPrimary from "../../../../../components/ButtonPrimary";
import { format } from "date-fns";

function RefundSummaryDialog({
  open,
  onClose: handleClose,
  loading,
  refundRes
}) {


    console.log('dasdasasasdasdsa', refundRes)




  return (
    <>
      <Dialog
        open={open}
        maxWidth={"xs"}
        onClose={handleClose}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
            width: "628px",
            minHeight: "450px",
            maxHeight: "90vh",
          },
        }}
      >
        <DialogTitle className="text-center">
          <p className="dialog-title">Refund Processed</p>
        </DialogTitle>

       
          <DialogContent>
          <Stack mb={4}>
              <Typography variant="subtitle2">
              Customer will receive refund within [7] working days
              </Typography>
            </Stack>
            <Stack direction={"column"} mb={4}>
              <PreviewContainer>
                <Stack gap={2}>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle2">
                     Date Refunded
                    </Typography>
                    <Typography variant="subtitle2">
                      {format(new Date(refundRes?.created_date), "dd/MM/yyyy")}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle2">ID</Typography>
                    <Typography variant="subtitle2">
                      {refundRes?.id}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle2">Status</Typography>
                    {/* <Typography variant="subtitle2"> */}
                 <Chip
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    backgroundColor: "#FFF7CD",
                    color: "rgb(122, 79, 1)",
                  }}
                 
                  color="warning"
                  label="Pending"
                />
                    {/* </Typography> */}
                  </Stack>
                </Stack>
              </PreviewContainer>
            </Stack>
            <Stack direction={"column"}>
              
              <PreviewContainer>
                <Stack gap={2}>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle2">Refund Amount</Typography>
                    <Typography variant="subtitle2">
                      {formatCurrency(
                       refundRes.receive_amount,
                        refundRes?.receiver_coin?.decimal_place,
                        refundRes?.receiver_coin?.symbol,
                        refundRes?.receiver_coin?.symbol_prefix
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle2">Processing Fee</Typography>
                    <Typography variant="subtitle2">
                      {formatCurrency(
                        0,
                        refundRes?.receiver_coin?.decimal_place,
                        refundRes?.receiver_coin?.symbol,
                        refundRes?.receiver_coin?.symbol_prefix
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle2">STB Fee</Typography>
                    <Typography variant="subtitle2">
                      {formatCurrency(
                        0,
                        refundRes?.receiver_coin?.decimal_place,
                        refundRes?.receiver_coin?.symbol,
                        refundRes?.receiver_coin?.symbol_prefix
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      Total Refund Amount
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      {formatCurrency(
                       refundRes.receive_amount,
                        refundRes?.receiver_coin?.decimal_place,
                        refundRes?.receiver_coin?.symbol,
                        refundRes?.receiver_coin?.symbol_prefix
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              </PreviewContainer>
            </Stack>
         
          </DialogContent>
            <DialogActions>
             
              <ButtonPrimary
            onClick={handleClose}
                loading={loading}
              >
                Close
              </ButtonPrimary>
            </DialogActions>
      </Dialog>
    </>
  );
}

export default RefundSummaryDialog;
