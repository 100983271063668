import React, { useState, useEffect } from "react";
import { Button } from "rsuite";
import { useHistory } from "react-router-dom";
import { Row, Col } from "@themesberg/react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import "react-phone-input-2/lib/style.css";
import { MenuItem } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers";
import * as API from "../../ApiUrl";
import { useSelector } from "react-redux";
import {
  ADMIN,
  CASHIER,
  MANAGER,
  handleBackgroundClick,
  numricRegex,
  removeCodeFromPhone,
  textFieldLabelProps,
} from "../../utils/common";
import { merchantApi } from "../../redux/api/apiConfig";
import { format, isBefore, subYears } from "date-fns";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import ButtonSecondary from "../../components/ButtonSecondary";
import ButtonPrimary from "../../components/ButtonPrimary";

const AddSystemUserModal = ({ onClose, isOpen, onUserAdd }) => {
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [countryCode, setCountryCode] = useState("")
  const [userRole, setUserRole] = useState([]);
  const [userStore, setUserStore] = useState([]);
  const [userCounters, setUserCounters] = useState([]);
  const [showStores, setShowStores] = useState(false);
  const [showCounters, setShowCounters] = useState(false);
  const [docTypes, setDocTypes] = useState([]);
  const [countryList] = useState(
    JSON.parse(localStorage.getItem("country-list")) || []
  );
  const [phoneCountry, setPhoneCountry] = useState({
    countryCode: 'ca',
    dialCode: '1'
  })

  const validationSchema = yup.object({
    first_name: yup
      .string("Enter your first name")
      .min(2, "Please enter valid name")
      .required("First Name is required!"),
  
    last_name: yup
      .string("Enter your last name")
      .min(2, "Please enter valid name")
      .required("Last Name is required!"),
  
    email: yup
      .string("Enter your email")
      .email("Please enter valid email")
      .required("Email is required!"),
  
      phone_number: yup
      .string("Enter your phonenumber")
      .matches(numricRegex, "Only Number Required")
      .test("len", "Enter Valid Number", phone => {
        return removeCodeFromPhone(phone, phoneCountry.dialCode)?.length === 10;
      })
      .required("Phone Number is required"),
  
      dob: yup
      .date("Enter a valid date")
      .max(new Date(), "Date of birth cannot be in the future")
      .required("Date of birth is required")
      .nullable()
      .test("age", "Must be at least 16 years old", function (value) {
        // 'value' is the parsed date object
        const currentDate = new Date();
        const userDOB = new Date(value);
    
        // Check if the user is at least 16 years old
        return isBefore(userDOB, subYears(currentDate, 16));
      }).nullable(),
    user_role: yup.string("Select user").required("User role is required"),
    store: yup.string("Select store").when("user_role", {
      is: (userRole) => [CASHIER, MANAGER].includes(userRole),
      then: yup.string("Select store").required("Store is required"),
    }),
    counter: yup.string("Select counter").when("user_role", {
      is: CASHIER,
      then: yup.string("Select counter").required("Counter is required"),
    }),
    document_type: yup
      .string("Select document type")
      .required("Document type is required"),
    document_number: yup
      .string("Select document number")
      .required("Document number is required"),
  });

  const curRole = useSelector((store) => store.authReducer.userRole);

  const onSubmit = async (values) => {
    const { ...data } = values;
    data.dial_code = phoneCountry.dialCode;
    data.country_code = phoneCountry.countryCode;
    data.dob = format(data.dob, "yyyy-MM-dd");
    data.phone_number = removeCodeFromPhone(values.phone_number, phoneCountry.dialCode);
    if (data.user_role === "administrator") {
      delete data["store"];
      delete data["counter"];
    }
    else if (data.user_role === "manager") {
      delete data["counter"];
    }
    setloading(true);

    try {
      const response = await merchantApi.post(`${API.USER}`, data);
      toast.success("User created successfully");
      onUserAdd();
      formik.resetForm();
      onClose();

      history.push("/administration/systemUser");
    } catch (error) {
      setloading(false);
      if (typeof error?.response?.data === 'object' && error?.response?.status === 400) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong');
      }
    }
    setloading(false);
  };

  const onChangeStore = async (e) => {
    setloading(true);
    const response = await merchantApi.get(
      `${API.COUNTER}?store=${e.target.value}`
    );
    if (response?.data?.results) {
      setUserCounters(response.data.results);
    }
    setloading(false);
  };

  const getUserStores = async () => {
    setloading(true);
    const response = await merchantApi.get(`${API.STORE_LIST}`);
    if (response?.data?.results) {
      setUserStore(response.data.results);
    }
    setloading(false);
  };

  const onChangeRole = (e) => {

    formik.setFieldValue("store","")
    formik.setFieldValue("counter","")
    if(e.target.value === ADMIN){
      setShowStores(false);
      setShowCounters(false);
    }
    else if (e.target.value === MANAGER) {
      setShowStores(true);
      setShowCounters(false);
    } else {
      setShowStores(true);
      setShowCounters(true);
    }
  };

  const getUserRole = () => {
    merchantApi.get(`${API.USER_ROLE}`).then((res) => {
      let roles;
      if (curRole === ADMIN) {
        roles = res.data.data;
      } else if (curRole === MANAGER) {
        roles = res.data.data.filter((role) => role.value === CASHIER);
      } else {
        roles = [];
      }
      setUserRole(roles);
    });
  };

  const getDocTypes = async () => {
    const response = await merchantApi.get(`${API.GET_DOC_TYPES}`);
    setDocTypes(response.data.data.doc_types);
  };

  useEffect(() => {
    getUserRole();
    getDocTypes();
    getUserStores();
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      user_role: "",
      store: "",
      counter: "",
      dob: null,
      document_type: "",
      document_number: "",
    },
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleClose = (event, reason) => {
    handleBackgroundClick(reason, onClose, false);
  };


  return (
    <Dialog
      open={isOpen}
      maxWidth={"sm"}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
          width: "628px",
          minHeight: "550px",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle className="text-center">
        <p className="dialog-title">Create System User Account</p>
        <p className="">
          Please make sure all the required field is carefully filled with valid
          information.
        </p>
      </DialogTitle>
      <hr className="hr" />
      <form onSubmit={() => {}}>
        <DialogContent className="flex-col">
          <Row>
            <Col xl={6} xs={12}>
              <TextField
                // onchange={(e) => handleChange(e)}
                // autoFocus
                required
                className="w-100 mb-4"
                variant="outlined"
                // value={data.name}
                name="first_name"
                id="first_name"
                label="First Name"
                InputLabelProps={textFieldLabelProps}
                type="text"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                // onChange={(e) => handleChange(e)}
                required
                className="w-100 mb-4"
                variant="outlined"
                name="last_name"
                id="last_name"
                label="Last Name"
                // placeholder="last_name"
                InputLabelProps={textFieldLabelProps}
                type="text"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                // onChange={(e) => handleChange(e)}
                required
                className="w-100 mb-4"
                variant="outlined"
                name="email"
                id="email"
                label="Email"
                // placeholder="email"
                InputLabelProps={textFieldLabelProps}
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Col>
            <Col xl={6} xs={12} className="mb-4">
            <PhoneNumberInput
              formik={formik}
              label={"Phone Number"}
              name={"phone_number"}
              setPhoneCountry={setPhoneCountry}
              onlyCountries={
                countryList.length > 0
                  ? _.map(countryList, (country) => {
                      return country?.alpha2code?.toLowerCase();
                    })
                  : []
              }
              country={phoneCountry?.countryCode}
            />
            
            </Col>
            <Col xl={6} xs={12}>
              <DatePicker
                fullWidth
                disableFuture
                format={"dd/MM/yyyy"}
                className="mb-4 bg-white"
                id={"dob"}
                name="dob"
                value={formik.values.dob}
                onChange={(date) => {
                  formik.setFieldTouched("dob", true);
                  formik.setFieldValue("dob", date);
                }}
                slots={{
                  textField: TextField,
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    fullWidth: true,
                    error:
                      formik.touched.dob && Boolean(formik.errors.dob),
                    helperText: formik.touched.dob && formik.errors.dob,
                  },
                }}
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                select
                fullWidth
                variant="outlined"
                className="mb-4 bg-white"
                id="document_type"
                name="document_type"
                InputLabelProps={textFieldLabelProps}
                label="Document Type"
                value={formik.values.document_type}
                onChange={(event) => {
                  formik.handleChange(event);
                }}
                onBlur={(event) => {
                  formik.handleChange(event);
                }}
                error={
                  formik.touched.document_type &&
                  Boolean(formik.errors.document_type)
                }
                helperText={
                  formik.touched.document_type && formik.errors.document_type
                }
              >
                {docTypes.map((doctype) => (
                  <MenuItem value={doctype.value}>{doctype.name}</MenuItem>
                ))}
              </TextField>
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                // onChange={(e) => handleChange(e)}
                required
                className="w-100 mb-4"
                variant="outlined"
                name="document_number"
                id="document_number"
                label="Document Number"
                // placeholder="document_number"
                InputLabelProps={textFieldLabelProps}
                type="text"
                value={formik.values.document_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.document_number &&
                  Boolean(formik.errors.document_number)
                }
                helperText={
                  formik.touched.document_number &&
                  formik.errors.document_number
                }
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                select
                required
                className="w-100 mb-4"
                variant="outlined"
                // onChange={(e) => handleChange(e)}
                name="user_role"
                id="user_role"
                // value={data.address}

                InputLabelProps={textFieldLabelProps}
                label="User Role"
                type="text"
                value={formik.values.user_role}
                onChange={(e) => {
                  onChangeRole(e);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.user_role && Boolean(formik.errors.user_role)
                }
                helperText={formik.touched.user_role && formik.errors.user_role}
              >
                {userRole.map((role, i) => (
                  <MenuItem value={role.value}>{role.name}</MenuItem>
                ))}
              </TextField>
            </Col>
            {!!showStores && (
              <Col xl={12}>
                <TextField
                  select
                  required
                  className="w-100 mb-4"
                  variant="outlined"
                  // onChange={(e) => handleChange(e)}
                  name="store"
                  id="store"
                  value={formik.values.store}
                  InputLabelProps={textFieldLabelProps}
                  label="Store"
                  type="text"
                  onChange={(e) => {
                    onChangeStore(e);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.store && Boolean(formik.errors.store)}
                  helperText={formik.touched.store && formik.errors.store}
                >
                  {userStore.map((store, i) => (
                    <MenuItem value={store.id}>{`${_.startCase(store?.name)} (${_.startCase(store?.city)})`}</MenuItem>
                  ))}
                </TextField>
              </Col>
            )}
            {!!showCounters && (
              <Col xl={12}>
                <TextField
                  select
                  required
                  className="w-100 mb-4"
                  variant="outlined"
                  // onChange={(e) => handleChange(e)}
                  name="counter"
                  id="counter"
                  value={formik.values.counter}
                  InputLabelProps={textFieldLabelProps}
                  label="Counter"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.counter && Boolean(formik.errors.counter)
                  }
                  helperText={formik.touched.counter && formik.errors.counter}
                >
                  {userCounters.map((counter, i) => (
                    <MenuItem value={counter.id}>{counter.name}</MenuItem>
                  ))}
                </TextField>
              </Col>
            )}
          </Row>
        </DialogContent>
        <hr className="hr" />
        <DialogActions
        sx={{
          paddingX: "24px",
          paddingBottom: "15px",
          gap: 1
        }}
      >
          <ButtonSecondary
            onClick={onClose}
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary
            loading={loading}
            onClick={formik.handleSubmit}
          >
            {!loading ? "Create User" : "loading..."}
          </ButtonPrimary>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddSystemUserModal;
