import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { Col, Row } from "@themesberg/react-bootstrap";
import _ from "lodash";
import {
  InputAdornment,
  TextField,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import InfoRounded from "@material-ui/icons/InfoRounded";
import { useFormik } from "formik";
import * as yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  handleBackgroundClick,
  numricRegex,
  removeCodeFromPhone,
  textFieldLabelProps,
} from "../../../../../utils/common";
import PhoneNumberInput from "../../../../../components/PhoneNumberInput";
import ButtonSecondary from "../../../../../components/ButtonSecondary";
import ButtonPrimary from "../../../../../components/ButtonPrimary";



function AddStore({ open, handleaddStore, onClose, loading, error }) {

  const [countryCode, setCountryCode] = useState(null); 
  const [phoneCountry, setPhoneCountry] = useState({
    countryCode: 'ca',
    dialCode: '1'
  })

  const countryList = JSON.parse(localStorage.getItem("country-list")) || [];
  const countryCodes = countryList.reduce((acc, cur) => {
    acc.push(cur.alpha2code);
    return acc;
  }, []);

  const validationSchema = yup.object({
    name: yup
      .string("Enter your store name")
      .min(2, "Store Name is too short.")
      .required("Store Name is required."),
  
    store_id: yup
      .string("Enter your Store ID")
      .min(2, "Store ID is too short.")
      .required("Store ID is required."),
  
      phone: yup
      .string("Enter your phonenumber")
      .matches(numricRegex, "Only Number Required")
      .test("len", "Enter Valid Number", phone => {
        return removeCodeFromPhone(phone, phoneCountry.dialCode)?.length === 10;
      })
      .required("Phone Number is required"),
  
    country: yup
      .mixed("Select country")
      .required("Country is required")
      .nullable(),
  
    postal_code: yup
      .string("Enter your postal code.")
      .required("Postal Code is required.")
      .matches("^[a-zA-Z0-9]*$", "Only alphanumeric characters allowed"),
  
    address: yup
      .string("Enter your Address")
      .min(5, "Too Short")
      .max(300, "Too Long ")
      .required("Address is required"),
  
    city: yup.string("Enter your city").required("City is required"),
  });

  // console.log("estore", estore_id);
  const onSubmit = async (values) => {
    const value = {
      ...values,
      country: values.country?.id,
      phone: removeCodeFromPhone(values.phone, phoneCountry.dialCode),
      dial_code: phoneCountry.dialCode,
      country_code: phoneCountry.countryCode,
    };
    console.log(value);
    handleaddStore(value);
  };

  const initialData = {
    name: "",
    store_id: "",
    phone: "",
    postal_code: "",
    address: "",
    city: "",
    country: null
  };

  const formik = useFormik({
    initialValues: initialData,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
    isInitialValid: false,
    validateOnMount: true,
  });

  const handleClose = (event, reason) => {
    handleBackgroundClick(reason, onClose, false);
  };

  console.log(formik.isValid, formik.values, formik.errors);
  useEffect(() => {
    if (
      countryCode &&
      countryCode?.toLowerCase() !==
        formik.values.country?.alpha2code?.toLowerCase()
    ) {
      const country = _.find(countryList, [
        "alpha2code",
        countryCode?.toUpperCase(),
      ]);
      formik.setFieldValue("country", country);
    }
  }, [countryCode]);


  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
          width: "600px",
          minHeight: "435px",
          maxHeight: "80vh",
        },
      }}
    >
      <DialogTitle className="text-center">
        <p className="dialog-title">Add New Store</p>
      </DialogTitle>
      <hr className="hr" />

      <form onSubmit={() => {}}>
        <DialogContent className="flex-col">
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}

          <Row>
            <Col xl={6} xs={12}>
              <TextField
                className="w-100 mb-3"
                required
                variant="outlined"
                name="name"
                id="name"
                InputLabelProps={textFieldLabelProps}
                label="Store Name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                // onChange={(e) => handleChange(e)}
                required
                className="w-100 mb-3"
                variant="outlined"
                name="store_id"
                id="store_id"
                InputLabelProps={textFieldLabelProps}
                label="Store ID"
                type="text"
                // helperText="This is your unique store id which will be prefixed to all paystrings generated. No special chars only alphanumeric allowed"
                value={formik.values.store_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <Tooltip
                        arrow
                        title="This is your unique store id which will be prefixed to all paystrings generated. No special chars only alphanumeric allowed"
                      >
                        <IconButton>
                          <InfoRounded />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                error={
                  formik.touched.store_id && Boolean(formik.errors.store_id)
                }
                helperText={formik.touched.store_id && formik.errors.store_id}
              />
            </Col>
           
            <Col xl={6} xs={12}>
              <ReactFlagsSelect
                countries={countryCodes}
                className="mb-3 bg-white material-inp"
                style={{ lineHeight: "2rem" }}
                selected={formik?.values?.country?.alpha2code}
                onSelect={(code) => {
                  let country = _.find(countryList, {
                    alpha2code: code,
                  });
                  let e = {
                    target: {
                      name: "country",
                      value: country,
                    },
                  };
                  setCountryCode(code);
                  formik.handleChange(e);
                }}
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                className="w-100 mb-3"
                // onChange={(e) => handleChange(e)}
                required
                variant="outlined"
                name="city"
                id="city"
                // value={formik.city}
                InputLabelProps={textFieldLabelProps}
                label="City"
                type="text"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                className="w-100 mb-3"
                // onChange={(e) => handleChange(e)}
                required
                variant="outlined"
                name="address"
                id="address"
                // value={formik.address}
                InputLabelProps={textFieldLabelProps}
                label="Address"
                type="text"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Col>
            <Col xl={6} xs={12} className="mb-4">
            <PhoneNumberInput
              formik={formik}
              label={"Phone Number"}
              name={"phone"}
              setPhoneCountry={setPhoneCountry}
              onlyCountries={
                countryList.length > 0
                  ? _.map(countryList, (country) => {
                      return country?.alpha2code?.toLowerCase();
                    })
                  : []
              }
              country={phoneCountry?.countryCode}
            />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                required
                className="w-100 mb-3"
                // onChange={(e) => handleChange(e)}
                variant="outlined"
                name="postal_code"
                id="postal_code"
                InputLabelProps={textFieldLabelProps}
                label="Postal Code"
                type="text"
                // value={formik.postal_code}
                value={formik.values.postal_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.postal_code &&
                  Boolean(formik.errors.postal_code)
                }
                helperText={
                  formik.touched.postal_code && formik.errors.postal_code
                }
              />
            </Col>
          </Row>
        </DialogContent>
        <hr className="hr" />
        <DialogActions
        sx={{
          paddingX: "24px",
          paddingBottom: "15px",
          gap: 1
        }}
      >
          <ButtonSecondary
            disabled={loading}
            onClick={handleClose}
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary
            loading={loading}
            onClick={formik.handleSubmit}
          >
            {!loading ? "Add Store" : "loading..."}
          </ButtonPrimary>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddStore;
