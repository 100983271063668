import React, { useState } from "react";
import * as Rsuite from "rsuite";
import moment from "moment-timezone";
import { startCase, toLower } from "lodash";
import LinearProgress from "@mui/material/LinearProgress";

import { DataGrid } from "@mui/x-data-grid";
import { Chip } from "@mui/material";
import greentick from "../../../assets/img/icons/greentick.svg";
import cross from "../../../assets/img/icons/cross.svg";
import warning from "../../../assets/img/icons/warning.svg";
import { toast } from "react-toastify";
import { formatCurrency, statusColors } from "../../../utils/common";
import TableWrapper from "../../components/TableWrapper";
import ButtonPrimary from "../../../components/ButtonPrimary";
import { IconButton } from "@mui/material";
import Iconify from "../../../components/Iconify";
import { activateInteracAccount } from "../../../redux/actions/accountActions";
import { useDispatch } from "react-redux";
import DeleteWarningModal from "../../../components/warning/DeleteWarningModal";

export default function WithdrawAccountTable({
  accounts,
  paginateTable,
  setpaginateTable,
  fetchAccountsLoading,
  getInteracAccounts,
  onDeleteClick,
  toggleWithdrawVerifyOtpModal
}) {
  const cellStyle = {
    color: "black",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const [loading, setLoading] = useState(-1);

  const dispatch = useDispatch();

  const activateAccount = (id) => {
    setLoading(id);
    dispatch(
      activateInteracAccount(
        id,
        (res) => {
          setLoading(-1);
          toast.success(res.message);
          toggleWithdrawVerifyOtpModal(id);
          getInteracAccounts();
        },
        (err) => {
          toast.error(err?.response?.data?.message);
          setLoading(-1);
        }
      )
    );
  };

  const handleChangePage = (newPage) => {
    setpaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: +event.target.value,
    });
  };

  const createDataRow = (account) => {
    return {
      id: account.id,
      date:
        moment().format("YY") === moment(account.created_date).format("YY")
          ? moment.parseZone(account.created_date).format("MMM Do")
          : moment.parseZone(account.created_date).format("MMM Do YY"),

      type: "Interac",
      details: account?.interac_email || "--",
      status: account?.is_approved_for_withdrawal,
      action: "Action",
      verify: "Verify",
      account: account,
    };
  };

  const rows =
    accounts?.length > 0
      ? accounts.map((account) => createDataRow(account))
      : [];

  const createDataColumn = () => {
    return [
      {
        field: "date",
        headerName: "Date Added",
        flex: 1,
        minWidth: 120,
        // maxWidth: 200,
        headerClassName: "header",
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          return <p style={cellStyle}>{params.formattedValue}</p>;
        },
      },
      {
        field: "type",
        headerName: "Type",
        flex: 1,
        minWidth: 120,
        maxWidth: 150,
        textOverflow: "ellipsis",
        headerClassName: "header",
        renderCell: (params) => {
          return <p style={cellStyle}>{params.formattedValue}</p>;
        },
      },
      {
        field: "details",
        headerName: "Details",
        flex: 1.5,
        minWidth: 200,
        // maxWidth: 200,
        textOverflow: "ellipsis",
        headerClassName: "header",
        renderCell: (params) => {
          return <p style={cellStyle}>{params.formattedValue}</p>;
        },
      },

      {
        field: "status",
        headerName: "Status",
        sortable: false,
        flex: 1.5,
        minWidth: 200,
        headerClassName: "header",
        renderCell: (params) => {
          console.log(
            params,
            params?.account?.withdraw_requested_at,
            "cwecwec",
            Boolean(params?.account?.withdraw_requested_at)
          );
          return params.formattedValue ? (
            <Chip
              style={{
                fontSize: "13px",
                fontWeight: "600",
                backgroundColor: statusColors.bgColors.complete,
                color: statusColors.colors.complete,
              }}
              label="Verified"
              color="success"
            />
          ) : params?.row?.account?.withdraw_requested_at ? (
            <Chip
              style={{
                fontSize: "13px",
                fontWeight: "600",
                backgroundColor: statusColors.bgColors["application submitted"],
                color: statusColors.colors["application submitted"],
              }}
              label="Verification Pending"
              color="warning"
            />
          ) : (
            <ButtonPrimary
              loading={loading === params.id}
              onClick={() => {
                activateAccount(params.id);
              }}
            >
              Activate for Withdraw
            </ButtonPrimary>
          );
        },
      },
      {
        field: "action",
        headerName: "Delete Account",
        flex: 1,
        minWidth: 40,
        maxWidth: 160,
        align: "center",
        alignHeader: "center",
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <IconButton
              sx={{
                color: statusColors.colors.rejected,
              }}
              onClick={() => {
                onDeleteClick(params.id);
              }}
            >
              <Iconify icon="material-symbols:delete-outline" width={18} />
            </IconButton>
          );
        },
      },
    ];
  };

  const columns = createDataColumn();

  return (
    <>
      <TableWrapper>
        <DataGrid
          sx={{
            borderRadius: "8px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
            width: "100%",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          autoHeight
          rows={rows}
          columns={columns}
          rowHeight={60}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={[10, 20, 50]}
          page={paginateTable.currentPage}
          pageSize={paginateTable.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchAccountsLoading}
        />
      </TableWrapper>
     
    </>
  );
}
