import axios from "axios";
import * as API from "../../ApiUrl";
import { GET_STORE_LIST, SET_STORE_LIST } from "../actionTypes";
import { merchantApi } from "../api/apiConfig";

const getStorelist = () => async (dispatch) => {
  dispatch({ type: GET_STORE_LIST });
  try {
    const res = await merchantApi.get(`${API.STORE_LIST}`);
    console.log("response of the auth", res);
    dispatch({
      type: SET_STORE_LIST,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err, "errr");
    // dispatch({
    //   type: GET_PROFILE_FAIL,
    // });
  }
};

export { getStorelist };
