import React, { useState } from "react";
import { Button } from "rsuite";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

import edit from "../../../assets/img/icons/edit.svg";
import qr from "../../../assets/img/icons/qr.svg";
import Popover from "@mui/material/Popover";

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { merchantApi } from "../../../redux/api/apiConfig";
import * as API from "../../../ApiUrl";
import { SET_USER_PAYSTRING } from "../../../redux/actionTypes";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const Toggle = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disabled={props.disabled}
    disableRipple
    checked={props.checked}
    {...props}
  />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#00f6ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#00f6ff",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const rowStyle = { color: "black", fontSize: "14px", fontWeight: 500 };

const CounterTable = ({
  data: results,
  loading,
  handleeditCounter,
  handlePOSStatusChange,
}) => {
  const stores = useSelector((state) => state.storeReducer.storelist);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("No data to show.");

  const updatePaystring = async () => {
    merchantApi
      .get(API.PAY_STRING)
      .then((response) => {
        const { data } = response;
        console.log(data);
        if (data?.data?.paystring) {
          dispatch({
            type: SET_USER_PAYSTRING,
            payload: data.data.paystring,
          });
          return;
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message || "Something went wrong");
      });
  };

  // console.log(stores);

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {message}
      </Stack>
    );
  };

  const createDataRow = (counter) => {
    // console.log(counter, stores);
    return {
      id: counter.id,
      name: counter.name,
      paystring: counter.paystring,
      store: stores.filter((store) => store.id === counter.store)[0].name,
      status: counter.active,
      qr: counter.qrcode,
      counterData: counter,
    };
  };

  // console.log(results);

  const rows =
    results.length > 0 ? results.map((user) => createDataRow(user)) : [];

  // console.log(results);
  const totalActive = results.filter((counter) => counter.active);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 80,
      // maxWidth: 120,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <p style={rowStyle}>{params.formattedValue}</p>;
      },
      // align: 'center',
    },
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      flex: 1,
      minWidth: 140,
      // maxWidth: 250,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={rowStyle}>{params.formattedValue}</p>;
      },
      // align: 'center',
      // headerAlign: 'center',
    },
    {
      flex: 2,
      minWidth: 170,
      // minWidth: 350,
      field: "paystring",
      headerName: "Paystring",
      sortable: false,
      type: "text",
      headerClassName: "header",
      // align: 'center',
    },
    {
      field: "store",
      headerName: "Store",
      sortable: false,
      flex: 1,
      minWidth: 120,
      headerClassName: "header",
      // align: 'center',
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 140,
      // maxWidth: 180,
      headerClassName: "header",
      renderCell: (params) => {
        // console.log(params);
        const onClick = async (data) => {
          if (totalActive.length === 1 && params.formattedValue) {
            toast.error("Cannot disable all counters");
          } else {
            await handlePOSStatusChange(params.row.counterData);
            await updatePaystring();
            console.log("Hey");
          }
        };
        return (
          <div className="d-flex gap-2 align-items-center">
            <Toggle
              sx={{ m: 1 }}
              onClick={onClick}
              disabled={
                loading || (totalActive.length === 1 && params.formattedValue)
              }
              defaultChecked={params.formattedValue}
            />
            <p style={rowStyle}>
              {params.formattedValue ? "Active" : "Inactive"}
            </p>
          </div>
        );
      },
      // align: 'center',
    },
    {
      flex: 1,
      field: "qr",
      headerName: "QR",
      sortable: false,
      // maxWidth: 180,
      minWidth: 140,
      headerClassName: "header",
      renderCell: (params) => {
        // const onClick = (e) => {
        //   e.stopPropagation(); // don't select this row after clicking

        // };

        return (
          <a
            href={params.formattedValue}
            rel="noopener noreferrer"
            target="_blank"
            style={rowStyle}
          >
            <Button
              style={{ ...rowStyle, background: "none" }}
              className="d-flex align-items-center gap-2"
            >
              <img src={qr} alt="edit" />
              QR Code
            </Button>
          </a>
        );
      },
    },
    {
      flex: 1,
      headerName: "Action",
      sortable: false,
      minWidth: 140,
      headerClassName: "header",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          // console.log(params.row.counterData);
          handleeditCounter(params.row.counterData);
        };

        return (
          <Button
            onClick={onClick}
            style={{ background: "none" }}
            className="d-flex align-items-center gap-2"
          >
            <img src={edit} alt="edit" />
            <span style={rowStyle}>Edit</span>
          </Button>
        );
      },
    },
  ];
  const handlePopoverOpen = (event) => {
    const field = event.currentTarget.dataset.field;
    const id = event.currentTarget.parentElement.dataset.id;
    const row = rows.find((r) => r.id == id);
    console.log(field, row, rows, id);
    setValue(row[field]);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div>
      <Box
        sx={{
          height: 615,
          width: 1,
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          sx={{
            borderRadius: "8px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          }}
          rows={rows}
          columns={columns}
          // componentsProps={{
          //   cell: {
          //     onMouseEnter: handlePopoverOpen,
          //     onMouseLeave: handlePopoverClose,
          //   },
          // }}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
          // loading={fetchPosLoading}
          rowHeight={72}
          pageSize={7}
          // rowsPerPageOptions={[5]}
          disableColumnFilter={true}
          disableColumnMenu={true}
          // checkboxSelection
        />
        {/* <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1, borderRadius: '10px' }}>{value}</Typography>
        </Popover> */}
      </Box>
    </div>
  );
};

export default CounterTable;
