import React from "react";
import moment from "moment-timezone";
import LinearProgress from "@mui/material/LinearProgress";

import { startCase, toLower } from "lodash";

import { cellStyle, formatCurrency, getStatusChip } from "../../../utils/common";

import { DataGrid } from "@mui/x-data-grid";
import TableWrapper from "../../components/TableWrapper";



export default function Transactiontable({
  translist,
  paginateTable,
  setpaginateTable,
  loading,
}) {

  const handleChangePage = (newPage) => {
    setpaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (newSize) => {
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  };


  const createDataRow = (transaction) => ({
    id: transaction?.reference_number,
    lastUpdate:
      moment().format("YY") === moment(transaction?.created_date).format("YY")
        ? moment(transaction?.created_date).format("MMM Do")
        : moment(transaction?.created_date).format("MMM Do YY"),

    sender:
    transaction?.type === "refund"
    ? startCase(toLower(transaction?.receiver?.name))
    : startCase(toLower(transaction.sender?.name)),
    receiver:
      transaction?.type === "withdraw"
        ? transaction?.receiver?.name
        : startCase(toLower(transaction?.receiver?.name)),
    value: formatCurrency(
      transaction?.receive_amount,
      transaction?.receiver_coin?.decimal_place,
      transaction?.receiver_coin?.symbol,
      transaction?.receiver_coin?.symbol_prefix
    ),
    fees: formatCurrency(
      transaction?.total_fee,
      transaction?.receiver_coin?.decimal_place,
      transaction?.receiver_coin?.symbol,
      transaction?.receiver_coin?.symbol_prefix
    ),
    balanceAfterFees: formatCurrency(
      Number(transaction?.receive_amount) - Number(transaction?.total_fee),
      transaction?.receiver_coin?.decimal_place,
      transaction?.receiver_coin?.symbol,
      transaction?.receiver_coin?.symbol_prefix
    ),
    store: startCase(transaction?.store?.name || "N/A"),
    counter: transaction?.counter?.name,
    type: startCase(toLower(transaction.type)),
    status: transaction.current_status,
    details: transaction?.blockchain_url,
  });

  const rows =
    translist?.results?.length > 0
      ? translist?.results?.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: "lastUpdate",
      headerName: "Date of Txn",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 120,
      // maxWidth: 150,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: "sender",
      headerName: "Customer Name",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: "store",
      headerName: "Store",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },

    {
      field: "counter",
      headerName: "Counter",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
   
    {
      field: "value",
      headerName: "Amount",
      flex: 1.5,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      sortable: false,
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },

    {
      field: "fees",
      headerName: "Fees",
      flex: 1.5,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      sortable: false,
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },

    {
      field: "balanceAfterFees",
      headerName: "Amount Received",
      flex: 1.5,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return <p style={{...cellStyle, fontWeight:'bold'}}>{params.formattedValue}</p>;
      },
    },
    
   
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1.5,
      minWidth: 220,
      headerClassName: "header",
      renderCell: (params) => {
        return getStatusChip(params.formattedValue)
      },
    },
  ];

  return (
    <>
      <TableWrapper>
        <DataGrid
          sx={{
            borderRadius: "8px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          }}
          autoHeight
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={rows}
          columns={columns}
          loading={loading}
          rowHeight={40}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={[10, 20, 50]}
          page={paginateTable.currentPage}
          rowCount={translist.count}
          pageSize={paginateTable.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
        />
      </TableWrapper>
    </>
  );
}
