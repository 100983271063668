import React, { useEffect, useRef, useState } from "react";
import { Button } from "rsuite";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Stack,
  Typography,
  MenuItem,
  TextField,
  InputAdornment,
} from "@mui/material";
import QRCode from "react-qr-code";
import * as yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import stblogo from "../../assets/img/icons/stbLogoDark.svg";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import {
  ADMIN,
  CASHIER,
  MANAGER,
  formatCurrency,
  handleBackgroundClick,
  statusColors,
  textFieldLabelProps,
} from "../../utils/common";
import { useFormik } from "formik";
import { merchantApi } from "../../redux/api/apiConfig";
import { COUNTER, STORE_LIST } from "../../ApiUrl";
import ButtonSecondary from "../ButtonSecondary";
import ButtonPrimary from "../ButtonPrimary";
import { generateCustomQr, getCustomQr } from "../../redux/actions/accountActions";
import PreviewContainer from "../preview/PreviewContainer";
import StyledTitle from "../preview/StyledTitle";
import StyledInfo from "../preview/StyledInfo";
import Image from "../image";
import Colors from "../../assets/colors";
import warning from "../../assets/img/icons/warning.svg";
import tick from "../../assets/img/icons/greentick.svg";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import _ from "lodash";


const CreateCustomQrModal = ({ userPayString, handleClose, open }) => {
  const [step, setStep] = useState("create");
  const [userStore, setUserStore] = useState([]);
  const [userCounters, setUserCounters] = useState([]);
  const [generateQrLoading, setGenerateQrLoading] = useState(false);
  const [customQrRes, setCustomQrRes] = useState(null);
  const [qrPaymentData, setQrPaymentData] = useState(null);

  const curRole = useSelector((store) => store.authReducer.userRole);
  const { currency, name: merchantName } = useSelector(
    (store) => store?.profileReducer?.user?.merchant
  );

  const dispatch = useDispatch();

  const isStoreCounterRequired = [ADMIN, MANAGER].includes(curRole);

  const intervalIdRef = useRef(null);

  const pollingInterval = 3000; // 3 seconds
  const totalDuration = 5 * 60 * 1000 + 10 * 1000; // 5 minutes 10 seconds


  const closeHandler = (event, reason) => {
    createQrFormik.resetForm();
    handleBackgroundClick(reason, handleClose, false);
  }

  useEffect(() => {
    console.log("curRole", curRole);
    getUserStores();
    return () => {
      stopPolling();
    }
  }, []);

  let componentRef = useRef();

  const validationSchema = yup.object({
    store: yup.string("Select store").when("user_role", {
      is: (curRole) => isStoreCounterRequired,
      then: yup.string("Select store").required("Store is required"),
    }),
    counter: yup.string("Select counter").when("user_role", {
      is: (curRole) => isStoreCounterRequired,
      then: yup.string("Select counter").required("Counter is required"),
    }),
    amount: yup.number("Enter your amount").required("Amount is required"),
    description: yup
      .string("Enter description")
      .min(5, "Too Short")
      .max(300, "Too Long "),
  });

  const onSubmit = () => {
    setGenerateQrLoading(true);
    const data = {
      description: createQrFormik.values.description,
      amount: createQrFormik.values.amount,
      store: createQrFormik.values.store,
      counter: createQrFormik.values.counter,
    };
    dispatch(
      generateCustomQr(
        data,
        (res) => {
          console.log("cwdcsdc", res);
          setGenerateQrLoading(false);
          setCustomQrRes(res.data);
          toast.success(res.message);
          setStep("view");
          startPolling(res.data);
        },
        (err) => {
          toast.error(err?.response?.data?.message || "Something went wrong");
          setGenerateQrLoading(false);
        }
      )
    );
  };

  const createQrFormik = useFormik({
    initialValues: {
      store: "",
      counter: "",
      amount: "",
      description: "",
    },
    validateOnBlur: true,
    isInitialValid: false,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  console.log(createQrFormik);

  const onChangeStore = async (e) => {
    const response = await merchantApi.get(
      `${COUNTER}?store=${e.target.value}`
    );
    if (response?.data?.results) {
      setUserCounters(response.data.results);
    }
  };

  const getUserStores = async () => {
    const response = await merchantApi.get(`${STORE_LIST}`);
    if (response?.data?.results) {
      setUserStore(response.data.results);
    }
  };

  let content;
  let title;

  const fetchData = async (data) => {
    try {
      console.log('hewrwcqcw', data)

      dispatch(
        getCustomQr(
          data.id,
          (res) => {
            console.log("cwdcsdc", res);
            let status = res.status;
            // setCustomQrStatus(res.status);
            if(status === "closed") {
              setStep('closed');
              setQrPaymentData(res?.transactions?.[0]);
              stopPolling();
            }

          },
          (err) => {
          }
        )
      );
    } catch (err) {
    }
  };


  const startPolling = (data) => {
    const startTime = Date.now();

    intervalIdRef.current = setInterval(() => {
      console.log("intervalIdRef.current",Date.now() - startTime, totalDuration);
      if (Date.now() - startTime >= totalDuration) {
        setStep("expired");
        stopPolling();
      } else {
        fetchData(data);
      }
    }, pollingInterval);
  };

  const stopPolling = () => {
    clearInterval(intervalIdRef.current);
  };

  if (step === "create") {
    content = (
      <>
        <DialogTitle>
          <Stack direction={"column"}>
            Create Dynamic QR Code
            {/* <Typography variant="caption">via Interac e-Transfer®</Typography> */}
          </Stack>
        </DialogTitle>
        <form onSubmit={createQrFormik.handleSubmit}>
          <DialogContent sx={{ paddingTop: "5px !important" }}>
            <Stack spacing={2} direction={"column"} alignItems={"center"}>
              {curRole !== CASHIER && (
                <TextField
                  select
                  required
                  className="w-100 mb-3"
                  variant="outlined"
                  name="store"
                  id="store"
                  value={createQrFormik.values.store}
                  InputLabelProps={textFieldLabelProps}
                  label="Store"
                  type="text"
                  onChange={(e) => {
                    onChangeStore(e);
                    createQrFormik.handleChange(e);
                  }}
                  onBlur={createQrFormik.handleBlur}
                  error={
                    createQrFormik.touched.store &&
                    Boolean(createQrFormik.errors.store)
                  }
                  helperText={
                    createQrFormik.touched.store && createQrFormik.errors.store
                  }
                >
                  {userStore.map((store, i) => (
                     <MenuItem value={store.id}>{`${_.startCase(store?.name)} (${_.startCase(store?.city)})`}</MenuItem>
                  ))}
                </TextField>
              )}

              {curRole !== CASHIER && (
                <TextField
                  select
                  required
                  className="w-100 mb-3"
                  variant="outlined"
                  // onChange={(e) => handleChange(e)}
                  name="counter"
                  id="counter"
                  value={createQrFormik.values.counter}
                  InputLabelProps={textFieldLabelProps}
                  label="Counter"
                  type="text"
                  onChange={createQrFormik.handleChange}
                  onBlur={createQrFormik.handleBlur}
                  error={
                    createQrFormik.touched.counter &&
                    Boolean(createQrFormik.errors.counter)
                  }
                  helperText={
                    createQrFormik.touched.counter &&
                    createQrFormik.errors.counter
                  }
                >
                  {userCounters.map((counter, i) => (
                    <MenuItem value={counter.id}>{counter.name}</MenuItem>
                  ))}
                </TextField>
              )}

              <TextField
                className="w-100 mb-3"
                required
                variant="outlined"
                name="amount"
                type="number"
                id="amount"
                InputLabelProps={textFieldLabelProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {currency?.code || "CAD"}
                    </InputAdornment>
                  ),
                }}
                label="Enter amount for QR Code"
                value={createQrFormik.values.amount}
                onChange={(e) => {
                  // getFees(e.target.value)
                  createQrFormik.handleChange(e);
                }}
                onBlur={createQrFormik.handleBlur}
                error={
                  createQrFormik.touched.amount &&
                  Boolean(createQrFormik.errors.amount)
                }
                helperText={
                  createQrFormik.touched.amount && createQrFormik.errors.amount
                }
              />
              <TextField
                className="w-100 mb-3"
                multiline
                rows={4}
                variant="outlined"
                name="description"
                id="description"
                InputLabelProps={textFieldLabelProps}
                label="Description"
                type="text"
                value={createQrFormik.values.description}
                onChange={createQrFormik.handleChange}
                onBlur={createQrFormik.handleBlur}
                error={
                  createQrFormik.touched.description &&
                  Boolean(createQrFormik.errors.description)
                }
                helperText={
                  createQrFormik.touched.description &&
                  createQrFormik.errors.description
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
              flexDirection:"column",
              gap: 1
            }}
          ><ButtonPrimary
              onClick={createQrFormik.handleSubmit}
              loading={generateQrLoading}
              style={{width: "100%"}}
            >
              Generate
            </ButtonPrimary>

            <ButtonSecondary disabled={generateQrLoading} style={{width: "100%"}} onClick={closeHandler}>
              Close
            </ButtonSecondary>
            
          </DialogActions>
        </form>
      </>
    );
  } else if (step === "view") {

    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
          return <Typography>Qr Expired</Typography>;
        }
      
        const minn = Math.floor(remainingTime / 60);
        let secs = remainingTime % 60;
        if (secs < 10) {
          secs = `0${secs}`;
        }
        const remainingTimer = `${minn}:${secs}`;
      
        return (
          <Stack sx={{alignItems: "center", justifyContent: "center", flexDirection: "column" }} >
            <Typography variant="caption" >Expires In</Typography>
            <Typography variant="h6" >{remainingTimer}</Typography>
          </Stack>
        );
      };

    content = (
        <>
      <Stack ref={componentRef}>
        <DialogTitle>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>

          <Stack direction={"column"}>
            Pay Here
            <Typography variant="caption">This is one time QR</Typography>
          </Stack>
          <Stack>
          <CountdownCircleTimer
          isPlaying
          duration={300}
          size={90}
          strokeWidth={6}
          colors={[statusColors.colors.complete, Colors.secondary, statusColors.colors.rejected]}
            colorsTime={[300, 120, 30]}
          onComplete={() => ({ shouldRepeat: false, delay: 1 })}
        >
          {renderTime}
        </CountdownCircleTimer>
          </Stack>
            </Stack>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "5px !important" }}>
          <Stack spacing={2} direction={"column"} alignItems={"center"}>
            <Stack
              sx={{
                backgroundColor: Colors.cardBg,
                padding: 2,
                borderRadius: "8px",
              }}
            >
              <Image
                alt="payment_qr"
                src={customQrRes?.shareable_qr}
                sx={{ width: "180px" }}
              />
            </Stack>

            <PreviewContainer sx={{ width: "100%" }}>
            <Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <StyledTitle>Merchant Name</StyledTitle>
                <StyledInfo>{merchantName}</StyledInfo>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <StyledTitle>Paystring</StyledTitle>
                <StyledInfo>{customQrRes?.recipient_paystring}</StyledInfo>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <StyledTitle>Amount</StyledTitle>
                <StyledInfo>{formatCurrency(customQrRes?.amount,currency.decimal_place, currency?.symbol, currency?.symbol_prefix)}</StyledInfo>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <StyledTitle>Remarks</StyledTitle>
                <StyledInfo>{customQrRes?.description}</StyledInfo>
              </Stack>
              </Stack>
            </PreviewContainer>

            <Stack sx={{ alignItems: "center", mt: 4 }}>
            <Typography variant="caption">Powered By</Typography>
            <Image style={{ marginRight: "20px" }} src={stblogo} alt="" />
          </Stack>
          </Stack>
        </DialogContent>
        </Stack>
        <DialogActions
          sx={{
            paddingX: "24px",
            paddingBottom: "15px",
            flexDirection: "column",
            gap:1
          }}
        >
          <ReactToPrint
            trigger={() => <ButtonPrimary style={{width: "100%"}}>Print</ButtonPrimary>}
            content={() => componentRef.current}
            documentTitle={"STB QR"}
          />
          <ButtonSecondary onClick={() => setStep("create")} style={{width: "100%"}}>
            Generate Another Dynamic QR Code
          </ButtonSecondary>
          <ButtonSecondary disabled={generateQrLoading} onClick={closeHandler} style={{width: "100%"}}>
            Close
          </ButtonSecondary>
        </DialogActions>
    </>
    );


  } else if (step === "expired") {
    content = (
      <>
    <Stack ref={componentRef}>
      <DialogTitle>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>

        <Stack direction={"column"}>
          QR Expired
        </Stack>
          </Stack>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "5px !important" }}>
        <Stack spacing={2} direction={"column"} alignItems={"center"}>

        <img src={warning} style={{width: "100px"}} alt="warning" />
        
        <Typography variant="subtitle1" sx={{textAlign: "center"}}>This QR has expired</Typography>
        </Stack>
      </DialogContent>
      </Stack>
      <DialogActions
        sx={{
          paddingX: "24px",
          paddingBottom: "15px",
          flexDirection: "column",
          gap:1
        }}
      >
        <ButtonSecondary onClick={() => setStep("create")} style={{width: "100%"}}>
          Generate Another Dynamic QR Code
        </ButtonSecondary>
        <ButtonSecondary disabled={generateQrLoading} onClick={closeHandler} style={{width: "100%"}}>
          Close
        </ButtonSecondary>
      </DialogActions>
  </>
  );

  }
  else if (step === "closed") {
    content = (
      <>
    <Stack ref={componentRef}>
      <DialogTitle>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>

        <Stack direction={"column"}>
        Payment Received
        </Stack>
          </Stack>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "5px !important" }}>
        <Stack spacing={2} direction={"column"} alignItems={"center"}>

        <img src={tick} style={{width: "100px"}} alt="success" />

          <PreviewContainer sx={{ width: "100%" }}>
          <Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <StyledTitle>From</StyledTitle>
              <StyledInfo>{qrPaymentData?.sender?.name}</StyledInfo>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <StyledTitle>Amount</StyledTitle>
              <StyledInfo>{`${Number(qrPaymentData?.receive_amount).toFixed(2)} ${qrPaymentData?.receiver_coin}`}</StyledInfo>
            </Stack>
            
            </Stack>
          </PreviewContainer>
        </Stack>
      </DialogContent>
      </Stack>
      <DialogActions
        sx={{
          paddingX: "24px",
          paddingBottom: "15px",
          flexDirection: "column",
          gap:1
        }}
      >
       
        <ButtonSecondary onClick={() => setStep("create")} style={{width: "100%"}}>
          Generate Another Dynamic QR Code
        </ButtonSecondary>
        <ButtonSecondary disabled={generateQrLoading} onClick={closeHandler} style={{width: "100%"}}>
          Close
        </ButtonSecondary>
      </DialogActions>
  </>
  );

  }

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={closeHandler}
      maxWidth="xs"
      scroll={"paper"}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      {content}
    </Dialog>
  );
};

function mapStateToProps({ authReducer }) {
  const { userPayString } = authReducer;
  return {
    userPayString,
  };
}

export default connect(mapStateToProps, null)(CreateCustomQrModal);
