import React, { useState, useEffect } from "react";
import { UserTables as UserData } from "./components";
// import { Dropdown, InputGroup, Input, Icon, Button } from 'rsuite';
import "rsuite/dist/styles/rsuite-default.css";
import { Col, Button } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import * as API from "../../../ApiUrl";
import AddSystemUserModal from "../AddSystemUserModal";
import add from "../../../assets/img/icons/add.svg";

import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import searchIcon from "../../../assets/img/icons/searchIcon.svg";

import { Form, InputGroup } from "@themesberg/react-bootstrap";

import dropDown from "../../../assets/img/icons/dropDown.svg";
import { merchantApi } from "../../../redux/api/apiConfig";
import ButtonPrimary from "../../../components/ButtonPrimary";

const SystemUser = () => {
  const [users, setUsers] = useState([]);
  const [fetchUsersLoading, setFetchUsersLoading] = useState(true);
  const [userStores, setUserStores] = useState([]);
  const [userroletitle, setuserroletitle] = useState("");
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [userroleOption, setUserroleOption] = useState([
    { name: "Select User Role", value: "" },
  ]);
  const [pageChange, setPageChange] = useState({
    search: "",
    user_role: "",
    currentPage: 0,
    pageSize: 7,
  });

  const { permissions, userRole } = useSelector((state) => state?.authReducer);

  const toggleAddUserModal = () => {
    setShowAddUserModal((prevState) => !prevState);
  };

  const getUsers = async () => {
    setFetchUsersLoading(true);
    try {
      const response = await merchantApi.get(`${API.USER_LIST}`, {
        params: {
          offset: pageChange.currentPage * pageChange.pageSize,
          limit: pageChange.pageSize,
          search: pageChange.search,
          user_role: pageChange.user_role,
        },
      });

      setUsers(response.data);
      setFetchUsersLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setFetchUsersLoading(false);
    }
  };

  const getAllStores = async () => {
    try {
      const response = await merchantApi.get(`${API.STORE_LIST}?limit=100`);
      if (response?.data?.results) {
        setUserStores(response.data.results);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getUserLevelOptions();
    getAllStores();
  }, []);

  const getUserLevelOptions = async () => {
    try {
      const response = await merchantApi.get(`${API.USER_ROLE}`);
      setUserroleOption([...userroleOption, ...response.data.data]);
    } catch (error) {}
  };

  useEffect(() => {
    getUsers();
  }, [
    pageChange.currentPage,
    pageChange.pageSize,
    pageChange.search,
    pageChange.user_role,
  ]);

  const handlefilter = (e) => {
    console.log("role select", e);
    let l = userroleOption.filter((a) => a.value === e);
    setuserroletitle(l[0].name);

    setPageChange({
      ...pageChange,
      currentPage: 0,
      user_role: e === "Select User Role" ? "" : e,
    });
  };

  const handleSearch = (e) => {
    setPageChange({
      ...pageChange,
      search: e,
      currentPage: 0,
    });
  };

  console.log("user role options", userroleOption);

  return (
    <div className="page-wrapper stb-page-wrapper stb-ph-8">
      <div className="user stb-h-100">
        <div className="users stb-h-100 bg-white p-4 radi">
          <div className="page-header  pb-4 space-between">
            <div>
              <h3 className="m-0 page-title">System User</h3>
              <p>View your users</p>
            </div>
            <div className="gap-4 tools">
              <div className="filter">
                <Form
                  onSubmit={(e) => e.preventDefault()}
                  className="w-100 search"
                >
                  <Form.Group>
                    <InputGroup>
                      <InputGroup.Text>
                        <img src={searchIcon} alt="search" />
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                        type="text"
                        placeholder="Search"
                      />
                    </InputGroup>
                  </Form.Group>
                </Form>
              </div>

              <div className="filter">
                <Dropdown as={ButtonGroup} className="w-100">
                  <Dropdown.Toggle id="dropdown-basic">
                    {userroletitle || "Select User Role"}
                    <img
                      src={dropDown}
                      className="dropdown-icon"
                      style={{ marginLeft: "4px" }}
                      alt="drop-down"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors">
                    {userroleOption.map((a, i) => (
                      <Dropdown.Item
                        as="button"
                        value={a.value}
                        onClick={() => {
                          handlefilter(a.value);
                        }}
                        key={i + "res"}
                      >
                        {a.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>{" "}
              </div>

              {permissions.administrations.SystemUser.permission.add[
                userRole
              ] && (
                <div className="filter">
                  <ButtonPrimary onClick={toggleAddUserModal}>
                    Add New User
                  </ButtonPrimary>
                </div>
              )}
            </div>
          </div>

          <UserData
            fetchUsersLoading={fetchUsersLoading}
            users={users}
            userStores={userStores}
            pageChange={pageChange}
            setPageChange={setPageChange}
          />
          {showAddUserModal && (
            <AddSystemUserModal
              onClose={toggleAddUserModal}
              isOpen={showAddUserModal}
              // This is to trigger re-render after adding user to fetch new list
              onUserAdd={() => {
                getUsers();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SystemUser;
