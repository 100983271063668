import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { statusColors } from '../utils/common';

const { colors, bgColors } = statusColors;

// ----------------------------------------------------------------------

const RootStyle = styled('span')(({ theme, ownerState }) => {
  const { color, textColor, backgroundColor, variant } = ownerState;

  const styleOutlined = () => ({
    color: color === ''? textColor : colors[color],
    backgroundColor: 'transparent',
    border: `1px solid ${color === ''? textColor : colors[color]}`,
  });

  const styleGhost = () => ({
    color:  color === ''? textColor : colors[color],
    backgroundColor:  color === ''? backgroundColor : bgColors[color],
  });

  return {
    height: 22,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 6,
    cursor: 'default',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    color: theme.palette.grey[800],
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey[300],
    fontWeight: theme.typography.fontWeightBold,
    ...(variant === 'outlined' && { ...styleOutlined() }),
    ...(variant === 'ghost' && { ...styleGhost() }),
  };
});

// ----------------------------------------------------------------------

export default function Label({ children, color='', textColor = '#003bbbdc', backgroundColor = '#afe2ffdc', variant = 'ghost', startIcon, endIcon, sx }) {
  const style = {
    width: 16,
    height: 16,
    '& svg, img': { width: 1, height: 1, objectFit: 'cover' },
  };

  return (
    <RootStyle
      ownerState={{color, textColor, backgroundColor, variant }}
      sx={{
        ...(startIcon && { pl: 0.75 }),
        ...(endIcon && { pr: 0.75 }),
        ...sx,
      }}
    >
      {startIcon && <Box sx={{ mr: 0.75, ...style }}>{startIcon}</Box>}

      {children}

      {endIcon && <Box sx={{ ml: 0.75, ...style }}>{endIcon}</Box>}
    </RootStyle>
  );
}
