import * as API from "../../ApiUrl";
import {
  GET_BUSINESSTYPE,
  SET_BUSINESSTYPE,
  GET_BUSINESSCATEGORY,
  SET_BUSINESSCATEGORY,
  SET_BUSINESS_DETAILS
} from "../actionTypes";
import {merchantApi} from "../api/apiConfig"

const getbusiesstype = () => async (dispatch) => {
  dispatch({ type: GET_BUSINESSTYPE });
  try {
    const res = await merchantApi.get(`${API.BUSINESS_TYPE_API}`);
    dispatch({
      type: SET_BUSINESSTYPE,
      payload: res.data.data,
    });
  } catch (err) {
    // dispatch({
    //   type: GET_PROFILE_FAIL,
    // });
  }
};
const getbusinesscategory = () => async (dispatch) => {
  dispatch({ type: GET_BUSINESSCATEGORY });

  try {
    const res = await merchantApi.get(`${API.CATEGORY_API}`);
    let d = [...res.data.data];
    let d1 = d.map((a) => ({ ...a, option: a.name }));
    dispatch({
      type: SET_BUSINESSCATEGORY,
      payload: d1,
    });
  } catch (err) {
    // dispatch({
    //   type: GET_PROFILE_FAIL,
    // });
  }
};

// merchantApi
// .get(API.ADD_MERCHANT_API)
// .then((response) => {
//   setMerchantDetail(response.data);
// })
// .catch((error) => {
//   toast.error(error.response.data.message);
// });



const getBusinessDetails = () => async(dispatch) => {

  try {
    const res = await merchantApi.get(`${API.ADD_MERCHANT_API}`);
    dispatch({
      type: SET_BUSINESS_DETAILS,
      payload: res.data,
    });
  } catch (err) {
  }
}
export { getbusiesstype, getbusinesscategory,getBusinessDetails };
