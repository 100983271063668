import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonSecondary from "../../../../components/ButtonSecondary";
import ButtonPrimary from "../../../../components/ButtonPrimary";
import { toast } from "react-toastify";
import {
  MERCHANT_IDENTOMAT_KYC,
  MERCHANT_IDENTOMAT_KYC_SUBMIT,
} from "../../../../ApiUrl";
import { Loader } from "rsuite";
import { merchantApi } from "../../../../redux/api/apiConfig";

const IdentomatKyc = ({ handleNext, handleBack, updateCurrentStepHandler }) => {
  const [sessionKey, setSessionKey] = useState(null);
  const [kycProcessComplete, setKycProcessComplete] = useState(false);

  const getSessionKey = () => {
    merchantApi
      .post(MERCHANT_IDENTOMAT_KYC)
      .then((response) => {
        // console.log("session key", response);
        setSessionKey(response?.data?.data?.session);
        toast.success("Session Key Generated");
      })
      .catch((error) => {
        toast.error("Error fetching identomat session key");
      });
  };

  useEffect(() => {
    getSessionKey();
  }, []);

  const submitKycHandler = () => {
    merchantApi
      .post(MERCHANT_IDENTOMAT_KYC_SUBMIT)
      .then((response) => {
        // console.log("KYC STATUS", response);
        // setSessionKey(response?.data?.data?.session);
        toast.success("KYC Submitted Successfully");
      })
      .catch((error) => {
        toast.error("Error fetching identomat session key");
      });
  };

  const url = sessionKey
    ? `https://widget.identomat.com/?session_token=${sessionKey}`
    : "";
  useEffect(() => {
    const handleMessage = (e) => {
      if (e.origin !== "https://widget.identomat.com") return;
      if (e.data !== "DONE") return;
      // Process done, check for result here
      console.log("Process completed, handle the result here.");
      updateCurrentStepHandler(1);
      toast.success("KYC Updated Successfully");
      submitKycHandler();
      // handleNext();
      setKycProcessComplete(true);
    };

    window.addEventListener("message", handleMessage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  return (
    <div style={{ width: "100%" }}>
      {sessionKey ? (
        <>
          <iframe
            src={url}
            allow="camera"
            style={{ width: "100%", height: "500px", border: "none" }}
          ></iframe>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "2rem",
              gap: 1,
            }}
          >
            <ButtonSecondary onClick={() => handleBack()}>Back</ButtonSecondary>

            <ButtonPrimary
              onClick={() => handleNext()}
              // className={!kycProcessComplete ? "disabled" : null}
              disabled={!kycProcessComplete}
            >
              Next
            </ButtonPrimary>
          </Stack>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default IdentomatKyc;
