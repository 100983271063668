import React, { useEffect, useState } from 'react';
import { Button } from 'rsuite';
import { Col, Row } from '@themesberg/react-bootstrap';
import {
  InputAdornment,
  TextField,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import InfoRounded from '@material-ui/icons/InfoRounded';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { textFieldLabelProps } from '../../../utils/common';

const validationSchema = yup.object({
  otp: yup
    .string('Enter your OTP')
    .min(2, 'Please enter valid OTP')
    .required('OTP is required!'),
});

function WithdrawOtpDialog({
  resendOTP,
  otpModal,
  onClose: handleOTPClose,
  title,
  loading = false,
  handleFinalSubmit,
  error = {},
}) {
  const [data, setdata] = useState({});
  const [resendOtpButtonActive, setResendOtpButtonActive] = useState(false);
  const [otpTimer, setOtpTimer] = useState(15);

  const { otp: estore_id = [] } = error;
  const onSubmit = async (values) => {
    const value = {
      ...values,
    };
    handleFinalSubmit(value);
  };

  useEffect(() => {
    let timer;
    if (otpTimer !== 0) {
      timer = setInterval(() => {
        setOtpTimer(otpTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [otpTimer]);

  const formik = useFormik({
    initialValues: data,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const handleChange = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    handleOTPClose();
  };

  return (
    <Dialog
      open={otpModal}
      maxWidth={'sm'}
      fullWidth
      disableEscapeKeyDown={true}
      onClose={handleCloseModal}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          width: '480px',
          minHeight: '216px',
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle className="text-center">
        <p className="dialog-title"> {title}</p>
      </DialogTitle>
      <hr className="hr" />

      <DialogContent className="mt-3">
        <form onSubmit={() => {}}>
          <Row>
            <Col xl={12} xs={12}>
              <TextField
                className="w-100 mb-3"
                variant="outlined"
                InputLabelProps={textFieldLabelProps}
                name="otp"
                id="otp"
                label="Enter OTP"
                type="text"
                value={formik.values.otp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
          </Row>
        </form>
      </DialogContent>
      <hr className="hr" />
      <DialogActions className="footer-content">
        <Button
          onClick={handleOTPClose}
          appearance="subtle"
          className="btn white-btn action-button basis-30"
        >
          Cancel
        </Button>
        <Button
          className={`btn action-button primary_color text-white basis-70 ${
            loading ? 'disabled' : null
          }`}
          appearance="primary"
          onClick={formik.handleSubmit}
          loading={loading}
          disabled={loading}
        >
          {!loading ? 'Submit' : 'Loading...'}
        </Button>
      </DialogActions>
      <DialogActions className="resend-otp-timer">
        {otpTimer > 0 ? <p>Resend OTP in {otpTimer}s</p> : null}
        <button
          disabled={otpTimer > 0}
          onClick={resendOTP}
          className="resend-otp-btn"
        >
          Resend OTP
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default WithdrawOtpDialog;
