import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import { Box, Stack, Drawer, IconButton, FormControlLabel, Switch, Typography, Tab, Tabs } from '@mui/material';
import { useSelector } from 'react-redux';
import useResponsive from '../../../hooks/useResponsive';
import ChatSearchResults from './ChatSearchResults';
import ChatConversationList from './ChatConversationList';
import { Routes } from '../../../routes';
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const ToggleButtonStyle = styled((props) => <IconButton disableRipple {...props} />)(({ theme }) => ({
  left: 0,
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  top: theme.spacing(13),
  borderRadius: `0 12px 12px 0`,
}));

// ----------------------------------------------------------------------

const SIDEBAR_WIDTH = 340;
const SIDEBAR_COLLAPSE_WIDTH = 96;

export default function ChatSidebar({
  toggleOpenTickets,
  openOnly,
  category,
  tabs,
  onCategoryFilterChanges,
  // detailView,
}) {
  const theme = useTheme();

  const history = useHistory();

  const { pathname } = useLocation();

  const [openSidebar, setOpenSidebar] = useState(true);

  const [searchQuery, setSearchQuery] = useState('');

  const [searchResults, setSearchResults] = useState([]);

  const [isSearchFocused, setSearchFocused] = useState(false);

  const { tickets: conversations } = useSelector((state) => state.chat);

  const isDesktop = useResponsive('up', 'md');

  const displayResults = searchQuery && isSearchFocused;

  const isCollapse = isDesktop && !openSidebar;

  useEffect(() => {
    if (!isDesktop) {
      return handleCloseSidebar();
    }
    return handleOpenSidebar();
  }, [isDesktop, pathname]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!openSidebar) {
      return setSearchFocused(false);
    }
  }, [openSidebar]);

  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleToggleSidebar = () => {
    setOpenSidebar((prev) => !prev);
  };

  const handleSearchSelect = (username) => {
    setSearchFocused(false);
    setSearchQuery('');
    history.push(Routes.UserChat.path + '/username');
  };

  const handleSelectContact = (result) => {
    if (handleSearchSelect) {
      handleSearchSelect(result.username);
    }
  };

  const renderContent = (
    <>
      <Box sx={{ mb: 1 }}>
        <Tabs
          value={category}
          onChange={(e, value) => onCategoryFilterChanges(value)}
          sx={{
            px: 2,
            bgcolor: '#F4F6F8',
            borderRadius: '8px 0 0 0',
          }}
        >
          {tabs.map((tab) => (
            <Tab
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              key={tab.label}
              value={tab.value}
              icon={
                <Label sx={{ marginRight: '5px', cursor: 'pointer' }} variant={'ghost'} color={tab.color}>
                  {tab.count}
                </Label>
              }
              label={tab.label}
              iconPosition={'start'}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ py: 2, px: 3, borderBottom: '1px solid grey' }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          {!isCollapse && (
            <FormControlLabel
              control={<Switch checked={openOnly} onChange={toggleOpenTickets} />}
              labelPlacement='end'
              label={<Typography variant='subtitle2'>Display Open Tickets</Typography>}
            />
          )}
        </Stack>
      </Box>

      {/* <Scrollbar> */}
      {!displayResults ? (
        <ChatConversationList
          conversations={conversations}
          isOpenSidebar={openSidebar}
          onCloseNav={handleCloseSidebar}
          openOnly={openOnly}
          category={category}
          // activeConversationId={activeConversationId}
          sx={{ ...(isSearchFocused && { display: 'none' }), overflow: 'auto' }}
        />
      ) : (
        <ChatSearchResults query={searchQuery} results={searchResults} onSelectContact={handleSelectContact} />
      )}
      {/* </Scrollbar> */}
    </>
  );

  return (
    <>
      {!isDesktop && (
        <ToggleButtonStyle onClick={handleToggleSidebar}>
          <Iconify width={16} height={16} icon={'eva:people-fill'} />
        </ToggleButtonStyle>
      )}

      {isDesktop ? (
        <Drawer
          open={openSidebar}
          variant='persistent'
          sx={{
            width: SIDEBAR_WIDTH,
            // transition: theme.transitions.create('width'),
            '& .MuiDrawer-paper': {
              position: 'static',
              width: SIDEBAR_WIDTH,
            },
            ...(isCollapse && {
              width: SIDEBAR_COLLAPSE_WIDTH,
              '& .MuiDrawer-paper': {
                width: SIDEBAR_COLLAPSE_WIDTH,
                position: 'static',
                transform: 'none !important',
                visibility: 'visible !important',
              },
            }),
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          ModalProps={{ keepMounted: true }}
          open={openSidebar}
          onClose={handleCloseSidebar}
          sx={{
            '& .MuiDrawer-paper': { width: SIDEBAR_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}
