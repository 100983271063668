import React from "react";
import { Paper, Box } from "@mui/material";
import Colors from "../../assets/colors";
const TableWrapper = ({ children }) => {
  return (
    <Paper sx={{ width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              fontWeight: 500,
              color: Colors.tableBodyText,
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: Colors.tableHeaderBg,
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        {children}
      </Box>
    </Paper>
  );
};

export default TableWrapper;
