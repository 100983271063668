import * as actionTypes from '../actionTypes';
import { updateObject } from './utility';

const initialState = {
  loading: false,
  storelist: [],
  error: null,
};

const getstorelist = (action, state) => {
  return updateObject(state, {
    loading: true,
  });
};

const setstorelist = (action, state) => {
  return updateObject(state, {
    loading: false,
    storelist: action.payload,
  });
};
const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STORE_LIST:
      return getstorelist(action, state);
    case actionTypes.SET_STORE_LIST:
      console.log(action);
      return setstorelist(action, state);

    default:
      return state;
  }
};

export default storeReducer;
