import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import "rsuite/dist/styles/rsuite-default.css";
import { Col } from "@themesberg/react-bootstrap";
import * as API from "../../../ApiUrl";
import { merchantApi } from "../../../redux/api/apiConfig";
import WithdrawTransactiontable from "../WithdrawTransactiontable";
import { formatCurrency } from "../../../utils/common";
import WithdrawModal from "../Components/WithdrawModal";
import { getWithdrawHighlights, verifyWithdrawOtp } from "../../../redux/actions/transactionActions";
import ButtonPrimary from "../../../components/ButtonPrimary";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import OtpModal from "../../../components/otpModal/OtpModal";

const Withdrawl = ({ userRole, isSingleWallet, permissions }) => {
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const [userCurrency, setUserCurrency] = useState("");
  const [translist, setTranslist] = useState([]);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [paginateTable, setpaginateTable] = useState({
    currentPage: 0,
    pageSize: 5,
    search: "",
    type: "",
    coin: "",
    store: "",
    status: "",
    fromDate: "",
    toDate: "",
  });
  const [highlights, setHighlights] = useState(null);
  const [createWithdrawRes, setCreateWithdrawRes] = useState(null); //createWithdrawRes
  const [otpLoading, setOtpLoading] = useState(false)

  const dispatch = useDispatch();
  const history = useHistory();


  const getTrans = async () => {
    setloading(true);
    const query = `?offset=${
      paginateTable.currentPage * paginateTable.pageSize
    }&limit=${paginateTable.pageSize}&search=${paginateTable.search}&coin=${
      paginateTable.coin
    }&store=${paginateTable.store}&status=${
      paginateTable.status
    }&type=withdraw`;
    merchantApi
      .get(`${API.TRANSACTION_LIST}${query}`, {
        params: {
          offset: paginateTable.currentPage * paginateTable.pageSize,
          limit: paginateTable.pageSize,
          search: paginateTable.search,
          type: "withdraw",
          coin: paginateTable.coin,
          store: paginateTable.store,
          status: paginateTable.status,
          from_date: paginateTable.fromDate,
          to_date: paginateTable.toDate,
        },
      })
      .then((response) => {
        if (response.data) {
          console.log(response);
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message || "Something went wrong");
        setloading(false);
      });
    setloading(false);
  };
  useEffect(() => {
    getTrans();
  }, [
    paginateTable.currentPage,
    paginateTable.pageSize,
    paginateTable.search,
    paginateTable.type,
    paginateTable.coin,
    paginateTable.store,
    paginateTable.status,
    paginateTable.fromDate,
    paginateTable.toDate,
  ]);

  useEffect(() => {
    getBalance();
    dispatch(
      getWithdrawHighlights(
        (res) => {
          setHighlights(res.data);
        },
        (err) => {
          console.log("cwcwecwc", err);
        }
      )
    );
  }, []);

  const toggleWithdrawModal = (text) => {
    setShowWithdrawModal(!showWithdrawModal);
  };
  const toggleOtpModal = () => {
    setShowOtpModal(!showOtpModal);
  };

  const onOtpSubmit = async (otp) => {
    setOtpLoading(true);
    const data = {
      otp
    };
    console.log("data", createWithdrawRes);
    dispatch(verifyWithdrawOtp(createWithdrawRes?.transaction?.id, data, (res) => {
      getTrans();
      toast.success(res.message);
      setOtpLoading(false)
      toggleOtpModal();
    },
    (err) => {
      setOtpLoading(false);
      toast.error(err?.response?.data?.message);
    }))
   
  };

  const getBalance = (coinId = null) => {
    setloading(true);

    let apiUrl = `${API.GET_BALANCE}`;
    if (coinId) {
      apiUrl += `?coin=${coinId}`;
    }
    merchantApi
      .get(apiUrl)
      .then((response) => {
        if (response && response.data) {
          if (response.data?.data) {
            setUserBalance(response.data.data.total_balance);
            setUserCurrency({
              symbol: response.data.data.currency.symbol,
              decimalPlace: response.data.data.currency.decimal_place,
              symbolPrefix: response.data.data.currency.symbol_prefix,
            });
          } else {
            toast.error(response.data.message);
          }
        }
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
        toast.error(
          "There was an error getting your balance information. Please try again!"
        );
      });
  };

 const onCreateWithdraw = (data) => {
  setCreateWithdrawRes(data);
  toggleWithdrawModal();
  toggleOtpModal();
 }

  return (
    <>
      <div className="page-wrapper stb-page-wrapper stb-ph-8">
        <div className="stb-h-100">
          <div className="reports stb-h-100 bg-white p-xl-4 radi">
            <div className="d-flex flex-col align-items-start gap-4">
              <div className="page-header space-between w-100">
                <div>
                  <h2 className="m-0 page-title">Withdrawal</h2>
                  <p className="report-title">Total Balance</p>
                  <h3 className="m-0 page-title">
                    {formatCurrency(
                      userBalance,
                      userCurrency.decimalPlace,
                      userCurrency.symbol,
                      userCurrency.symbolPrefix
                    )}
                  </h3>
                </div>
                {permissions?.merchantProfile?.withdrawal?.permission
                  ?.canWithdraw?.[userRole] ? (
                  <Stack gap={2} direction={"row"}>
                    <ButtonPrimary
                      onClick={() => history.push(Routes.WithdrawAccounts.path)}
                     >
                      Withdraw Accounts
                    </ButtonPrimary>
                    <ButtonPrimary
                      onClick={toggleWithdrawModal}
                    >
                      Withdraw
                    </ButtonPrimary>
                  </Stack>
                ) : (
                  ""
                )}
              </div>
              <div className="balance-cards d-flex align-items-center space-between w-100 gap-4">
                <div className="trans-card light-yellow">
                  <div className="card-content">
                    <span>
                      {formatCurrency(
                        highlights?.in_process || 0,
                        userCurrency.decimalPlace,
                        userCurrency.symbol,
                        userCurrency.symbolPrefix
                      )}
                    </span>
                    <p>In Process</p>
                  </div>
                </div>
                <div className="trans-card light-green">
                  <div className="card-content">
                    <span>
                      {formatCurrency(
                        highlights?.completed,
                        userCurrency.decimalPlace,
                        userCurrency.symbol,
                        userCurrency.symbolPrefix
                      )}
                    </span>
                    <p>Successfully Withdrawn</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <Col xs={12} xl={12} className="mb-4">
                <WithdrawTransactiontable
                  translist={translist}
                  loading={loading}
                  setpaginateTable={setpaginateTable}
                  paginateTable={paginateTable}
                />
              </Col>
            </div>
          </div>
        </div>
      </div>
      {showWithdrawModal && (
        <WithdrawModal
        isOpen={showWithdrawModal}
        onClose={toggleWithdrawModal}
        onSuccess={onCreateWithdraw}
        getTrans={getTrans}
        />
      )}
      {showOtpModal && (
        <OtpModal
          isOpen={showOtpModal}
          onClose={toggleOtpModal}
          onOtpSubmit={onOtpSubmit}
          loading={otpLoading}
          title="Enter OTP"
          subTitle="Please enter OTP"
         
        />
      )}
    </>
  );
};

function mapStateToProps({ authReducer }) {
  const { userRole, isSingleWallet, permissions } = authReducer;
  return {
    userRole,
    isSingleWallet,
    permissions,
  };
}

export default connect(mapStateToProps, null)(Withdrawl);

// if(coin?.length !== 3) {
//   coin =  Buffer.from(coin, 'utf-8').toString('hex');
//   coin = coin.padEnd(40, "0");
// }
