import React, { useRef } from "react";
import { Button } from "rsuite";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Stack,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import stblogo from "../assets/img/stbLogo.png";
import ReactToPrint from "react-to-print";
import ButtonPrimary from "./ButtonPrimary";
import ButtonSecondary from "./ButtonSecondary";
import Colors from "../assets/colors";
import PreviewContainer from "./preview/PreviewContainer";
import StyledTitle from "./preview/StyledTitle";
import StyledInfo from "./preview/StyledInfo";
import Image from "./image";

const QRDialog = ({
  userPayString,
  handleClose,
  open,
  onCreateCustomQrClick,
}) => {

  let componentRef = useRef();

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      // onClose={handleClose}
      maxWidth="md"
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
          width: "480px",
          minHeight: "538px",
          maxHeight: "80vh",
        },
      }}
    >
      <Stack ref={componentRef}>
        <DialogTitle>
          <Stack direction={"column"}>
            Pay Here
            <Typography variant="caption">
              Scan this QR code from SpendTheBits Cash app to pay
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "5px !important", justifyContent: "center" }}>
        <Stack sx={{justifyContent: "center", alignItems: "center", width:'100%'}}> 
          <Stack
            sx={{
              backgroundColor: Colors.cardBg,
              padding: 2,
              borderRadius: "8px",
              width:"fit-content",
              justifyContent: "center",
            }}
          >
            <QRCode size={180} value={userPayString} className=" center" />
          </Stack>
          </Stack>
          <PreviewContainer sx={{ width: "100%", marginTop: 2 }}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <StyledTitle>Paystring</StyledTitle>
            <StyledInfo>{userPayString}</StyledInfo>
            </Stack>
          </PreviewContainer>

          {/* <Stack sx={{ alignItems: "center", mt: 4 }}>
            <Typography variant="caption">Powered By</Typography>
            <Image style={{ width: '150px' }} src={stblogo} alt=""/>
          </Stack> */}
        </DialogContent>
      </Stack>
      <DialogActions
        sx={{ flexDirection: "column", gap:1 }}
      >
        <ReactToPrint
          trigger={() => <ButtonPrimary style={{width: "100%"}}>Print</ButtonPrimary>}
          content={() => componentRef.current}
          documentTitle={"STB QR"}
        />
        <ButtonSecondary onClick={onCreateCustomQrClick} style={{width: "100%"}}>
          Generate Dynamic QR Code
        </ButtonSecondary>
        <ButtonSecondary onClick={handleClose} style={{width: "100%"}}>Close</ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
};

function mapStateToProps({ authReducer }) {
  const { userPayString } = authReducer;
  return {
    userPayString,
  };
}

export default connect(mapStateToProps, null)(QRDialog);
