import axios from 'axios';
import * as API from '../../ApiUrl';
import {  RESET_LOADING, SET_CONVERSATION, SET_LOADING, SET_TICKETS } from '../actionTypes';
import { merchantApi } from '../api/apiConfig';

const getConversation = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await merchantApi.get(`${API.TICKET_LIST}${id}/`);
      console.log(response,'cwceca2c')
      dispatch({ type: SET_CONVERSATION, payload: response.data.id });
    } catch (error) {
      dispatch({ type: RESET_LOADING });
    }
  };
};
const sendMessage = (data, ticketId, isAttachment) => {

  console.log('c3ccsdc23c32', data)
  return async (dispatch) => {
    if (!isAttachment) {
      try {
        const response = await merchantApi.post(API.TICKET_REPLY, data);

        dispatch(getAllTickets());
      } catch (error) {
        dispatch({ type: RESET_LOADING });
      }
    } else {
      let token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(API.TICKET_REPLY, data, config);
        dispatch(getAllTickets());
      } catch (error) {

      }
    }
  };
};

// const createTicket = (data) => {
//   return async (dispatch) => {
//     // dispatch(slice.actions.startLoading());
//     try {
//       const response = await merchantApi.post(API.TICKET_LIST, data);
//       console.log(response);
//       dispatch(getAllTickets());
//     } catch (error) {
//       // dispatch(slice.actions.hasError(error));
//       console.log(error);
//       throw new Error(error?.message ?? 'Failed');
//     }
//   };
// };

const getAllTickets = (status) => {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    dispatch({ type: SET_LOADING });
    try {
      const response = await merchantApi.get(API.TICKET_LIST,{
        params:{
          limit: 100,
          status
        }
      });
      dispatch({ type: SET_TICKETS, payload: response.data.results });
    } catch (error) {
      dispatch({ type: RESET_LOADING });
      // dispatch(slice.actions.hasError(error));
    }
  };
};

const toggleStatus = (status, id) => {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await merchantApi.patch(`${API.TICKET_LIST}${id}/`, {
        status,
      });

      dispatch(getAllTickets());
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      throw new Error(error?.message ?? 'Failed');
    }
  };
};

const resetActiveConversation = (data) => {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      dispatch({ type: SET_CONVERSATION, payload: null });
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
};

export {
  getConversation,
  sendMessage,
  getAllTickets,
  toggleStatus,
  // createTicket,
  resetActiveConversation,
};
