import React, { useState, useEffect } from 'react';
import { Button } from 'rsuite';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import { startCase, toLower } from 'lodash';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Popover from '@mui/material/Popover';

import edit from '../../../../../assets/img/icons/edit.svg';
import cross from '../../../../../assets/img/icons/cross.svg';
import greentick from '../../../../../assets/img/icons/greentick.svg';
import pos from '../../../../../assets/img/icons/pos.svg';

import { UpdateStore } from '../UpdateStore';

import { Link, useHistory } from 'react-router-dom';

import { BiEdit } from 'react-icons/bi';
import { useSelector } from 'react-redux';
// import CounterTable from "./component/Coutertable";

const StoreTable = ({
  paginateTable,
  editClickHandler,
  storeData,
  loading,
}) => {
  console.log({paginateTable},'obq9eix9quwbix')
  // const storeData = useSelector((state) => state.storeReducer.storelist);

  // console.log('storeData', storeData);

  const [message, setMessage] = useState('No data to show.');
  const [filteredStores, setFilteredStores] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState('');

  const history = useHistory();

  useEffect(() => {
    if (storeData.length > 0) {
      let updatedStores = storeData;
      let searchTerm = paginateTable.search.trim().toLowerCase();

      if (searchTerm !== '') {
        updatedStores = updatedStores.filter(
          (store) =>
            store.id.toString().includes(searchTerm) ||
            store.name.toLowerCase().includes(searchTerm) ||
            store.phone.toString().includes(searchTerm) ||
            store.city.toLowerCase().includes(searchTerm)
        );
      }
      console.log(updatedStores,  paginateTable.status)
      if (paginateTable.status !== '') {
        if(paginateTable.status === 'active') {
          updatedStores = updatedStores.filter(
            (store) => store.status === paginateTable.status
          );
        } else if( paginateTable.status === 'inactive'){
          updatedStores = updatedStores.filter(
            (store) => store.status === paginateTable.status || store.status === 'unfunded'
          );
        }
        // updatedStores = updatedStores.filter(
        //   (store) => store.status === paginateTable.status
        // );
      }

      if (updatedStores?.length === 0) {
        setMessage('No items match the filters.');
      } else {
        setMessage('No data to show.');
      }

      setFilteredStores(updatedStores);
    } else {
      setFilteredStores(storeData);
    }
  }, [storeData, paginateTable.search, paginateTable.status]);

  const userRole = useSelector((state) => state.authReducer.userRole);

  // Datagrid components
  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {message}
      </Stack>
    );
  };

  const createDataRow = (store) => {
      return {
        id: store.id,
        store: startCase(toLower(store.name)),
        phone: store.phone,
        city: startCase(toLower(store.city)),
        counters: store.counter.length,
        storeData: store,
        pos: 'POS List',
        action: 'Edit Store',
      };
  };

  // console.log(filteredStores);

  const rows =
    filteredStores?.length > 0
      ? filteredStores.map((user) => createDataRow(user))
      : [];

  const columns = [
          {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            minWidth: 80,
            // maxWidth: 150,
            headerClassName: 'header',
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
              return <p style={{ color: 'black' }}>{params.formattedValue}</p>;
            },
            // align: 'center',
          },
          {
            field: 'store',
            headerName: 'Store',
            sortable: false,
            flex: 2,
            minWidth: 140,
            // maxWidth: 250,
            headerClassName: 'header',
            renderCell: (params) => {
              return <p style={{ color: 'black' }}>{params.formattedValue}</p>;
            },
            // align: 'center',
            // headerAlign: 'center',
          },
          {
            flex: 2,
            // maxWidth: 250,
            field: 'phone',

            minWidth: 150,
            headerName: 'Phone Number',
            sortable: false,
            type: 'email',
            headerClassName: 'header',
            // align: 'center',
          },
          {
            field: 'city',
            headerName: 'City',
            sortable: false,
            flex: 2,
            minWidth: 120,
            // maxWidth: 250,
            headerClassName: 'header',
            // align: 'center',
          },
          {
            field: 'counters',
            headerName: 'Counters',
            flex: 1,
            minWidth: 100,
            // maxWidth: 200,
            headerClassName: 'header',
            // align: 'center',
          },
          {
            field: 'pos',
            flex: 1,
            headerName: 'POS',

            minWidth: 120,
            sortable: false,
            // maxWidth: 150,
            headerClassName: 'header',
            renderCell: (params) => {
              const onClick = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                history.push('/administration/counter/' + params.id);
              };

              return (
                <Button
                  onClick={onClick}
                  style={{ background: 'none' }}
                  className="d-flex align-items-center gap-2"
                >
                  <img src={pos} alt="edit" />
                  <span style={{ color: 'black' }}>POS</span>
                </Button>
              );
            },
          },
          {
            flex: 1,
            field: 'action',
            headerName: 'Action',

            minWidth: 120,
            sortable: false,
            // maxWidth: 150,
            headerClassName: 'header',
            renderCell: (params) => {
              const onClick = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                // history.push('/administration/counter/' + params.id);
                // console.log(params);
                editClickHandler(params.row.storeData);
              };

              return (
                <Button
                  onClick={onClick}
                  style={{ background: 'none' }}
                  className="d-flex align-items-center gap-2"
                >
                  <img src={edit} alt="edit" />
                  <span style={{ color: 'black' }}>Edit</span>
                </Button>
              );
            },
          },
        ];

  const handlePopoverOpen = (event) => {
    const field = event.currentTarget.dataset.field;
    const id = event.currentTarget.parentElement.dataset.id;
    const row = rows.find((r) => r.id.toString() === id.toString());
    console.log(row, field, id, rows)
    setValue(row[field]);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div>
      <Box
        sx={{
          height: 615,
          width: 1,
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          }}
          rows={rows}
          columns={columns}
          // componentsProps={{
          //   cell: {
          //     onMouseEnter: handlePopoverOpen,
          //     onMouseLeave: handlePopoverClose,
          //   },
          // }}
          loading={loading}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
          rowHeight={72}
          pageSize={7}
          // rowsPerPageOptions={[7, 10, 20, 50]}
          disableColumnFilter={true}
          disableColumnMenu={true}
        />
        {/* <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1, borderRadius: '10px' }}>{value}</Typography>
        </Popover> */}
      </Box>
    </div>
  );
};

export default StoreTable;
