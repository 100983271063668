import React from 'react';

const BulletPointer = ({ fg = '#FCBD00', bg = '#fff0c2' }) => {
  return (
    <div className="pointer-bg" style={{ backgroundColor: bg }}>
      <div className="pointer-fg" style={{ backgroundColor: fg }}></div>
    </div>
  );
};

export default BulletPointer;
