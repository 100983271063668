import React from "react";
import emailimg from "../../assets/img/email.gif";
import { Link } from "react-router-dom";
import { Button } from "rsuite";

import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Stack,
} from "@mui/material";

import mail from "../../assets/img/icons/mail.svg";
import maillogo from "../../assets/img/icons/maillogo.svg";
import stblogo from "../../assets/img/icons/stblogo.svg";
import { RESEND_PASSWORD_MAIL } from "../../ApiUrl";
import axios from "axios";
import { toast } from "react-toastify";
import ButtonSecondary from "../../components/ButtonSecondary";
import ButtonPrimary from "../../components/ButtonPrimary";

const SignupThanks = (props) => {
  const {
    location: { state },
  } = props;

  console.log(state);

  const token = localStorage.getItem("token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const history = useHistory();

  const resendPasswordMail = async () => {
    console.log(state.id);
    const response = await axios.get(
      `${RESEND_PASSWORD_MAIL}${state.id}`,
      config
    );
    if (response.status === 200) {
      toast.success("Mail sent successfully.");
    }
    console.log(response);
  };

  const navigateToLogin = () => {
    history.push("/signin");
  };

  return (
    <section className="primary_color vh-100 position-relative">
      <img
        src={stblogo}
        className="position-absolute top-5 start-50 translate-middle"
        alt="logo"
      />

      <a
        className="socials twitter"
        href="https://twitter.com/Spend_The_Bits"
        target="_blank"
        rel="noopener noreferrer"
      >
        @Spend_The_Bits
      </a>
      <a
        className="socials mail"
        href="mailto:help@spendthebits.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={mail} alt="mail" />
        help@spendthebits.com
      </a>
      <Dialog
        open={true}
        disableEscapeKeyDown={true}
        // onClose={handleClose}
        fullWidth
        className="wallet-configure"
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
            width: "100%",
            maxWidth: "480px",
            minHeight: "428px",
            maxHeight: "80vh",
            padding: "1rem",
          },
        }}
      >
        <DialogTitle className="text-center">
          <p className="dialog-title fw-bold">Thank you for signing up</p>
          <p>Check your mail</p>
        </DialogTitle>
        <DialogContent className="merchant-wallet">
          <Stack marginBottom={4} alignItems="center" justifyContent="center">
            <img src={maillogo} alt="mail" />
          </Stack>
          <p className="text-center">
            <b className="mb-3">
              We just sent you a set your password link. Please check your inbox
              including spam folder.
            </b>
          </p>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            gap: "1rem",
          }}
          className="footer-content"
        >
          <ButtonSecondary
            onClick={resendPasswordMail}
            style={{ width: "100%" }}
          >
            Resend
          </ButtonSecondary>
          <ButtonPrimary style={{ width: "100%" }} onClick={navigateToLogin}>
            Login Now
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default SignupThanks;
