import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { Button } from 'rsuite';
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  // PaymentElement,
} from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TextField } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import * as API from '../ApiUrl';
import { useSelector } from 'react-redux';
import { merchantApi } from '../redux/api/apiConfig';

const fundValidation = yup.object({
  xrpAmount: yup
    .number('Enter XRP value')
    .min(10, 'Minimum 10 XRP needs to be funded.')
    .required('XRP amount is required.'),
});

const PaymentModal = ({ open, togglePayModal, goBack, userCurrency }) => {
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [XRPrate, setXRPrate] = useState(0);
  const merchantName = useSelector(
    (store) => store.profileReducer.user.merchant.business
  );

  const history = useHistory();


  useEffect(() => {
    getCoinType();
  }, []);

  const getCoinType = async () => {
    try {
      const response = await merchantApi.get(`${API.COIN}`);
      const XRPrate = response.data.data.find(
        (coin) => coin.symbol === 'XRP'
      ).rate;
      setXRPrate(XRPrate);
    } catch (error) {}
  };

  const stripe = useStripe();
  const elements = useElements();

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        height: '66px',
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const onSubmit = async () => {
    // event.preventDefault();

    setLoading(true);

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: merchantName,
      },
    });

    handlePaymentMethodResult(result);
  };

  const handlePaymentMethodResult = async (result) => {
    if (result.error) {
      toast.error('Payment Failed.');
      setLoading(false);
    } else {
      try {
        const response = await merchantApi.post(
          API.INITIAL_FUND,
          {
            payment_method_id: result.paymentMethod.id,
            currency_amount: (fundFormik.values.xrpAmount * XRPrate).toFixed(
              userCurrency.decimalPlace
            ),
            coin_amount: fundFormik.values.xrpAmount,
          }
        );
        toast.success('Payment successful');
        if (response.data.data.is_payment_complete) {
          togglePayModal();
          setLoading(false);
          history.push('/merchantprofile/wallets');
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        toast.error(error.response.data.message || 'Something went wrong');
        setLoading(false);
      }
    }
  };

  const fundFormik = useFormik({
    initialValues: {
      xrpAmount: 1,
    },
    validateOnBlur: true,
    onSubmit,
    isInitialValid: false,
    validateOnMount:true,
    validationSchema: fundValidation,
  });

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      onClose={() => {
        // togglePayModal();
      }}
      // onBackdropClick="false"
      className="wallet-configure"
      maxWidth="md"
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          width: '560px',
          minHeight: '400px',
          maxHeight: '80vh',
          padding: '0 2rem',
        },
      }}
    >
      <DialogTitle className="text-center">
        <p className="dialog-title">Fund your wallet</p>
      </DialogTitle>
      <DialogContent className="mt-3">
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        <TextField
          fullWidth
          variant="outlined"
          className="mt-4 bg-white"
          id="xrpAmount"
          name="xrpAmount"
          InputLabelProps={{
            style: {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            },
          }}
          type="number"
          label="XRP"
          value={fundFormik.values.xrpAmount}
          onChange={fundFormik.handleChange}
          onBlur={fundFormik.handleBlur}
          error={
            fundFormik.touched.xrpAmount && Boolean(fundFormik.errors.xrpAmount)
          }
          helperText={
            fundFormik.touched.xrpAmount && fundFormik.errors.xrpAmount
          }
        />
        <TextField
          fullWidth
          variant="outlined"
          className="mt-4 bg-white"
          id="currencyValue"
          name="currencyValue"
          InputLabelProps={{
            style: {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            },
          }}
          type="number"
          label={userCurrency.symbol}
          value={(fundFormik.values.xrpAmount * XRPrate).toFixed(
            userCurrency.decimalPlace
          )}
          disabled={true}
        />
      </DialogContent>

      <DialogActions className="footer-content">
        <Button
          className="btn white-btn action-button basis-30"
          onClick={goBack}
          appearance="subtle"
        >
          Back
        </Button>
        <Button
          className={`btn action-button primary_color text-white basis-70 ${
            loading ? 'disabled' : null
          }`}
          appearance="primary"
          onClick={fundFormik.handleSubmit}
          loading={loading}
          disabled={!fundFormik.isValid || !stripe}
        >
          Submit Payment
        </Button>
      </DialogActions>
      {/* <button type="submit" disabled={!stripe}>
          Submit Payment
        </button>
        <button onClick={goBack}>Back</button> */}
      {/* </form> */}
    </Dialog>
  );
};

export default PaymentModal;
