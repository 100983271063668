import * as API from "../../ApiUrl";
import {
  GET_PROFILE,
  GET_KYB_STATUS,
  SET_KYB_STATUS,
  SET_KYB_FORM_DATA,
  COMPLETE_FORM,
} from "../actionTypes";
import { merchantApi } from "../api/apiConfig";

const getStatus = () => async (dispatch) => {
  dispatch({ type: GET_KYB_STATUS });

  try {
    const res = await merchantApi.get(`${API.KYB_STATUS}`);
    console.log(res);
    dispatch({
      type: SET_KYB_STATUS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err, "errr");
  }
};

const kybStart = (d) => async (dispatch) => {
  const body = { attempt_id: d.attempt_id, KybStatuses: "not done" };

  try {
    const res = await merchantApi.get(`${API.KYB_START}`, body);
  } catch (err) {
    console.log(err, "errr");
  }
};

const uploadDocument = (d) => async (dispatch) => {
  try {
    const res = await merchantApi.patch(`${API.UPLOAD_KYB_DOC}`);
  } catch (err) {
    console.log(err, "errr");
  }
};

const setkybformdata = (d) => (dispatch) => {
  dispatch({ type: SET_KYB_FORM_DATA, payload: d });
};

const getPersonalInfo = (id, onSuccess, onError) => async (dispatch) => {
  merchantApi
    .get(`${API.SHAREHOLDER}${id}/`)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(({ error, responseData }) => {
      onError(responseData?.data || error);
    });
};

const updatePersonalInfo =
  (id, data, onSuccess, onError) => async (dispatch) => {
    merchantApi
      .patch(`${API.SHAREHOLDER}${id}/`, data)
      .then((response) => {
        onSuccess(response);
      })
      .catch(({ error, responseData }) => {
        onError(responseData?.data || error);
      });
  };

const getBusinessInfo = (onSuccess, onError) => async (dispatch) => {
  merchantApi
    .get(API.ADD_MERCHANT_API)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(({ error, responseData }) => {
      onError(responseData?.data || error);
    });
};

const getShareholdersList = (onSuccess, onError) => async (dispatch) => {
  merchantApi
    .get(API.SHAREHOLDER)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(({ error, responseData }) => {
      onError(responseData?.data || error);
    });
};

const updateShareHoldersList =
  (data, onSuccess, onError) => async (dispatch) => {
    merchantApi
      .post(API.SHAREHOLDER, data)
      .then((response) => {
        onSuccess(response);
      })
      .catch(({ error, responseData }) => {
        onError(responseData?.data || error);
      });
  };

const getCompanyRoles = (onSuccess, onError) => async (dispatch) => {
  merchantApi
    .get(API.COMPANY_ROLE)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(({ error, responseData }) => {
      onError(responseData?.data || error);
    });
};

const getPersonalDocs = (onSuccess, onError) => async (dispatch) => {
  merchantApi
    .get(API.SHAREHOLDER_KYC)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(({ error, responseData }) => {
      onError(responseData?.data || error);
    });
};

const getPersonalKycDocTypes = (onSuccess, onError) => async (dispatch) => {
  merchantApi
    .get(API.PERSONAL_KYC_DOC_TYPES)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(({ error, responseData }) => {
      onError(responseData?.data || error);
    });
};

const initiateKyc = (onSuccess, onError) => async (dispatch) => {
  merchantApi
    .post(API.SHAREHOLDER_KYC)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(({ error, responseData }) => {
      onError(responseData?.data || error);
    });
};

const initiateKyb = (onSuccess, onError) => async (dispatch) => {
  merchantApi
    .post(API.KYB_START)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(({ error, responseData }) => {
      onError(responseData?.data || error);
    });
};

const getBusinessDocs = (onSuccess, onError) => async (dispatch) => {
  merchantApi
    .get(API.UPLOAD_KYB_DOC)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(({ error, responseData }) => {
      onError(responseData?.data || error);
    });
};

const initateVerification = (onSuccess, onError) => async (dispatch) => {
  merchantApi
    .post(API.KYB_FINAL_SUBMIT)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(({ error, responseData }) => {
      onError(responseData?.data || error);
    });
};

const completeForm = () => {
  return {
    type: COMPLETE_FORM,
  };
};

export {
  initateVerification,
  getBusinessDocs,
  initiateKyb,
  initiateKyc,
  getPersonalKycDocTypes,
  getPersonalDocs,
  getCompanyRoles,
  getStatus,
  kybStart,
  setkybformdata,
  uploadDocument,
  getPersonalInfo,
  getBusinessInfo,
  updatePersonalInfo,
  getShareholdersList,
  updateShareHoldersList,
  completeForm,
};
