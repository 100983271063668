import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { connect } from "react-redux";
import ButtonPrimary from "../../../components/ButtonPrimary";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { handleBackgroundClick } from "../../../utils/common";
import PreviewContainer from "../../../components/preview/PreviewContainer";
import StyledTitle from "../../../components/preview/StyledTitle";
import StyledInfo from "../../../components/preview/StyledInfo";
import { format } from "date-fns";

const AddWithdrawAccountSuccessModal = ({
  isOpen,
  onClose,
  addAccountRes,
}) => {

  const handleClose = (event, reaon) => {
    handleBackgroundClick(reaon, onClose, false);
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={"xs"}
      onClose={handleClose}
      scroll={"paper"}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        Withdrawal account added
      </DialogTitle>
      <DialogContent
        sx={{ paddingTop: "5px !important", justifyContent: "center" }}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <IconButton>
            <AccessTimeIcon color="action" fontSize="medium" />
          </IconButton>
          <Typography variant="caption" sx={{ lineHeight: "110%" }}>
            You will be notified once account is verified. It takes 24-48 hours
            for account to be successfully added{" "}
          </Typography>
        </Stack>

        <PreviewContainer>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <StyledTitle>Date Added</StyledTitle>
            <StyledInfo>{format(new Date(addAccountRes?.created_date), 'yyyy-MM-dd')}</StyledInfo>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <StyledTitle>Interac Name</StyledTitle>
            <StyledInfo>{addAccountRes?.interac_name}</StyledInfo>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <StyledTitle>Interac Email</StyledTitle>
            <StyledInfo>{addAccountRes?.interac_email}</StyledInfo>
          </Stack>
        </PreviewContainer>
      </DialogContent>
      <DialogActions
        sx={{
          paddingX: "24px",
          paddingBottom: "15px",
        }}
      >
        <ButtonPrimary onClick={onClose}>
          Close
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};

function mapStateToProps({ authReducer }) {
  const { userPayString } = authReducer;
  return {
    userPayString,
  };
}

export default connect(mapStateToProps, null)(AddWithdrawAccountSuccessModal);
