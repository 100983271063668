import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Stack,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import ButtonPrimary from "../../../components/ButtonPrimary";
import ButtonSecondary from "../../../components/ButtonSecondary";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  handleBackgroundClick,
  textFieldLabelProps,
} from "../../../utils/common";
import InfoIcon from "@mui/icons-material/Info";
import { addInteracAccount } from "../../../redux/actions/accountActions";
import Colors from "../../../assets/colors";

const AddWithdrawAccountModal = ({ isOpen, onClose, onAddAccount }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const interacValidationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
  });

  const interacSubmit = async (values) => {
    setLoading(true);
    const requestData = {
      email: interacFormik.values.email,
    };
    dispatch(
      addInteracAccount(
        requestData,
        (res) => {
          toast.success(res.message);
          onAddAccount(res.data);
          setLoading(false);
        },
        (err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        }
      )
    );
  };

  const interacFormik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: interacSubmit,
    validateOnBlur: true,
    isInitialValid: false,
    validateOnMount: true,
    validationSchema: interacValidationSchema,
  });

  const handleClose = (event, reaon) => {
    handleBackgroundClick(reaon, onClose, false);
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={"xs"}
      onClose={handleClose}
      scroll={"paper"}
      BackdropProps={{ style: { backgroundColor: Colors.modalBackdrop } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {"Add Interac Withdrawal Account"}
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "5px !important" }}>
        <Stack direction={"column"} gap={2}>
          <TextField
            variant="outlined"
            name="email"
            id="email"
            InputLabelProps={textFieldLabelProps}
            label="Enter Interac Email"
            type="email"
            value={interacFormik.values.email}
            onChange={interacFormik.handleChange}
            onBlur={interacFormik.handleBlur}
            error={
              interacFormik.touched.email && Boolean(interacFormik.errors.email)
            }
            helperText={
              interacFormik.touched.email && interacFormik.errors.email
            }
          />
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <IconButton sx={{ paddingLeft: 0 }}>
            <InfoIcon fontSize="small" />
          </IconButton>
          <Typography variant="caption">
            It should match the same name as your business name
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          paddingX: "24px",
          paddingBottom: "15px",
          gap: 1
        }}
      >
        <ButtonSecondary disabled={loading} onClick={handleClose}>
          Cancel
        </ButtonSecondary>
        <ButtonPrimary loading={loading} onClick={interacFormik.handleSubmit}>
          Next
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};

function mapStateToProps({ authReducer }) {
  const { userPayString } = authReducer;
  return {
    userPayString,
  };
}

export default connect(mapStateToProps, null)(AddWithdrawAccountModal);
