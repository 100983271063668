import React from 'react';
import Paper from '@mui/material/Paper';
import * as Rsuite from 'rsuite';
import moment from 'moment-timezone';
import { startCase, toLower } from 'lodash';

import BTC from '../../assets/img/icons/BTC.svg';
import XRP from '../../assets/img/icons/XRP.svg';
import LTC from '../../assets/img/icons/LTC.svg';
import ELS from '../../assets/img/icons/ELS.png';

import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Popover from '@mui/material/Popover';
import { DataGrid } from '@mui/x-data-grid';
import {Box, Chip} from '@mui/material';

import { formatCurrency } from '../../utils/common';

const cellStyle = {
  color: 'black',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export default function WithdrawTransactiontable({
  translist,
  paginateTable,
  setpaginateTable,
  loading,
}) {

  console.log(translist);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setpaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: +event.target.value,
    });
  };

  const createDataRow = (transaction) => ({
    id: transaction.reference_number,
    createdDate:  moment().format('YY') === moment(transaction.created_date).format('YY')
    ? moment(transaction.created_date).format('MMM Do')
    : moment(transaction.created_date).format('MMM Do YY'),
    lastUpdate:
      moment().format('YY') === moment(transaction.modified_date).format('YY')
        ? moment(transaction.modified_date).format('MMM Do')
        : moment(transaction.modified_date).format('MMM Do YY'),

    sender: startCase(toLower(transaction.sender?.name)),
   value: formatCurrency(
      transaction.send_amount,
      transaction.sender_coin.decimal_place,
      transaction.sender_coin.symbol
    ),
   
    details: transaction.blockchain_url,
    status: transaction.current_status,
  });

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: 'value',
      headerName: 'Amount',
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1.5,
      minWidth: 150,
      headerClassName: "header",
      renderCell: (params) => {
        return params.formattedValue?.type === "success" ? (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#ECFDF3",
              color: "#027A48",
            }}
           
            label={params?.formattedValue?.status_txt_short}
            color="success"
          />
        ) : params.formattedValue?.type === "warning" ? (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#FFF7CD",
              color: "rgb(122, 79, 1)",
            }}
           
            color="warning"
            label={params?.formattedValue?.status_txt_short}
          />
        ) : (
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: "#f8dada",
              color: "#B42318",
            }}
           
            color="error"
            label={params?.formattedValue?.status_txt_short}
          />
        );
      },
    },
    {
      field: 'lastUpdate',
      headerName: 'Last Updated',
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
    {
      field: 'sender',
      headerName: 'Requested By',
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return <p style={cellStyle}>{params.formattedValue}</p>;
      },
    },
  
  ];



  return (
    <>
      <>
        <Paper sx={{ width: '100%' }}>
          <Box
            sx={{
              height: 615,
              width: 1,
              '& .MuiDataGrid-virtualScrollerRenderZone': {
                '& .MuiDataGrid-row': {
                  // backgroundColor: 'rgba(235, 235, 235, .7)',
                  fontWeight: 500,
                  color: '#667085',
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px',
                },
              },
              '& .header': {
                backgroundColor: '#F4F4F4',
                '& div': {
                  '& div': {
                    '& div': {
                      '& div': {
                        fontSize: '14px',
                        fontWeight: 600,
                        fontFamily: 'Inter, sans-serif',
                      },
                    },
                  },
                },
              },
            }}
          >
            <DataGrid
              sx={{
                borderRadius: '8px',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
              }}
              // componentsProps={{
              //   cell: {
              //     onMouseEnter: handlePopoverOpen,
              //     onMouseLeave: handlePopoverClose,
              //   },
              // }}
              components={{
                LoadingOverlay: LinearProgress,
              }}
              rows={rows}
              columns={columns}
              loading={loading}
              rowHeight={72}
              disableColumnFilter={true}
              disableColumnMenu={true}
              pagination
              paginationMode="server"
              rowsPerPageOptions={[5, 10, 20, 50]}
              page={paginateTable.currentPage}
              rowCount={translist.count}
              pageSize={paginateTable.pageSize}
              onPageChange={(newPage) => handleChangePage(newPage)}
              onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
            />
            {/* <Popover
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1, borderRadius: '10px' }}>
                {value}
              </Typography>
            </Popover> */}
          </Box>
        </Paper>
      </>
    </>
  );
}
