import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../redux/actions/modalActions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ButtonSecondary from "../ButtonSecondary";
import ButtonPrimary from "../ButtonPrimary";

const NavigationModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isModalOpen = useSelector((state) => state.modalReducer.isModalOpen);
  const nextPath = useSelector((state) => state.modalReducer.nextPath);

  const handleConfirmNavigation = () => {
    dispatch(closeModal());
    history.push(nextPath);
  };

  const handleCancelNavigation = () => {
    dispatch(closeModal());
  };

  if (!isModalOpen) return null;

  return (
    <>
      {/* <div className="modal-backdrop">
      <div className="modal">
        <p>
          You have not completed all the steps. Are you sure you want to leave?
        </p>
        <button onClick={handleConfirmNavigation}>Yes</button>
        <button onClick={handleCancelNavigation}>No</button>
      </div>
    </div> */}
      <Dialog
        open={isModalOpen}
        fullWidth
        maxWidth={"xs"}
        onClose={handleCancelNavigation}
        scroll={"paper"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          KYB/KYC Flow not completed yet
        </DialogTitle>
        <DialogContent
          sx={{ paddingTop: "5px !important", justifyContent: "center" }}
        >
          <Typography variant="subtitle2">
            You have not completed all the steps. Are you sure you want to
            leave?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            paddingX: "24px",
            paddingBottom: "15px",
            gap: 1,
          }}
        >
          <ButtonSecondary onClick={handleCancelNavigation}>No</ButtonSecondary>
          <ButtonPrimary onClick={handleConfirmNavigation}>Yes</ButtonPrimary>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NavigationModal;
