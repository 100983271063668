import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Button, Dropdown } from '@themesberg/react-bootstrap';
import { GeneralInfo } from '../GeneralInfo';
import ProfileCard from './ProfileCard';
import { merchantApi } from '../../redux/api/apiConfig';
import { MERCHANT_PROFILE } from '../../ApiUrl';
import {toast} from 'react-toastify'

export default () => {
  const [userInfo] = useState(JSON.parse(localStorage.getItem('user-info')));
  const [userProfileData, setUserProfileData] = useState({});

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = () => {
    merchantApi
      .get(`${MERCHANT_PROFILE}${userInfo.user_id}/`)
      .then((response) => {
        const { data } = response;
        setUserProfileData(data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message || 'Something went wrong');
      });
  };

  return (
    <>
      {userProfileData && Object.keys(userProfileData).length > 0 && (
        <>
          <Col xs={12} xl={12}>
            <Row style={{ maxWidth: '100%' }}>
              <Col xs={12}>
                <ProfileCard userProfileData={userProfileData} />
              </Col>
            </Row>
          </Col>
          <Row style={{ maxWidth: '100%', margin: '0' }} className="mt-3">
            <Col xs={12} xl={12}>
              <GeneralInfo userProfileData={userProfileData} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
