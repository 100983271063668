import * as API from '../../ApiUrl';
import { merchantApi } from '../api/apiConfig';



const getInteracAccount = (params,onSuccess, onError) => async dispatch => {
  merchantApi
    .get(API.INTERAC_ACCOUNT,{
      params
    })
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((err) => {
      onError(err);
    });
};


  const addInteracAccount = (data, onSuccess, onError) => async dispatch => {
    merchantApi
      .post(API.INTERAC_ACCOUNT, data)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const verifyInteracAccountOtp = (id, data, onSuccess, onError) => async dispatch => {
    merchantApi
      .patch(`${API.INTERAC_ACCOUNT}${id}/`, data)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const activateInteracAccount = (id, onSuccess, onError) => async dispatch => {
    merchantApi
      .post(`${API.INTERAC_ACCOUNT}${id}/${API.ACTIVATE_FOR_WITHDRAW}`)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const verifyInteracAccountForWithdrawOtp = (id, data, onSuccess, onError) => async dispatch => {
    merchantApi
      .post(`${API.INTERAC_ACCOUNT}${id}/${API.ACTIVATE_FOR_WITHDRAW_VERIFY}`,data)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const deleteInteracAccount = (id, onSuccess, onError) => async dispatch => {
    merchantApi
      .delete(`${API.INTERAC_ACCOUNT}${id}/`)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const generateCustomQr = (data, onSuccess, onError) => async dispatch => {
    merchantApi
      .post(API.PAYMENT_INTENT, data)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };


  const getCustomQr = (id, onSuccess, onError) => async dispatch => {
    console.log('here')
    merchantApi
      .get(`${API.PAYMENT_INTENT}${id}/`)
      .then((response) => {
        console.log('heretoo')
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };


  const getWalletChartData = (onSuccess, onError) => async dispatch => {
    merchantApi
      .get(API.WALLET_CHART_DATA)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const getBalance = (onSuccess, onError) => async dispatch => {
    merchantApi
      .get(API.GET_BALANCE)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const getFraudData = (id, onSuccess, onError) => async dispatch => {
    merchantApi
      .get(`${API.FRAUD_REPORT}${id}/`)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const blockAction = (data, onSuccess, onError) => async dispatch => {
    merchantApi
      .post(API.BLOCK_ACTION, data)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };



 
export {getCustomQr,blockAction, getFraudData, getBalance, getWalletChartData, generateCustomQr, addInteracAccount, verifyInteracAccountOtp, getInteracAccount, activateInteracAccount, deleteInteracAccount, verifyInteracAccountForWithdrawOtp};
