import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Button } from "@themesberg/react-bootstrap";
import * as Rsuite from "rsuite";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { startCase, toLower } from "lodash";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Popover from "@mui/material/Popover";

import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Chip } from "@mui/material";
import greentick from "../../../assets/img/icons/greentick.svg";
import cross from "../../../assets/img/icons/cross.svg";
import warning from "../../../assets/img/icons/warning.svg";

import * as API from "../../../ApiUrl";
import { send } from "../../../utils/xrpl_functions";
import WithdrawPassphraseDialog from "../../merchantprofile/Components/WithdrawPassphraseDialog";
import { merchantApi } from "../../../redux/api/apiConfig";
import { ADMIN, MANAGER, formatCurrency, getStatusChip } from "../../../utils/common";
import TableWrapper from "../../components/TableWrapper";

const xlsx = require("xlsx");

export default function Transactiontable({
  translist,
  paginateTable,
  setpaginateTable,
  handleCallback,
  userRole,
  resetTransactions = null,
  fetchTranslistLoading,
}) {
  
  const cellStyle = {
    color: "black",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };


  const handleChangePage = (newPage) => {
    setpaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: +event.target.value,
    });
  };


  const createDataRow = (transaction) => {
    return {
      id: transaction.reference_number,
      lastUpdate:
        moment().format("YY") === moment(transaction.modified_date).format("YY")
          ? moment.parseZone(transaction.modified_date).format("MMM Do")
          : moment.parseZone(transaction.modified_date).format("MMM Do YY"),
      sender:
        transaction?.type === "refund"
          ? startCase(toLower(transaction?.receiver?.name))
          : startCase(toLower(transaction.sender?.name)),
      remarks:transaction?.remarks || '--',
      receiver:
        transaction?.type === "withdraw"
          ? transaction?.receiver?.name
          : startCase(toLower(transaction?.receiver?.name)),
      store: startCase(toLower(transaction?.store?.name || "N/A")),
      counter: startCase(toLower(transaction?.counter?.name || "N/A")),
      value: formatCurrency(
        transaction?.receive_amount,
        transaction?.receiver_coin?.decimal_place,
        transaction?.receiver_coin?.symbol,
        transaction?.receiver_coin?.symbol_prefix
      ),
      fees: formatCurrency(
        transaction?.total_fee,
        transaction?.receiver_coin?.decimal_place,
        transaction?.receiver_coin?.symbol,
        transaction?.receiver_coin?.symbol_prefix
      ),
      balanceAfterFees: formatCurrency(
        Number(transaction?.receive_amount) - Number(transaction?.total_fee),
        transaction?.receiver_coin?.decimal_place,
        transaction?.receiver_coin?.symbol,
        transaction?.receiver_coin?.symbol_prefix
      ),
      // currency: transaction.currency.code,
      type: startCase(toLower(transaction.type)),
      status: transaction.current_status,
      action: "Action",
      refund: "Refund",
      transactionData: transaction,
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const createDataColumn = (role) => {
      if (role === ADMIN ||  role === MANAGER) {
        return [
          {
            field: "id",
            headerName: "ID",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "lastUpdate",
            headerName: "Last Updated",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "sender",
            headerName: "Customer Name",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            textOverflow: "ellipsis",
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "remarks",
            headerName: "Remarks",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            textOverflow: "ellipsis",
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          
          {
            field: "store",
            headerName: "Store",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "counter",
            headerName: "Counter",
            flex: 1.5,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "value",
            headerName: "Transaction Value",
            flex: 1.5,
            minWidth: 150,
            // maxWidth: 200,
            headerClassName: "header",
            sortable: false,
            renderCell: (params) => {
              return <p style={{...cellStyle, fontWeight:'bold'}}>{params.formattedValue}</p>;
            },
          },
          {
            field: "type",
            headerName: "Type",
            flex: 1,
            minWidth: 80,
            // maxWidth: 150,
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "status",
            headerName: "Status",
            sortable: false,
            flex: 1.5,
            minWidth: 220,
            headerClassName: "header",
            renderCell: (params) => {
              return getStatusChip(params.formattedValue)
            },
          },
          // {
          //   field: "action",
          //   headerName: "Action",
          //   sortable: false,
          //   flex: 1.5,
          //   minWidth: 120,
          //   headerClassName: "header",
          //   renderCell: (params) => {
          //     let row = params.row.transactionData;

          //     return row.status.value === "pending_admin_approval" ? (
          //       <div style={{ display: "flex", flexDirection: "column" }}>
          //         <Button
          //           style={{ height: "40px" }}
          //           onClick={() => {
          //             handleApprovalStatus("approved", row);
          //           }}
          //           size="sm"
          //           variant="outline-success"
          //           disabled={loading}
          //         >
          //           Approve
          //         </Button>
          //         <Button
          //           onClick={() => handleApprovalStatus("rejected", row)}
          //           size="sm"
          //           variant="outline-danger"
          //           style={{ marginTop: 5, height: "40px" }}
          //           disabled={loading}
          //         >
          //           Reject
          //         </Button>
          //       </div>
          //     ) : (
          //       <Button
          //         size="sm"
          //         disabled
          //         style={{ height: "40px" }}
          //         variant={
          //           row["approval_status"] === "Approved"
          //             ? "outline-success"
          //             : "outline-danger"
          //         }
          //       >
          //         {row["approval_status"]}
          //       </Button>
          //     );
          //   },
          // },
          {
            field: "refund",
            headerName: "Refund",
            sortable: false,
            flex: 2,
            headerClassName: "header",
            minWidth: 200,
            renderCell: (params) => {
              let row = params.row.transactionData;

              return row["type"] === "receipt" ? (
                <Rsuite.Button
                  onClick={() => handleCallback(row)}
                  disabled={row.is_refunded?.refunded}
                  appearance="default"
                  className={`w-xs-100 primary_color mt-2 mb-2 font-size-12 text-white font-700 font-fam ${
                    row.is_refunded?.refunded === 0 ? "disabled" : ""
                  }`}
                >
                  {row.is_refunded?.refunded
                    ? "Amount Refunded"
                    : "Initiate Refund"}
                </Rsuite.Button>
              ) : row["type"] === "refund" ? (
                <Rsuite.Button
                  appearance="default"
                  className="w-xs-100 success_color mt-2 mb-2 text-black font-700 font-fam"
                >
                  {row["status"] !== "processed" ? (
                    <span> Refund Initiated</span>
                  ) : (
                    <span className="text-success">Refund Completed</span>
                  )}
                </Rsuite.Button>
              ) : (
                "N/A"
              );
            },
          },
        ];
      } else {
        return [
          {
            field: "id",
            headerName: "ID",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "lastUpdate",
            headerName: "Last Updated",
            flex: 1,
            minWidth: 120,
            // maxWidth: 200,
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "sender",
            headerName: "Customer Name",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            textOverflow: "ellipsis",
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "remarks",
            headerName: "Remarks",
            flex: 1.5,
            minWidth: 200,
            // maxWidth: 200,
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
         
          {
            field: "value",
            headerName: "Transaction Value",
            flex: 1.5,
            minWidth: 150,
            // maxWidth: 200,
            headerClassName: "header",
            sortable: false,
            renderCell: (params) => {
              return <p style={{...cellStyle, fontWeight:'bold'}}>{params.formattedValue}</p>;
            },
          },

         

          {
            field: "type",
            headerName: "Type",
            flex: 1,
            minWidth: 80,
            // maxWidth: 150,
            headerClassName: "header",
            renderCell: (params) => {
              return <p style={cellStyle}>{params.formattedValue}</p>;
            },
          },
          {
            field: "status",
            headerName: "Status",
            sortable: false,
            flex: 1.5,
            minWidth: 150,
            headerClassName: "header",
            renderCell: (params) => {
              return getStatusChip(params.formattedValue)
            },
          },
        ];
      }
  };

  const columns = createDataColumn(userRole);

  return (
    <>
      <TableWrapper>
        <DataGrid
          sx={{
            borderRadius: "8px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
            width: "100%",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          autoHeight
          rows={rows}
          columns={columns}
          rowHeight={60}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={[10, 20, 50]}
          page={paginateTable.currentPage}
          rowCount={translist.count}
          pageSize={paginateTable.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </TableWrapper>
    </>
  );
}
