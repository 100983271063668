import React from "react";
import { styled } from "@material-ui/core/styles";
import { Typography, Stack, Paper } from "@mui/material";
import { formatCurrency, statusColors } from "../../utils/common";
import Colors from "../../assets/colors";

const MyPaper = styled(Paper)({
  borderRadius: 8,
  backgroundColor: "#F0F0F0",
  padding: "1rem",
});

const InteracDetailsCard = ({ balance,
  stbFee,
  handlerFee,
  amount,
  wallet, sx }) => {
  const pendingBalance = Number(balance) -( Number(amount) + Number(stbFee) + Number(handlerFee)) || 0
  const styledInfo = (data, sx={}) => {
    return (
      <Typography
        variant="subtitle2"
        sx={{
          wordBreak: { sm: "break-all", md: "initial" },
          overflow: "hidden",
          textOverflow: "ellipsis",
          ...sx
        }}
      >
        {data}
      </Typography>
    );
  };

  const styledTitle = (data, sx) => {
    return (
      <Typography sx={{ color: "#4a4a4a", ...sx }} variant="caption">
        {data}
      </Typography>
    );
  };
  return (
    <Stack sx={{textAlign:'left', width:'100%', marginBottom:'1rem !important'}}>
    <MyPaper
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "1rem",
        border: pendingBalance < 0 ? `1px solid ${Colors.error}` : "inherit",
        ...sx,
      }}
      elevation={0}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack>{styledTitle("Available Balance")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo(formatCurrency(balance, wallet?.coin?.decimal_place, wallet?.coin?.symbol, wallet?.coin?.symbol_prefix))}</Stack>
      </Stack>
      {/* <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack>{styledTitle("Name")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>
          {styledInfo(`${userData?.first_name} ${userData?.last_name}`)}
        </Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack>{styledTitle("Email Address")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo(userData?.email)}</Stack>
      </Stack>{" "}
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack>{styledTitle("Transfer Time")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo("2 hours")}</Stack>
      </Stack> */}
      <Stack direction={"row"} justifyContent={"space-between"} spacing={4}>
        <Stack>{styledTitle("Amount")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo(formatCurrency(amount, wallet?.coin?.decimal_place, wallet?.coin?.symbol, wallet?.coin?.symbol_prefix))}</Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} spacing={4}>
        <Stack>{styledTitle("STB Fee")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo(formatCurrency(stbFee, wallet?.coin?.decimal_place, wallet?.coin?.symbol, wallet?.coin?.symbol_prefix) )}</Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} spacing={4}>
        <Stack>{styledTitle("Handler Fee")}</Stack>
        <Stack sx={{ maxWidth: "100%" }}>{styledInfo(formatCurrency(handlerFee, wallet?.coin?.decimal_place, wallet?.coin?.symbol, wallet?.coin?.symbol_prefix) )}</Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} spacing={4}>
        <Stack>{styledTitle("Pending Balance Remaining", { color: pendingBalance < 0 ? Colors.error: "inherit" })}</Stack>
        <Stack sx={{ maxWidth: "100%", }}>
          {styledInfo(formatCurrency(pendingBalance, wallet?.coin?.decimal_place, wallet?.coin?.symbol, wallet?.coin?.symbol_prefix), { color: pendingBalance < 0 ? Colors.error : "inherit" })}
        </Stack>
      </Stack>
    </MyPaper>
    {pendingBalance < 0 && <Typography variant="caption" color={Colors.error} >Insufficient Balance</Typography>}
    </Stack>
  );
};

export default InteracDetailsCard;
