import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { Loader } from 'rsuite';

const DoughnutChartComp = ({ data }) => {
  const content = data ? (
    <ResponsiveContainer height="80%" width="100%">
      <PieChart width="100%" height="100%">
        <Pie
          dataKey="value"
          data={data}
          innerRadius={40}
          outerRadius={80}
          fill="#82ca9d"
        />
        <Tooltip />
        <Legend
          formatter={(value, entry, index) => (
            <span className="chart-legend">{value}</span>
          )}
        />
      </PieChart>
      {/* <div>
        
      </div> */}
    </ResponsiveContainer>
  ) : (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader content="loading..." />
    </div>
  );

  return content;
};

export default DoughnutChartComp;
