import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Input, Divider, IconButton } from '@mui/material';
// utils
import Iconify from '../../../components/Iconify';
import { urlRegex } from '../../../utils/common';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: 56,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  paddingLeft: theme.spacing(2),
}));

// ----------------------------------------------------------------------

ChatMessageInput.propTypes = {
  disabled: PropTypes.bool,
  conversationId: PropTypes.number,
  onSend: PropTypes.func,
};

export default function ChatMessageInput({ disabled, conversationId, onSend, getInputProps, file }) {
  const fileInputRef = useRef(null);
  const [message, setMessage] = useState('');

  const handleAttach = () => fileInputRef.current?.click();

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const handleSend = () => {
    if (!message && !file) {
      return '';
    }
    console.log(message.match(urlRegex));
    if (message && message.match(urlRegex)) {
      toast.error('Links are not allowed in chat');
      return '';
    }
    if (onSend && conversationId) {
      onSend({
        ticket: conversationId,
        message,
      });
    }
    return setMessage('');
  };

  return (
    <RootStyle>
      <Input
        disabled={disabled}
        fullWidth
        value={message}
        disableUnderline
        onKeyUp={handleKeyUp}
        onChange={(event) => setMessage(event.target.value)}
        placeholder={file ? 'Caption (optional)' : 'Type a message'}
        endAdornment={
          <Stack direction='row' spacing={1} sx={{ flexShrink: 0, mr: 1.5 }}>
            <IconButton disabled={disabled} size='small' onClick={handleAttach}>
              <Iconify icon='ic:round-add-photo-alternate' width={22} height={22} />
            </IconButton>
          </Stack>
        }
      />

      <Divider orientation='vertical' flexItem />

      <IconButton
        // {...getInputProps}
        color='primary'
        disabled={!message && !file}
        onClick={handleSend}
        sx={{ mx: 1 }}
      >
        <Iconify icon='ic:round-send' width={22} height={22} />
      </IconButton>

      <input
        {...getInputProps()}
        type='file'
        ref={fileInputRef}
        // onChange={uploadImage}
        style={{ display: 'none' }}
      />
    </RootStyle>
  );
}
