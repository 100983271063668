import * as actionTypes from "../actionTypes";
import { updateObject } from "./utility";

const initialState = {
  isModalOpen: false,
  nextPath: "",
};

const openModal = (state, action) => {
  return updateObject(state, {
    isModalOpen: true,
    nextPath: action.payload,
  });
};

const closeModal = (state) => {
  return updateObject(state, {
    isModalOpen: false,
    nextPath: "",
  });
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return openModal(state, action);
    case actionTypes.CLOSE_MODAL:
      return closeModal(state);
    default:
      return state;
  }
};

export default modalReducer;
