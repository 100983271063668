import PropTypes from 'prop-types';
import React from 'react';
// @mui
import { Dialog, Button, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import ButtonSecondary from '../ButtonSecondary';

// ----------------------------------------------------------------------

ConfirmModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
};

export default function ConfirmModal({ title, content, action, open, onClose, ...other }) {
  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
        },
      }}
      {...other}
    >
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

      <DialogActions sx={{gap:1}}>
        <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
        {action}
      </DialogActions>
    </Dialog>
  );
}
