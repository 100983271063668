import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Container,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { TextField, MenuItem, CircularProgress } from "@material-ui/core";
import { useFormik } from "formik";
import "react-phone-number-input/style.css";
import "react-phone-input-2/lib/style.css";
import * as yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import ReactFlagsSelect from "react-flags-select";
import Checkbox from "@mui/material/Checkbox";
import stblogo from "../../assets/img/icons/stblogo.svg";
import signupbg from "../../assets/img/signupcash.png";
import back from "../../assets/img/icons/back.svg";
import tick from "../../assets/img/icons/tick.svg";
import mail from "../../assets/img/icons/mail.svg";
import { numricRegex, removeCodeFromPhone, textFieldLabelProps } from "../../utils/common";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import { DatePicker } from "@mui/x-date-pickers";
import { format, isBefore, subYears } from "date-fns";
import * as API from "../../ApiUrl";
import {
  Autocomplete,
  FormControlLabel,
  Typography,
  Link,
} from "@mui/material";
import Colors from "../../assets/colors";
import { merchantApi } from "../../redux/api/apiConfig";





export default ({ changepage, countryList, categoryList, occupationList }) => {
  const history = useHistory();

  const countryCodes = countryList.reduce((acc, cur) => {
    acc.push(cur.alpha2code);
    return acc;
  }, []);

  const [error, setError] = useState(null);
  const [loading, setloading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isOtherCatVisible, setisOtherCatVisible] = useState(false);
  const [currentSection, setCurrentSection] = useState("user");
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [businessCountry, setBusinessCountry] = useState(null);
  const [ipInfo, setIpInfo] = useState([]);
  const [userId, setUserId] = useState(null);
  const [phoneCountry, setPhoneCountry] = useState({
    countryCode: 'ca',
    dialCode: '1'
  })

  const userValidationSchema = yup.object({
    first_name: yup
      .string("Enter your first name")
      .min(2, "Please enter your legal first name")
      .required("First name is required"),
  
    last_name: yup
      .string("Please enter your legal last name")
      .required("Last name is required"),
  
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
  
    pep: yup.boolean("Enter your pep").required("PEP is required"),
  
    dob: yup
    .date("Enter a valid date")
    .max(new Date(), "Date of birth cannot be in the future")
    .required("Date of birth is required")
    .nullable()
    .test("age", "Must be at least 16 years old",  value => {
      // 'value' is the parsed date object
      const currentDate = new Date();
      const userDOB = new Date(value);
  
      // Check if the user is at least 16 years old
      return isBefore(userDOB, subYears(currentDate, 16));
    }).nullable(),
  
    phone_number: yup
    .string("Enter your phonenumber")
    .matches(numricRegex, "Only Number Required")
    .test("len", "Enter Valid Number", phone => {
      return removeCodeFromPhone(phone, phoneCountry.dialCode)?.length === 10;
    })
    .required("Phone Number is required"),
  });
  
  const businessValidationSchema = yup.object({
    country: yup.string("Select country").required("Country is required"),
    province: yup.string("Select province").required("Province is required"),
    address: yup.string("Enter address").required("Address is required"),
    city: yup.string("Enter city").required("City is required"),
    postal_code: yup
      .string(" Enter postal code")
      .required("Postal code is required"),
    occupation_type: yup
      .string("Select occupation type")
      .required("Occupation type is required"),
    business_name: yup
      .string("Enter your business name")
      .min(2, "Please enter you real name")
      .required("Business name is required"),
  
    no_of_stores: yup
      .number("Enter your stores count")
      .min(1, "Should have minimum one store")
      .positive("Enter valid stores count")
      .required("Store count is required")
      .integer(),
  
    business_category: yup
      .string("Enter your business category")
      .required("Business category is required"),
  
    business_subcategory: yup
      .string("Enter your business subcategory")
      .required("Business subcategory is required"),
  
    acceptTerms: yup
      .boolean()
      .oneOf([true], "Accept Terms & Conditions is required"),
  });

  useEffect(() => {
    if (selectedCategory) {
      const selectedCategoryItem = _.find(categoryList, {
        id: selectedCategory.value,
      });
      if (selectedCategoryItem.name === "Others") {
        setisOtherCatVisible(true);
      } else {
        setisOtherCatVisible(false);
        setSubCategoryList(selectedCategoryItem.sub_category);
      }
    }
  }, [selectedCategory]);

  const onSubmit = async () => {
    let requestData = {
        country: businessCountry,
        province: businessFormik.values.province,
        address: businessFormik.values.address,
        city: businessFormik.values.city,
        postal_code: businessFormik.values.postal_code,
        name: businessFormik.values.business_name,
        no_of_store: businessFormik.values.no_of_stores,
        other_category: "",
    };
    console.log(requestData);
    if (isOtherCatVisible) {
      requestData.other_category =
        businessFormik.values.business_subcategory;
    } else {
      requestData.sub_category =
        businessFormik.values.business_subcategory;
    }
    setloading(true);
    try {
      // let token = localStorage.getItem("token");
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`,
      //   },
      // };
      const res = await merchantApi.post(`${API.ADD_MERCHANT_API}`, requestData);
      console.log(res);
      localStorage.setItem("user-info", JSON.stringify(res.data));
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');

      history.push("/signupthanks", { id: userId });
      userFormik.resetForm();
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: "Signup is successfull",
      });
    } catch (err) {
      console.log('erererer', err)
      const error = err.response.data.message;
      if (typeof error === "object") {
        err.response.data.message.forEach((message) => toast.error(message));
      } else {
        toast.error(error);
      }
    }
    setloading(false);
  };

  const togglePage = () => {
    setCurrentSection((prevState) =>
      prevState === "user" ? "business" : "user"
    );
  };

  const userSubmit = async() => {
    setloading(true);
   const requestData = {
    user:{
      first_name: userFormik.values.first_name,
      last_name: userFormik.values.last_name,
      email: userFormik.values.email,
      pep: userFormik.values.pep,
      dob: format(userFormik.values.dob, "yyyy-MM-dd"),
      phone_number: removeCodeFromPhone(userFormik.values.phone_number, phoneCountry.dialCode),
      dial_code: phoneCountry.dialCode,
      country_code: phoneCountry.countryCode,
    }
  }
  try {
    const storedUserId = localStorage.getItem("signupUserId")
    const storedToken = localStorage.getItem("token");
    if(storedToken && storedUserId){
      const res = await merchantApi.patch(`${API.USER}${storedUserId}/`,requestData.user);
    } else {
      const res = await axios.post(`${API.SIGNUP_API}`, requestData);
      const token = res.data.data.token
      setUserId(res.data.data.data.id)
      localStorage.setItem("signupUserId", res.data.data.data.id)
      localStorage.setItem("token", token?.access);
      localStorage.setItem("refresh", token?.refresh);
    }
    setloading(false)
    togglePage();
  } catch (err) {
    if( typeof err.response.data.message === 'object'){
      err.response.data.message.forEach((message) => toast.error(message));
    } else {
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
      setloading(false);
  }
  }

  const userFormik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      pep: false,
      dob: null,
      phone_number: "",
    
    },
    isInitialValid: false,
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: userSubmit,
    validationSchema: userValidationSchema,
  });

  const businessFormik = useFormik({
    initialValues: {
      country: "ca",
      province: "",
      address: "",
      city: "",
      postal_code: "",
      occupation_type: "",
      business_name: "",
      no_of_stores: 1,
      business_category: "",
      business_subcategory: "",
      timezone: { value: "US/Central" },
      acceptTerms: false,

    },
    isInitialValid: false,
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: onSubmit,
    validationSchema: businessValidationSchema,
  });

  const getTimezones = async () => {
    try {
      const response = await axios.get(API.TIMEZONES);
      console.log(response);
      console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const isTimezoneExistInList = response.data.data.find(
        (timezone) => timezone.value === browserTimezone
      );

      if (isTimezoneExistInList) {
        businessFormik.setFieldValue("timezone", {
          value: browserTimezone,
        });
      } else if (browserTimezone === "Asia/Calcutta") {
        businessFormik.setFieldValue("timezone", {
          value: "Asia/Kolkata",
        });
      }
      setTimezoneOptions(response.data.data);
    } catch (err) {
      toast.error(err?.data?.data?.message || "Something went wrong");
      console.log(err);
    }
  };

  useEffect(() => {
    getTimezones();
    const showPosition = async (position) => {
      console.log(position);
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      const params = {
        latitude,
        longitude,
        localityLanguage: "en",
      };
      try {
        const response = await axios.get(process.env.REACT_APP_LOCATION_URL, {
          params,
        });
        console.log(response);
        const data = response?.data;
        const countryCode = data?.countryCode ?? "ca";
        const province = data?.localityInfo.administrative[1];
        setIpInfo({
          country: countryCode,
          province,
          city: data?.localityInfo.administrative[3],
        });
        console.log({ data });

        const geoLocationBaseAddress = data.localityInfo.administrative
          .reverse()
          .reduce((prev, curr) => {
            console.log(prev, curr);
            if (!prev || !curr.name) {
              return curr.name;
            }
            return `${prev}, ${curr.name}`;
          }, "");

        businessFormik.setFieldValue("address", geoLocationBaseAddress);

        let country = _.find(countryList, {
          alpha2code: countryCode,
        });

        businessFormik.setFieldValue("phone_number", countryCode.toLowerCase());

        console.log(province);

        const selectedCountryProvinces = country.province;
        setProvinces(selectedCountryProvinces);
        if (province) {
          const geoLocationProvince = _.find(provinces, {
            geoname_id: ipInfo?.province?.geonameId,
          });
          console.log(geoLocationProvince);
          businessFormik.setFieldValue("province", geoLocationProvince?.id);
          setIpInfo((prev) => ({ ...prev, province: null }));
          // setIpInfo(prev => ({...prev,localityInfo:{administrative[1]: null} }))
        }

        setBusinessCountry(country?.id);
        businessFormik.setFieldValue("country", countryCode);
      } catch (error) {
        console.log(error);
        toast.error(error?.data?.data?.message || "Something went wrong");
      }
    };
    if (navigator.geolocation) {
      const permissionDenied = (a) => console.log(a);
      navigator.geolocation.getCurrentPosition(showPosition, permissionDenied);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  console.log(userFormik)

  return (
    <section className="bg-white">
      <Container fluid className="h-100 stb-w-100 pl-0">
        <div sm={2} className="signup">
          <section className="stb-info d-flex justify-content-center align-items-center position-relative bg-primary">
            <img className="logo" src={stblogo} alt="logo" />
            <img src={signupbg} alt="logo" />
            <div className="socials">
            <a
              className="social twitter"
              href="https://twitter.com/Spend_The_Bits"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Spend_The_Bits_Cash_Merchant_Portal
            </a>
            <a
              className="social mail"
              href="mailto:help@spendthebits.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={mail} alt="mail" />
              help@spendthebits.com
            </a>
            </div>
          </section>
          <section className="d-flex justify-content-center align-items-center ">
            <div className="bg-white signup-form">
              <Row className="w-75 mt-4">
                <div className="status mb-4">
                  <div className="part">
                    <div className="circle filled">
                      {currentSection === "user" ? (
                        1
                      ) : (
                        <img src={tick} alt="tick" />
                      )}
                    </div>
                    <span className="title">Your Account</span>
                  </div>
                  <div
                    className={`line ${
                      currentSection !== "user" ? "solid" : ""
                    }`}
                  ></div>
                  <div className="part">
                    <div
                      className={`circle  ${
                        currentSection === "user" ? "" : "filled"
                      }`}
                    >
                      2
                    </div>
                    <span className="title">Fill Details</span>
                  </div>
                </div>
                <Col
                  xl={6}
                  xs={12}
                  className="w-100 d-flex align-items-center justify-content-center p-0"
                >
                  <div className="mb-3 mb-lg-0 w-100 ">
                    {/* onSubmit={userFormik.handleSubmit} */}

                    {/* USER SECTION */}
                    {currentSection === "user" ? (
                      <>
                        <div className="text-left mb-2 mt-4 mt-md-0">
                          <h3 className="mb-0 font-fam text-primary">
                            Signup{" "}
                          </h3>
                          <p className="mt-2 font-fam font-16">
                            Please Fill Your Personal Details For Signup
                          </p>
                        </div>
                        <form onSubmit={userFormik.handleSubmit}>
                          <Row>
                            <Col xl={6} sm={6} xs={12}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                className="mt-4 bg-white"
                                id="first_name"
                                name="first_name"
                                InputLabelProps={textFieldLabelProps}
                                label="First Name"
                                value={userFormik.values.first_name}
                                onChange={userFormik.handleChange}
                                onBlur={userFormik.handleBlur}
                                error={
                                  userFormik.touched.first_name &&
                                  Boolean(userFormik.errors.first_name)
                                }
                                helperText={
                                  userFormik.touched.first_name &&
                                  userFormik.errors.first_name
                                }
                              />
                            </Col>
                            <Col xl={6} sm={6} xs={12}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                className="mt-4 bg-white"
                                id="last_name"
                                name="last_name"
                                InputLabelProps={textFieldLabelProps}
                                label="Last Name"
                                value={userFormik.values.last_name}
                                onChange={userFormik.handleChange}
                                onBlur={userFormik.handleBlur}
                                error={
                                  userFormik.touched.last_name &&
                                  Boolean(userFormik.errors.last_name)
                                }
                                helperText={
                                  userFormik.touched.last_name &&
                                  userFormik.errors.last_name
                                }
                              />
                            </Col>
                            <Col xl={6} sm={6} xs={12}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                className="mt-4 bg-white"
                                id="email"
                                name="email"
                                InputLabelProps={textFieldLabelProps}
                                label="Email"
                                value={userFormik.values.email}
                                onChange={userFormik.handleChange}
                                onBlur={userFormik.handleBlur}
                                error={
                                  userFormik.touched.email &&
                                  Boolean(userFormik.errors.email)
                                }
                                helperText={
                                  userFormik.touched.email &&
                                  userFormik.errors.email
                                }
                              />
                            </Col>{" "}
                            <Col xl={6} sm={6} xs={12}>
                              <TextField
                                select
                                fullWidth
                                variant="outlined"
                                className="mt-4 bg-white"
                                id="pep"
                                name="pep"
                                InputLabelProps={textFieldLabelProps}
                                label="Politically Exposed Person (PEP)"
                                value={userFormik.values.pep}
                                onChange={userFormik.handleChange}
                                onBlur={userFormik.handleBlur}
                                error={
                                  userFormik.touched.pep &&
                                  Boolean(userFormik.errors.pep)
                                }
                                helperText={
                                  userFormik.touched.pep &&
                                  userFormik.errors.pep
                                }
                              >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                              </TextField>
                            </Col>
                            <Col xl={6} sm={6} xs={12}>
                              <DatePicker
                                fullWidth
                                disableFuture
                                format={"dd/MM/yyyy"}
                                className="mt-4 bg-white"
                                id={"dob"}
                                name="dob"
                                value={userFormik.values.dob}
                                onChange={(date) => {
                                  // in date pickers it has to be manually set
                                  userFormik.setFieldTouched("dob", true);
                                  userFormik.setFieldValue("dob", new Date(date))}}
                                slots={{
                                  textField: TextField
                                }}
                                slotProps={{ textField: { variant: 'outlined', fullWidth: true, error: userFormik.touched.dob && Boolean(userFormik.errors.dob), helperText: userFormik.touched.dob && userFormik.errors.dob } }}
                              />
                            </Col>
                            <Col xl={6} sm={6} xs={12}>
                              <PhoneNumberInput
                                formik={userFormik}
                                label={"Phone Number"}
                                name={"phone_number"}
                                setPhoneCountry={setPhoneCountry}
                                style={{ marginTop: "24px" }}
                                onlyCountries={
                                  countryList.length > 0
                                    ? _.map(countryList, (country) => {
                                        return country?.alpha2code?.toLowerCase();
                                      })
                                    : []
                                }
                                country={phoneCountry?.countryCode}
                              />
                             
                            </Col>
                          </Row>

                          {error && (
                            <div className=" mb-2  text-danger">
                              <span className="fw-normal">{error}</span>
                            </div>
                          )}

                          <Button
                            variant="primary"
                            disabled={!userFormik.isValid || loading}
                            type={"submit"}
                            className="w-100 font-fam font-100 mt-4"
                          >
                            {loading && <CircularProgress size={14} />}
                            {!loading && "Submit and Continue"}
                          </Button>

                          <ToastContainer />
                        </form>
                      </>
                    ) : (
                      // BUSINESS SECTION
                      <>
                        <form onSubmit={businessFormik.handleSubmit}>
                          <span onClick={togglePage} className="back">
                            <img src={back} alt="back" />
                            <span>Back</span>
                          </span>
                          <Row>
                            <Col xl={6} sm={6} xs={12}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                className="mt-4 bg-white"
                                id="business_name"
                                name="business_name"
                                InputLabelProps={textFieldLabelProps}
                                label="Business Name"
                                value={businessFormik.values.business_name}
                                onChange={businessFormik.handleChange}
                                onBlur={businessFormik.handleBlur}
                                error={
                                  businessFormik.touched.business_name &&
                                  Boolean(businessFormik.errors.business_name)
                                }
                                helperText={
                                  businessFormik.touched.business_name &&
                                  businessFormik.errors.business_name
                                }
                              />
                            </Col>
                            <Col xl={6} sm={6} xs={12}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                className="mt-4 bg-white"
                                id="no_of_stores"
                                name="no_of_stores"
                                InputLabelProps={textFieldLabelProps}
                                type="number"
                                label="Number of stores"
                                value={businessFormik.values.no_of_stores}
                                onChange={businessFormik.handleChange}
                                onBlur={businessFormik.handleBlur}
                                error={
                                  businessFormik.touched.no_of_stores &&
                                  Boolean(businessFormik.errors.no_of_stores)
                                }
                                helperText={
                                  businessFormik.touched.no_of_stores &&
                                  businessFormik.errors.no_of_stores
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={6} sm={6} xs={12}>
                              <TextField
                                select
                                fullWidth
                                variant="outlined"
                                className="mt-4 bg-white"
                                id="business_category"
                                name="business_category"
                                InputLabelProps={textFieldLabelProps}
                                label="Business Category"
                                value={businessFormik.values.business_category}
                                onChange={(event) => {
                                  setSelectedCategory(event.target);
                                  businessFormik.setFieldValue(
                                    "business_subcategory",
                                    ""
                                  );
                                  businessFormik.handleChange(event);
                                }}
                                onBlur={(event) => {
                                  businessFormik.handleChange(event);
                                }}
                                error={
                                  businessFormik.touched.business_category &&
                                  Boolean(
                                    businessFormik.errors.business_category
                                  )
                                }
                                helperText={
                                  businessFormik.touched.business_category &&
                                  businessFormik.errors.business_category
                                }
                              >
                                {categoryList.map((category) => (
                                  <MenuItem value={category.id}>
                                    {category.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Col>
                            {isOtherCatVisible ? (
                              <Col xl={6} sm={6} xs={12}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  className="mt-4 bg-white"
                                  id="business_subcategory"
                                  name="business_subcategory"
                                  InputLabelProps={textFieldLabelProps}
                                  label="Other Category Name"
                                  value={
                                    businessFormik.values.business_subcategory
                                  }
                                  onChange={businessFormik.handleChange}
                                  onBlur={businessFormik.handleBlur}
                                  error={
                                    businessFormik.touched
                                      .business_subcategory &&
                                    Boolean(
                                      businessFormik.errors.business_subcategory
                                    )
                                  }
                                  helperText={
                                    businessFormik.touched
                                      .business_subcategory &&
                                    businessFormik.errors.business_subcategory
                                  }
                                />
                              </Col>
                            ) : (
                              <Col xl={6} sm={6} xs={12}>
                                <TextField
                                  select
                                  fullWidth
                                  variant="outlined"
                                  className="mt-4 bg-white"
                                  id="business_subcategory"
                                  name="business_subcategory"
                                  InputLabelProps={textFieldLabelProps}
                                  label="Business Subcategory"
                                  value={
                                    businessFormik.values.business_subcategory
                                  }
                                  onChange={businessFormik.handleChange}
                                  onBlur={businessFormik.handleBlur}
                                  error={
                                    businessFormik.touched
                                      .business_subcategory &&
                                    Boolean(
                                      businessFormik.errors.business_subcategory
                                    )
                                  }
                                  helperText={
                                    businessFormik.touched
                                      .business_subcategory &&
                                    businessFormik.errors.business_subcategory
                                  }
                                >
                                  {subCategoryList.map((subCategory) => (
                                    <MenuItem value={subCategory.id}>
                                      {subCategory.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <Col xl={6} sm={6} xs={12}>
                              <TextField
                                select
                                fullWidth
                                variant="outlined"
                                className="mt-4 bg-white"
                                id="occupation_type"
                                name="occupation_type"
                                InputLabelProps={textFieldLabelProps}
                                label="Occupation Type"
                                value={businessFormik.values.occupation_type}
                                onChange={(event) => {
                                  // console.log(event);

                                  businessFormik.handleChange(event);
                                }}
                                onBlur={(event) => {
                                  businessFormik.handleChange(event);
                                }}
                                error={
                                  businessFormik.touched.occupation_type &&
                                  Boolean(businessFormik.errors.occupation_type)
                                }
                                helperText={
                                  businessFormik.touched.occupation_type &&
                                  businessFormik.errors.occupation_type
                                }
                              >
                                {occupationList.map((occupation) => (
                                  <MenuItem value={occupation.value}>
                                    {occupation.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Col>
                            <Col xl={6} sm={6} xs={12}>
                              <ReactFlagsSelect
                                countries={countryCodes}
                                className="mt-4 bg-white material-inp"
                                style={{ lineHeight: "2rem" }}
                                selected={businessFormik.values.country}
                                onSelect={(code) => {
                                  let countryId = _.find(countryList, {
                                    alpha2code: code,
                                  }).id;

                                  setBusinessCountry(countryId);

                                  let e = {
                                    target: {
                                      name: "country",
                                      value: code,
                                    },
                                  };
                                  businessFormik.handleChange(e);
                                  const selectedCountryProvinces =
                                    countryList.filter(
                                      (country) => country.alpha2code === code
                                    )[0].province;
                                  setProvinces(selectedCountryProvinces);
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={6} sm={6} xs={12}>
                              <TextField
                                select
                                fullWidth
                                variant="outlined"
                                className="mt-4 bg-white"
                                id="province"
                                name="province"
                                InputLabelProps={textFieldLabelProps}
                                label="Province"
                                value={businessFormik.values.province}
                                onChange={(event) => {
                                  // console.log(event);

                                  businessFormik.handleChange(event);
                                }}
                                onBlur={(event) => {
                                  businessFormik.handleChange(event);
                                }}
                                error={
                                  businessFormik.touched.province &&
                                  Boolean(businessFormik.errors.province)
                                }
                                helperText={
                                  businessFormik.touched.province &&
                                  businessFormik.errors.province
                                }
                              >
                                {provinces.map((province) => (
                                  <MenuItem value={province.id}>
                                    {province.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Col>
                            <Col xl={6} sm={6} xs={12}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                className="mt-4 bg-white"
                                id="city"
                                name="city"
                                InputLabelProps={textFieldLabelProps}
                                label="City"
                                value={businessFormik.values.city}
                                onChange={businessFormik.handleChange}
                                onBlur={businessFormik.handleBlur}
                                error={
                                  businessFormik.touched.city &&
                                  Boolean(businessFormik.errors.city)
                                }
                                helperText={
                                  businessFormik.touched.city &&
                                  businessFormik.errors.city
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={6} sm={6} xs={12}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                className="mt-4 bg-white"
                                id="postal_code"
                                name="postal_code"
                                InputLabelProps={textFieldLabelProps}
                                label="Postal Code"
                                value={businessFormik.values.postal_code}
                                onChange={businessFormik.handleChange}
                                onBlur={businessFormik.handleBlur}
                                error={
                                  businessFormik.touched.postal_code &&
                                  Boolean(businessFormik.errors.postal_code)
                                }
                                helperText={
                                  businessFormik.touched.postal_code &&
                                  businessFormik.errors.postal_code
                                }
                              />
                            </Col>
                            <Col xl={6} sm={6} xs={12}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                className="mt-4 bg-white"
                                id="address"
                                name="address"
                                InputLabelProps={textFieldLabelProps}
                                label="Business Address"
                                value={businessFormik.values.address}
                                onChange={businessFormik.handleChange}
                                onBlur={businessFormik.handleBlur}
                                error={
                                  businessFormik.touched.address &&
                                  Boolean(businessFormik.errors.address)
                                }
                                helperText={
                                  businessFormik.touched.address &&
                                  businessFormik.errors.address
                                }
                              />
                            </Col>
                          </Row>

                          <Autocomplete
                            id="timezone"
                            value={businessFormik.values.timezone}
                            onChange={(e, newValue) => {
                              console.log(e, newValue);
                              businessFormik.setFieldValue(
                                "timezone",
                                newValue
                              );
                              // businessFormik.handleChange(e);
                            }}
                            options={timezoneOptions}
                            getOptionLabel={(option) => option?.value ?? ""}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            sx={{ flexGrow: 1, width: "100%", my: 3 }}
                            autoSelect={true}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder="Select Timezone"
                                  {...params}
                                  // size="small"
                                  variant="outlined"
                                  name="timezone"
                                  onBlur={businessFormik?.handleBlur}
                                  error={
                                    businessFormik?.touched?.timezone &&
                                    Boolean(businessFormik?.errors?.timezone)
                                  }
                                  helperText={
                                    businessFormik?.touched?.timezone &&
                                    businessFormik?.errors?.timezone
                                  }
                                />
                              );
                            }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={businessFormik.values.acceptTerms}
                                onChange={(e) => {
                                  businessFormik.setFieldValue(
                                    "acceptTerms",
                                    e.target.checked
                                  );
                                }}
                                sx={{
                                  color: Colors.primary,
                                  "&.Mui-checked": {
                                    color: Colors.primary,
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography variant="body2">
                                I agree to the{" "}
                                <Link
                                  href={API.TERMS_CONDITION}
                                  target="_blank"
                                  rel="noopener"
                                  sx={{
                                    fontWeight: 600,
                                    color: Colors.primary,
                                    textDecoration: "none",
                                  }}
                                >
                                  Terms of Use
                                </Link>{" "}
                                and{" "}
                                <Link
                                  href={API.PRIVACY_POLICY}
                                  target="_blank"
                                  rel="noopener"
                                  sx={{
                                    fontWeight: 600,
                                    color: Colors.primary,
                                    textDecoration: "none",
                                  }}
                                >
                                  Privacy Policy
                                </Link>
                              </Typography>
                            }
                          />
                          {error && (
                            <div className=" mb-2  text-danger">
                              <span className="fw-normal">{error}</span>
                            </div>
                          )}

                          <Button
                            variant="primary"
                            disabled={!businessFormik.isValid || loading}
                            type={"submit"}
                            className="w-100 font-fam font-100 mt-2"
                          >
                            {loading && <CircularProgress size={14} />}
                            {!loading && "Sign Up"}
                          </Button>
                        </form>
                      </>
                    )}
                    <div className="text-center mt-4">
                      <span className="fw-normal">
                        Already have an account?
                        <Card.Link
                          onClick={() => changepage("signin")}
                          className="fw-bold"
                        >
                          <span className="text-primary">{` Login`}</span>
                        </Card.Link>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </Container>
    </section>
  );
};
