import React from "react";
import { Link } from "react-router-dom";
import firstimg from "../assets/img/formplay.svg";
// import {img} from "../assets/images/firstimg.png";
const GoforBusinessUpdate = () => {
  return (
    <div>
      <section className="bg-white vh-lg-100 ">
        <div className="container" style={{height:"100vh"}}>
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center mt-4">
              <img style={{ width: "390px" }} src={firstimg} alt="" />
            </div>
            <div className="col-12" style={{ textAlign: "center" }}>
              <h3 className="mt-2 font-fam">
                Please update your business information
              </h3>
              <p className="mt-3 font-fam">
             You must fill in business details before unlocking this page.
            </p>

              <Link
                to="/administration/updatebusinessdetails"
                style={{ fontSize: "18px" }}
                class="btn btn-primary mt-4"
              >
                Business Details
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GoforBusinessUpdate;
