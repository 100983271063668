import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { connect, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Form, InputGroup, Col, Button } from "@themesberg/react-bootstrap";
import { DateRangePicker } from "rsuite";
import { toast } from "react-toastify";
import "rsuite/dist/styles/rsuite-default.css";
import * as API from "../../ApiUrl";
import Transactiontable from "./ReportTransactions/Transactiontable";
import { merchantApi } from "../../redux/api/apiConfig";
import RefundDialog from "./ReportTransactions/components/RefundData/RefundDialog";
import RefundSummaryDialog from "./ReportTransactions/components/RefundData/RefundSummaryDialog"
import WithdrawOtpDialog from "../merchantprofile/Components/WithdrawOtpDialog";
import { useDispatch } from "react-redux";

import sync from "../../assets/img/icons/sync.svg";
import reload from "../../assets/img/icons/reload.svg";
import searchIcon from "../../assets/img/icons/searchIcon.svg";
import dropDown from "../../assets/img/icons/dropDown.svg";
import {
  format,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from "date-fns";

import { ADMIN, formatCurrency } from "../../utils/common";
import { getTransactionHighlights } from "../../redux/actions/transactionActions";
import Colors from "../../assets/colors";
import { updateXRefreshToken } from "../../redux/actions/profileActions";

const reportTimePeriods = [
  {
    name: "Time Period",
    from: "",
    to: "",
  },
  {
    name: "This Week",
    from: format(startOfWeek(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  {
    name: "This Month",
    from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  {
    name: "This Quarter",
    from: format(startOfQuarter(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  {
    name: "This Year",
    from: format(startOfYear(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
];


function ReportTransactions({ userRole }) {
  const [statusOptions, setStatusOptions] = React.useState([
    { name: "Select Status", value: "" },
  ]);
  const [typeOption, setTypeOption] = React.useState([
    { name: "All", value: "" },
  ]);
  const [storeOption, setStoreOption] = React.useState([
    { name: "Select Store", id: "" },
  ]);

  const [counterOption, setCounterOption] = React.useState([
    { name: "Select Counter", id: "" },
  ]);
  const [statusTitle, setStatusTitle] = React.useState("Select Status");

  const [translist, setTranslist] = useState([]);
  const [modal, setmodal] = useState(false);
  const [loading, setloading] = useState(false);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(true);
  const [storeTitle, setStoreTitle] = useState("Select Store");
  const [counterTitle, setCounterTitle] = useState("Select Counter");
  const [transactionData, setTransactionData] = useState("");
  const [resetData, setResetData] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [userCurrency, setUserCurrency] = useState({
    symbol: "",
    decimalPlace: 2,
    symbolPrefix: false,
  });
  const [tabValue, setTabValue] = useState("");
  const [refresh, setRefresh] = useState(1);
  const [calenderValue, setCalenderValue] = useState([]);
  const [reLoading, setReLoading] = useState({
    transactionGraph: false,
    balance: false,
    revenue: false,
    balanceGraph: false,
  });
  const [showRefundSummary, setShowRefundSummary] = useState(false)
  const [refundRes, setRefundRes] = useState(null)

  const [highlights, setHighlights] = useState(null)

  const [reportTimePeriodTitle, setReportTimePeriodTitle] =
    useState("Time Period");

  console.log(reLoading);

  const dispatch = useDispatch()

  const xRefreshToken = useSelector(state => state.profileReducer?.xRefreshToken)

  const additionalHeader = {
    "X-REFRESH": String(xRefreshToken),
  }
  const handleTabChange = (e, value) => {
    setTabValue(value);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      type: value,
    });
  };

  const handleClearFilters = () => {
    setStatusTitle("Status");
    setStoreTitle("Store");
    setCounterTitle("Select Counter");
    setpaginateTable({
      currentPage: 0,
      pageSize: 7,
      search: "",
      type: "",
      store: "",
      status: "",
      fromDate: "",
      toDate: "",
      counter: "",
    });
    setCalenderValue([]);
    setReportTimePeriodTitle("Time Period");
  };

  const handleCallback = (childData) => {
    setTransactionData(childData);
    setmodal(true);
  };

  const handleSendRefund = async (data) => {
    setRefundRes(data)
    setShowRefundSummary(true);
    getTrans();
  };




  const [paginateTable, setpaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: "",
    type: "",
    store: "",
    status: "",
    fromDate: "",
    toDate: "",
    counter: "",
  });

  const getTrans = async () => {
    setFetchTranslistLoading(true);
    merchantApi
      .get(
        `${API.TRANSACTION_LIST}`,
        {
          params: {
            offset: paginateTable.currentPage * paginateTable.pageSize,
            limit: paginateTable.pageSize,
            search: paginateTable.search,
            type: paginateTable.type,
            store: paginateTable.store,
            status: paginateTable.status,
            modified_date_after: paginateTable.fromDate,
            modified_date_before: paginateTable.toDate,
            counter: paginateTable.counter,
          },
        }
      )
      .then((response) => {
        setFetchTranslistLoading(false);
        if (response.data) {
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message || "Something went wrong");
        setFetchTranslistLoading(false);
      });
  };

  const getTransType = async () => {
    try {
      const response = await merchantApi.get(`${API.TRANSACTION_TYPE}`);
      setTypeOption([...typeOption, ...response.data.data]);
    } catch (error) {}
  };


  const getCounterType = async () => {
    try {
      const response = await merchantApi.get(`${API.COUNTER}`,);
      setCounterOption([...counterOption, ...response.data.results]);
    } catch (error) {}
  };

  const getStoreList = async () => {
    try {
      const response = await merchantApi.get(`${API.TRANS_STORE}`, );
      setStoreOption([...storeOption, ...response.data.data]);
    } catch (error) {}
  };

  const getStatusOption = async () => {
    try {
      const response = await merchantApi.get(`${API.TRANS_STATUS}`, );
      setStatusOptions([...statusOptions, ...response.data.data]);
    } catch (error) {}
  };

  const getBalance = (coinId = null) => {
    setloading(true);
    setReLoading((prevState) => ({ ...prevState, balance: true }));

    let apiUrl = `${API.GET_BALANCE}`;
    merchantApi
      .get(apiUrl, {
        headers: {
          ...merchantApi.defaults.headers,
          ...additionalHeader
        },
      })
      .then((response) => {
        if (response && response.data) {
          if (response.data?.data) {
            setUserBalance(response.data.data.total_balance);
            setUserCurrency({
              symbol: response.data.data.currency.symbol,
              decimalPlace: response.data.data.currency.decimal_place,
              symbolPrefix: response.data.data.currency.symbol_prefix,
            });
          } else {
            toast.error(response.data.message);
          }
        }
        setReLoading((prevState) => ({ ...prevState, balance: false }));
        setloading(false);
      })
      .catch((error) => {
        setReLoading((prevState) => ({ ...prevState, balance: false }));
        toast.error(
          "There was an error getting your balance information. Please try again!"
        );
      });
  };



  useEffect(() => {
    getBalance();
    getTransType();
    getCounterType();
    getStoreList();
    getStatusOption();
  }, []);

  useEffect(() => {
    getBalance();
    dispatch(getTransactionHighlights((res) => {
      setHighlights(res.data)
    },(err) => {
    }))
  }, [refresh]);

  useEffect(() => {
    getTrans();
  }, [
    paginateTable.currentPage,
    paginateTable.pageSize,
    paginateTable.search,
    paginateTable.type,
    paginateTable.store,
    paginateTable.status,
    paginateTable.fromDate,
    paginateTable.toDate,
    paginateTable?.counter,
    resetData,
  ]);


  const handleStorechange = (e) => {
    let l = storeOption.filter((a) => a.id === e);
    setStoreTitle(l[0].name);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      store: l[0].id,
    });
  };

  const handleCounterChange = (e) => {
    let l = counterOption.filter((a) => a.id === e);
    setCounterTitle(l[0].name);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      counter: l[0].id,
    });
  };

  const handleStatusChange = (value) => {
    let l = statusOptions.filter((a) => a.value === value);

    setStatusTitle(l[0].name);
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      status: value,
    });
  };



  const handleSearch = (value) => {
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      search: value,
    });
  };

  const reportTimePeriodChangeHandler = (from, to, name) => {
    setCalenderValue(
      name === "Time Period" ? [] : [new Date(from), new Date(to)]
    );
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      fromDate: from,
      toDate: to,
    });
    setReportTimePeriodTitle(name);
  };

  const handleDateFilter = (value) => {
    if (value.length > 1) {
      console.log(value);
      setpaginateTable({
        ...paginateTable,
        currentPage: 0,
        fromDate: format(value[0], "yyyy-MM-dd"),
        toDate: format(value[1], "yyyy-MM-dd"),
      });
    } else {
      setpaginateTable({
        ...paginateTable,
        currentPage: 0,
        fromDate: "",
        toDate: "",
      });
    }
  };


  const updateXRefreshTokenClicked = () => {
    dispatch(updateXRefreshToken());
  }



  return (
    <>
      {/* {loading && <Loader backdrop content=" " vertical />} */}
      <div className="page-wrapper stb-page-wrapper stb-ph-8">
        <div className="stb-h-100">
          <div className="reports stb-h-100 bg-white p-xl-4 radi">
            <div className="d-flex flex-col align-items-start gap-4">
              <div className="d-flex space-between w-100">
                <div>
                  <h3 className="m-0 page-title">
                    Overview
                    <span
                      className="sync"
                      onClick={() => {
                        updateXRefreshTokenClicked();
                        setRefresh((prevState) => prevState + 1);
                      }}
                    >
                      {" "}
                      <img
                        className={`${
                          reLoading.balance ||
                          reLoading.balanceGraph ||
                          reLoading.revenue ||
                          reLoading.transactionGraph
                            ? "reload"
                            : null
                        }`}
                        src={sync}
                        alt="sync"
                      />
                    </span>
                  </h3>
                </div>
              </div>
              <div className="balance-cards d-flex align-items-center space-between w-100 gap-4">
                <div className="trans-card light-green">
                  <div className="card-content">
                    <span>
                    {formatCurrency(
                        highlights?.yesterday_receipt || 0,
                        userCurrency.decimalPlace,
                        userCurrency.symbol,
                        userCurrency.symbolPrefix
                      )}
                    </span>
                    <p>Yesterday's Receipt</p>
                  </div>
                </div>
                <div className="trans-card light-yellow">
                  <div className="card-content">
                    <span>
                    {formatCurrency(
                        highlights?.today_receipt || 0,
                        userCurrency.decimalPlace,
                        userCurrency.symbol,
                        userCurrency.symbolPrefix
                      )}
                    </span>
                    <p>Today's Receipt</p>
                  </div>
                </div>
                <div className="trans-card light-blue">
                  <div className="card-content">
                    <span>
                      {formatCurrency(
                        userBalance,
                        userCurrency.decimalPlace,
                        userCurrency.symbol,
                        userCurrency.symbolPrefix
                      )}
                    </span>
                    <p>Total Balance</p>
                  </div>
                </div>
              </div>
              <div className="w-100">
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: Colors.primary,
                    },
                  }}
                  value={tabValue}
                  onChange={handleTabChange}
                >
                  <Tab
                    value=""
                    style={{ color: `${tabValue === "" ? Colors.primary : ""}` }}
                    label="All"
                  />
                  <Tab
                    value="receipt"
                    style={{
                      color: `${tabValue === "receipt" ? Colors.primary : ""}`,
                    }}
                    label="Receipt"
                  />
                 {userRole === ADMIN && <Tab
                    value="withdraw"
                    style={{
                      color: `${tabValue === "withdraw" ? Colors.primary : ""}`,
                    }}
                    label="Withdraw"
                  />}
                  <Tab
                    value="refund"
                    style={{
                      color: `${tabValue === "refund" ? Colors.primary : ""}`,
                    }}
                    label="Refund"
                  />
                </Tabs>
              </div>
              <div className="w-100 d-flex align-items-start space-between gap-2 p-3 mb-5 filters">
                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {reportTimePeriodTitle || "Select Period"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {reportTimePeriods.map((period, i) => (
                        <Dropdown.Item
                          as="button"
                          value={period.name}
                          onClick={() => {
                            reportTimePeriodChangeHandler(
                              period.from,
                              period.to,
                              period.name
                            );
                          }}
                          key={i + "res"}
                        >
                          {period.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="filter">
                  <DateRangePicker
                    cleanable
                    placeholder="Filter Date"
                    className="w-100 dateInput"
                    size="lg"
                    style={{ height: "40px" }}
                    onOk={handleDateFilter}
                    value={calenderValue}
                    placement="auto"
                    showOneCalendar={true}
                    ranges={[
                      {
                        label: "clear",
                        value: [1, 1],
                      },
                    ]}
                    onChange={(value) => {
                      if (value[0] === 1 && value[1] === 1) {
                        setCalenderValue([]);
                      } else {
                        setCalenderValue(value);
                      }
                    }}
                    onClean={() => {
                      reportTimePeriodChangeHandler(
                        reportTimePeriods[0].from,
                        reportTimePeriods[0].to,
                        reportTimePeriods[0].name
                      );
                    }}
                  />
                </div>

                <div className="filter">
                  <Form
                    onSubmit={(e) => e.preventDefault()}
                    className="w-100 search"
                  >
                    <Form.Group>
                      <InputGroup>
                        <InputGroup.Text>
                          <img src={searchIcon} alt="search" />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                          type="text"
                          placeholder="Search"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form>
                </div>

                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {statusTitle || "Select Status"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                        className="dropdown-icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {statusOptions.map((a, i) => (
                        <Dropdown.Item
                          as="button"
                          value={a.value}
                          onClick={() => {
                            handleStatusChange(a.value);
                          }}
                          key={i + "res"}
                        >
                          {a.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                </div>

                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {storeTitle || "Select Store"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                        className="dropdown-icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {storeOption.map((a, i) => (
                        <Dropdown.Item
                          as="button"
                          value={a.id}
                          onClick={() => {
                            handleStorechange(a.id);
                          }}
                          key={i + "res"}
                        >
                          {a.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                </div>
                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {counterTitle || "Select Counter"}
                      <img
                        src={dropDown}
                        style={{ marginLeft: "4px" }}
                        alt="drop-down"
                        className="dropdown-icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      {counterOption.map((a, i) => (
                        <Dropdown.Item
                          as="button"
                          value={a.id}
                          onClick={() => {
                            handleCounterChange(a.id);
                          }}
                          key={i + "res"}
                        >
                          {a.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                </div>
                <div className="filter" style={{ flexGrow: "0" }}>
                  <Button
                    className=" w-xs-100  text-dark w-100"
                    style={{
                      background: "none",
                      border: "none",
                      width: "100%",
                      boxShadow: "none",
                    }}
                    onClick={handleClearFilters}
                  >
                    Reset Filters
                    <img
                      src={reload}
                      style={{ marginLeft: "7px" }}
                      alt="reset filter"
                    />
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <Col xs={12} xl={12} className="mb-4">
                <Transactiontable
                  translist={translist}
                  fetchTranslistLoading={fetchTranslistLoading}
                  setpaginateTable={setpaginateTable}
                  paginateTable={paginateTable}
                  handleCallback={handleCallback}
                  userRole={userRole}
                  resetTransactions={setResetData}
                />
              </Col>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <RefundDialog
          transactionData={transactionData}
          loading={loading}
          open={modal}
          onClose={() => {
            setmodal(false);
          }}
          userCurrency={userCurrency}
          handleSendRefund={handleSendRefund}
          title={"Initiate Refund"}
          userRole={userRole}
        />
      )}
      {showRefundSummary && (
        <RefundSummaryDialog 
        open={showRefundSummary}
        refundRes={refundRes}
        onClose={() => {setShowRefundSummary(false)}}
        userCurrency={userCurrency}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ authReducer }) => {
  const { userRole } = authReducer;
  return { userRole };
};

export default connect(mapStateToProps, null)(ReportTransactions);
