import { DataArray } from '@mui/icons-material';
import * as API from '../../ApiUrl';
import { merchantApi } from '../api/apiConfig';



  const getTransactionHighlights = ( onSuccess, onError) => async dispatch => {
    merchantApi
      .get(API.TRANSACTION_HIGHLIGHT)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch(({error, responseData}) => {
        onError(responseData?.data || error);
      });
  };

  const createRefund = ( data, onSuccess, onError) => async dispatch => {
    merchantApi
      .post(API.GET_TRANSACTION,data)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const getWithdrawHighlights = ( onSuccess, onError) => async dispatch => {
    merchantApi
      .get(API.WITHDRAW_HIGHLIGHT)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch(({error, responseData}) => {
        onError(responseData?.data || error);
      });
  };

  const verifyWithdrawOtp = (id, data, onSuccess, onError) => async dispatch => {
    merchantApi
      .post(`${API.VERIFY_OTP}${id}/`,data)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const getReportHighlights = (params, signal, onSuccess, onError) => async dispatch => {
    merchantApi
      .get(API.REPORT_HIGHLIGHT,{
        params,
        signal
      })
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError(err);
      });
  };

export {getReportHighlights,getTransactionHighlights, createRefund, getWithdrawHighlights, verifyWithdrawOtp };
