import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import { useSelector } from 'react-redux';
import {Typography, Stack} from '@mui/material'

const PhoneNumberInput = ({
  formik,
  label,
  name,
  onlyCountries = [],
  setPhoneNumber = () => {},
  country = '',
  style = {},
  setPhoneCountry = () => {}
}) => {
  let userCountryCode = country;
  const storedUserCountryCode = useSelector((state) =>
    state?.profileReducer?.user?.merchant?.country?.alpha2code?.toLowerCase()
  );
  if (userCountryCode === '') {
    userCountryCode = storedUserCountryCode;
  }
  return (
    <Stack direction="column" sx={{width:'100%'}}>
    <PhoneInput
    autoFormat
      onlyCountries={onlyCountries}
      value={formik.values[`${name}`]}
      specialLabel={label}
      country={userCountryCode}
      placeholder=""
      inputStyle={{
        height: 56,
        borderRadius: 8,
        border: 'none',
        width: '100%',
        color: 'rgba(0,0,0,0.87)',
      }}
      buttonStyle={{
        height: 56,
        border: 'none',
        background: 'transparent',
      }}
      containerStyle={{ ...style }}
      containerClass="phone-container"
      enableSearch
      disableSearchIcon
      disableCountryGuess={true}
      countryCodeEditable={false}
      isValid={(value, country) => {
       
        return true;
      }}
      id={name}
      name={name}
      className="bg-white"
      onChange={(phone, data) => {
        console.log('dscscdsdc', data)
        formik.setFieldTouched(`${name}`, true);
        let e = {
          target: {
            name: name,
            value: phone,
          },
        };
        setPhoneCountry({
          countryCode: data?.countryCode,
          dialCode: data?.dialCode
        });
        setPhoneNumber(phone);
        formik.handleChange(e);
      }}
      error={formik.touched[`${name}`] && Boolean(formik.errors[`${name}`])}
      helperText={formik.touched[`${name}`] && formik.errors[`${name}`]}
    />
    {(formik.touched[`${name}`] && Boolean(formik.errors[`${name}`])) &&<Typography variant='caption' color={'error'}>
{formik.touched[`${name}`] && formik.errors[`${name}`]}
    </Typography>}
    </Stack>
  );
};

export default PhoneNumberInput;
