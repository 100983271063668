

import * as API from '../../ApiUrl';
import { SET_PROFILE, UPDATE_X_REFRESH_TOKEN } from '../actionTypes';
import { merchantApi } from '../api/apiConfig';
import { toast } from "react-toastify";

  const getProfileInfo = (onSuccess, onError) =>  async dispatch => {
    try {
      const response = await merchantApi.get(`${API.MERCHANT_PROFILE}profile/`);
      const { data } = response;
      dispatch({ type: SET_PROFILE, payload: data.data });
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  const updateXRefreshToken = () => async dispatch => {
    dispatch({ type: UPDATE_X_REFRESH_TOKEN, payload: new Date().getTime()   });
   };


export { updateXRefreshToken, getProfileInfo };
