import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as API from "../../ApiUrl";
import back from "../../assets/img/icons/back.svg";
import { Stack, Switch, FormControlLabel } from "@mui/material";

import { TextField } from "@material-ui/core";
import { merchantApi } from "../../redux/api/apiConfig";
import PreviewContainer from "../../components/preview/PreviewContainer";
import PreviewItem from "../../components/preview/PreviewItem";
import { ADMIN, textFieldLabelProps } from "../../utils/common";
import ButtonPrimary from "../../components/ButtonPrimary";
import { useSelector } from "react-redux";

const UserApproval = (props) => {
  const [loading, setloading] = useState(false);
  const params = useParams();

  const history = useHistory();
  const [user, setUsers] = useState(null);
  const [changeStatus, setChangeStatus] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const userRole = useSelector((state) => state?.authReducer?.userRole);

  const onSubmit = async () => {
    let data = {};
    if (
      user?.user_role === "Administrator" ||
      user?.email_verified ||
      user?.email === userEmail
    ) {
      data = { is_active: changeStatus };
    } else {
      data = { email: userEmail, is_active: changeStatus };
    }
    setloading(true);

    try {
      const response = await merchantApi.patch(
        `${API.USER_LIST}${user.id}/`,
        data
      );
      toast.success("User info updated successfully");
      history.push("/administration/systemUser");
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
    setloading(false);
  };

  const getUsers = async () => {
    try {
      const response = await merchantApi.get(
        `${API.USER_LIST}` + params.id + "/"
      );
      setUsers(response.data.data);
      setUserEmail(response.data.data.email);
      setChangeStatus(response.data.data.is_active);
    } catch (error) {}
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleChange = (e) => {
    setChangeStatus(e.target.checked);
  };

  console.log("csdcscdsc", user, user?.email_verified, userRole);

  return (
    <div className="page-wrapper stb-page-wrapper stb-ph-8">
      <div className="user stb-h-100">
        <div className="users stb-h-100 bg-white p-4 radi">
          <span onClick={() => history.goBack()} className="back mb-4">
            <img src={back} alt="back" />
            <span>Back</span>
          </span>
          <div className="page-header pb-4 space-between">
            <h3 className="m-0 mb-2 page-title">Update User Info</h3>
          </div>
          <Stack alignItems={"center"}>
            <Stack sx={{ width: {xs: "100%", md:"60%"} }} >
              <PreviewContainer>
                <PreviewItem title={"First Name"} content={user?.first_name} />
                <PreviewItem title={"Last Name"} content={user?.last_name} />
                <PreviewItem title={"Counter"} content={user?.counter?.name} />
                <PreviewItem title={"Email"} content={user?.email} />
                <PreviewItem
                  title={"Phone Number"}
                  content={user?.phone_number}
                />
                <PreviewItem
                  title={"User Status"}
                  content={user?.is_active ? "Active" : "Suspended"}
                />
              </PreviewContainer>

              <Stack alignItems={"flex-start"} gap={2}>
                {!user?.email_verified && userRole === ADMIN && (
                  <TextField
                    fullWidth
                    variant="outlined"
                    className="mt-4 bg-white"
                    id="email"
                    name="email"
                    InputLabelProps={textFieldLabelProps}
                    label="Email"
                    value={userEmail}
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                  />
                )}
                {userRole === ADMIN && (
                  <FormControlLabel
                    sx={{ marginTop: 2 }}
                    control={
                      <Switch checked={changeStatus} onChange={handleChange} />
                    }
                    labelPlacement="top"
                    label={"Is User Active"}
                  />
                )}
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: "2rem",
                  gap: 2,
                }}
              >
                <ButtonPrimary loading={loading} onClick={onSubmit}>
                  Update
                </ButtonPrimary>
              </Stack>
            </Stack>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default UserApproval;
