import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
//
import { Box } from '@mui/material';
import ChatMessageItem from './ChatMessageItem';
import { useSelector } from 'react-redux';
import ChatMessageItemSkeleton from './ChatMessageItemSkeleton';
// ----------------------------------------------------------------------

ChatMessageList.propTypes = {
  conversation: PropTypes.object.isRequired,
};

export default function ChatMessageList({ conversation, getRootProps }) {
  const scrollRef = useRef(null);

  const { isLoading, activeConversationId } = useSelector(
    (state) => state.chat
  );

  const { replies } = conversation || {};

  console.log(conversation,'cviubr3c');

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [replies]);

  const loading = isLoading || (conversation.id === '' && activeConversationId);

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{ overflowX: 'auto', p: 3, height: 1 }}
        ref={scrollRef}
      >
        {loading
          ? [...Array(12)].map((item, i) => (
              <ChatMessageItemSkeleton sx={{ ml: i % 3 === 0 ? 'auto' : '' }} />
            ))
          : replies.length > 0 &&
            replies.map((reply) => (
              <ChatMessageItem
                key={reply.id}
                message={reply}
                conversation={conversation}
              />
            ))}
      </Box>
    </>
  );
}
