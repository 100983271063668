const Colors = {
    primary: '#0B0523',
    secondary: '#FCBD00',
    tableHeaderBg: '#F4F4F4',
    tableBodyText: '#667085',
    cardBg: '#F0F0F0',
    modalBackdrop:'rgba(11, 5, 35, 0.8)',
    error:'#d32f2f'
}

export default Colors