import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";
import { useDispatch } from "react-redux";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import * as API from "./ApiUrl";
import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import store from "./redux/store";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getPermissions } from "./redux/actions/auth";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const dotenv = require("dotenv");

dotenv.config();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const theme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  overrides: {
    MuiInputLabel: {
      root: {
        "&$focused": {
          // color: '#FCBD00',
        },
        "&$error": {
          // color: 'red',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$focused $notchedOutline": {
          // borderColor: '#FCBD00',
        },
        borderRadius: "8px",
      },
    },
  },
});

export default function App() {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const refresh = localStorage.getItem("refresh");
  console.log(token);

  useEffect(() => {
    if (token && refresh) {
      hydrateStore();
    }
  }, []);

  const hydrateStore = async () => {
    dispatch(getPermissions());
  };

  // useEffect(() => {
  //   axios.interceptors.response.use(
  //     function (response) {
  //       return response;
  //     },
  //     function (error) {
  //       console.log('errorinappjs',error);

  //         return Promise.reject(error);

  //     }
  //   );
  // }, []);

  return (
    // <Provider store={store}>
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <Elements stripe={stripePromise}>
            <ScrollToTop />
            <HomePage stripe={stripePromise} />
            <ToastContainer />
          </Elements>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
    // </Provider>
  );
}
