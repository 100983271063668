import * as actionTypes from "../actionTypes";
import { updateObject } from "./utility";

const initialState = {
  loading: false,
  businessType: [],
  category: [],
  businessDetails:{},
  error: null,
};

const getbusiesstype = (action, state) => {
  return updateObject(state, {
    loading: true,
  });
};

const setbusiesstype = (action, state) => {
  return updateObject(state, {
    loading: true,
    businessType: action.payload,
  });
};
const getbusiesscategory = (action, state) => {
  return updateObject(state, {
    loading: true,
  });
};

const setbusinesscategory = (action, state) => {
  return updateObject(state, {
    loading: true,
    category: action.payload,
  });
};

const setbusinessDetails = (action, state) => {
  return updateObject(state, {
    businessDetails: action.payload
  })
}

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BUSINESSTYPE:
      return getbusiesstype(action, state);
    case actionTypes.SET_BUSINESSTYPE:
      return setbusiesstype(action, state);
    case actionTypes.GET_BUSINESSCATEGORY:
      return getbusiesscategory(action, state);
    case actionTypes.SET_BUSINESSCATEGORY:
      return setbusinesscategory(action, state);
    case actionTypes.SET_BUSINESS_DETAILS:
        return setbusinessDetails(action, state);
    default:
      return state;
  }
};

export default businessReducer;
