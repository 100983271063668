import React, { useState, useEffect, useCallback } from 'react';
import 'rsuite/dist/styles/rsuite-default.css';

import { Box, Card, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ChatSidebar, ChatWindow } from './components';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '../../routes';
import {
  getAllTickets,
  getConversation,
  resetActiveConversation,
} from '../../redux/actions/chat';
import { toast } from 'react-toastify';
import NewTicketModal from '../support/component/NewTicketModal';
import ButtonPrimary from '../../components/ButtonPrimary';

const UserChat = () => {
  const [showNewTicketModal, setShowNewTicketModal] = useState(false);
  const [openOnly, setOpenOnly] = useState(true);
  const [categoryValue, setCategoryValue] = useState('');
  const { conversationKey } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeConversationId, tickets } = useSelector((state) => state.chat);

  const toggleNewTicketModal = () => {
    setShowNewTicketModal((prevState) => !prevState);
  };

  const selectedConversation = useSelector(() => {
    if (activeConversationId && tickets.length > 0) {
      return tickets.find((ticket) => ticket.id === activeConversationId);
    }

    return {
      id: '',
      replies: [],
    };
  });

  useEffect(() => {
    dispatch(getAllTickets(openOnly ? 'open' : null));
  }, [dispatch,openOnly]);
  useEffect(() => {
    const getDetails = async () => {
      try {
        await dispatch(getConversation(conversationKey));
      } catch (error) {
        toast.error(error?.data?.data?.message || 'Something went wrong');
        console.error(error);
        history.push(Routes.UserChatGeneral.path);
      }
    };

    if (conversationKey) {
      getDetails();
    } else if (activeConversationId) {
      dispatch(resetActiveConversation());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationKey]);

  const toggleOpenTickets = (event) => {
    setOpenOnly((prev) => !prev);
    if (!openOnly && selectedConversation.status === 'closed') {
      history.push(Routes.UserChatGeneral.path);
    }
  };
  let TABS = [
    { value: '', label: 'All', color: 'complete', count: tickets?.length }
  ];

  const onCategoryFilterChanges = useCallback(
    (newStatus) => {
      setCategoryValue(newStatus);
      if (newStatus !== selectedConversation.type) {
        history.push(Routes.UserChatGeneral.path);
      }
    },
    [selectedConversation, history]
  );

  return (
    <Box sx={{ padding: '0 24px 24px' }}>
      <Stack sx={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mb:4}}>
        <Typography variant="h4">Support</Typography>
        <ButtonPrimary onClick={toggleNewTicketModal}>
          New Ticket
        </ButtonPrimary>
      </Stack>
      <Card sx={{ height: '72vh', display: 'flex' }}>
        <ChatSidebar
          toggleOpenTickets={toggleOpenTickets}
          openOnly={openOnly}
          category={categoryValue}
          tabs={TABS}
          onCategoryFilterChanges={onCategoryFilterChanges}
        />
        <ChatWindow
          selectedConversation={selectedConversation}
          openOnly={openOnly}
          category={categoryValue}
        />
      </Card>
      {showNewTicketModal && (
            <NewTicketModal
              isOpen={showNewTicketModal}
              onClose={toggleNewTicketModal}
              getTickets={() => {
                dispatch(getAllTickets(openOnly ? 'open' : 'closed'))
              }}
            />
          )}
    </Box>
  );
};

export default UserChat;
