import React, { useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useFormik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import {
  getBusinessDocs,
  getBusinessInfo,
  getCompanyRoles,
  getStatus,
} from "../../../../redux/actions/kybActions";
import { SET_PROFILE } from "../../../../redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { Loader, Button } from "rsuite";
import { toast } from "react-toastify";
import _ from "lodash";
import { Stack, Autocomplete, Typography } from "@mui/material";
import { TextField, MenuItem } from "@material-ui/core";
import { textFieldLabelProps } from "../../../../utils/common";

import {
  ADD_MERCHANT_API,
  MERCHANT_PROFILE,
  TIMEZONES,
  OCCUPATION_LIST,
} from "../../../../ApiUrl";
import { merchantApi } from "../../../../redux/api/apiConfig";
import ButtonSecondary from "../../../../components/ButtonSecondary";
import ButtonPrimary from "../../../../components/ButtonPrimary";
import Iconify from "../../../../components/Iconify";

const kybValidation = yup.object({
  business_name: yup
    .string("Enter your business name")
    .min(2, "Please enter you real name")
    .required("Business name is required")
    .nullable(),

  no_of_stores: yup
    .number("Enter your stores count")
    .min(1, "Should have minimum one store")
    .positive("Enter valid stores count")
    .required("Store count is required")
    .integer()
    .nullable(),

  business_category: yup
    .string("Enter your business category")
    .required("Business category is required")
    .nullable(),

  business_subcategory: yup
    .string("Enter your business subcategory")
    .required("Business subcategory is required")
    .nullable(),

  occupation: yup
    .string("Select occupation type")
    .required("Occupation type is required")
    .nullable(),

  country: yup
    .mixed("Select country")
    .required("Country is required")
    .nullable(),
  province: yup
    .string("Select province")
    .required("Province is required")
    .nullable(),
  city: yup.string("Enter city").required("City is required").nullable(),
  postal_code: yup
    .string(" Enter postal code")
    .required("Postal code is required")
    .nullable(),
  address: yup
    .string("Enter address")
    .required("Address is required")
    .nullable(),

  business_type: yup
    .string("Select company type")
    .required("Company type is required")
    .nullable(),
  // .min(2.nullable(), 'Please enter you real name')
  registration_no: yup
    .string(" Enter registration number")
    .required("Registration number is required")
    .nullable(),

  role: yup
    .string("Enter company role")
    .required("Role is required")
    .nullable(),
  incorporation_year: yup
    .string("Enter incorporation year")
    .required("Incorporation year is required")
    .nullable(),
  web_url: yup.string("Enter web_url").notRequired().nullable(),
});

const UpdateBusinessInfo = ({ handleNext, onBackClick, getStatus, updateCurrentStepHandler }) => {
  const dispatch = useDispatch();

  const [updatingMerchantInfo, setUpdatingMerchantInfo] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [occupationList, setOccupationList] = useState(
    JSON.parse(localStorage.getItem("category-list")) || []
  );
  const [roles, setRoles] = useState([]);
  const [errors, setErrors] = useState([]);

  const businessTypes = useSelector(
    (state) => state.businessReducer.businessType
  );

  useEffect(() => {
    dispatch(
      getBusinessInfo(
        (res) => {
          console.log("reacascasc", res);
          const selectedCountryProvinces = countryList.filter(
            (country) =>
              country.alpha2code?.toLowerCase() ===
              res?.country?.alpha2code?.toLowerCase()
          )[0].province;
          setProvinces(selectedCountryProvinces);

          setSelectedCategory({ value: res?.category?.parent_category });
          businessInfoFormik.setValues({
            business_name: res?.name || "",
            no_of_stores: res?.no_of_store || 1,
            business_category: res?.category?.parent_category || "",
            business_subcategory: res?.category?.id || "",
            occupation: res?.occupation || "",
            country: res?.country,
            province: res.province,
            city: res.city,
            postal_code: res.postal_code,
            address: res.address,
            business_type: res.business_type.id,
            registration_no: res.registration_no,
            role: res.role,
            incorporation_year: res.incorporation_year || null,
            timezone: { value: res.timezone || "US/Central" },
            web_url: res.web_url || "",
          });
        },
        (err) => console.log("xwex23x23x", err)
      )
    );

    // dispatch(
    //   getBusinessDocs(
    //     (res) => {console.log("cdsasdasdaecwec", res)
    //     setErrors(res.data?.error_suggestion?.error)
    //   },
    //     (err) => console.log("acacecwc", err)
    //   )
    // );
    dispatch(
      getCompanyRoles(
        (res) => {
          console.log("wecwcewecwec", res);
          setRoles(res);
        },
        (err) => {
          console.log("err");
        }
      )
    );
  }, []);

  const getOccupationList = async () => {
    const response = await merchantApi.get(OCCUPATION_LIST);
    const {
      data: { data },
    } = response;

    console.log(data);
    setOccupationList(data);
    localStorage.setItem("occupation-list", JSON.stringify(data));
  };

  const getTimezones = async () => {
    try {
      const response = await merchantApi.get(TIMEZONES);
      console.log(response);
      console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const isTimezoneExistInList = response.data.data.find(
        (timezone) => timezone.value === browserTimezone
      );

      if (isTimezoneExistInList) {
        businessInfoFormik.setFieldValue("timezone", {
          value: browserTimezone,
        });
      } else if (browserTimezone === "Asia/Calcutta") {
        businessInfoFormik.setFieldValue("timezone", {
          value: "Asia/Kolkata",
        });
      }
      setTimezoneOptions(response.data.data);
    } catch (err) {
      toast.error(err?.data?.data?.message || "Something went wrong");
      console.log(err);
    }
  };

  const updateProfileRedux = async () => {
    try {
      const response = await merchantApi.get(`${MERCHANT_PROFILE}profile/`);
      const { data } = response;
      dispatch({ type: SET_PROFILE, payload: data.data });
    } catch (error) {
      toast.error(error.response.data.message || "Something went wrong");
    }
  };
  const onSubmit = () => {
    setUpdatingMerchantInfo(true);
    const data = {
      business_name: businessInfoFormik?.values?.business_name || "",
      no_of_stores: businessInfoFormik?.values?.no_of_stores || 1,
      business_category: businessInfoFormik?.values?.business_category || "",
      business_subcategory:
        businessInfoFormik?.values?.business_subcategory || "",
      occupation: businessInfoFormik?.values?.occupation || "",
      country: businessInfoFormik?.values?.country?.id,
      province: businessInfoFormik?.values.province,
      city: businessInfoFormik?.values.city,
      postal_code: businessInfoFormik?.values.postal_code,
      address: businessInfoFormik?.values.address,
      business_type: businessInfoFormik?.values.business_type,
      registration_no: businessInfoFormik?.values.registration_no,
      role: businessInfoFormik?.values.role,
      incorporation_year: businessInfoFormik?.values.incorporation_year || null,
      timezone: businessInfoFormik?.values?.timezone?.value || "US/Central",
      web_url: businessInfoFormik?.values.web_url || "",
    };
    merchantApi
      .patch(ADD_MERCHANT_API, {
        ...data,
        category: businessInfoFormik.values.category,
      })
      .then((response) => {
        getStatus();
        updateProfileRedux();
        updateCurrentStepHandler(3);
        toast.success("Merchant data updated successfully");
        setUpdatingMerchantInfo(false);
        handleNext();
      })
      .catch((err) => {
        setUpdatingMerchantInfo(false);
        console.log(err);
        handleNext();
        // toast.error(err.data.data[Object.keys(err.data.data)[0]][0]);
      });
  };
  let businessInfoFormik = useFormik({
    initialValues: {
      business_name: "",
      no_of_stores: 1,
      business_category: "",
      business_subcategory: "",
      occupation: "",
      country: "ca",
      province: "",
      city: "",
      postal_code: "",
      address: "",
      business_type: "",
      registration_no: "",
      role: "",
      incorporation_year: "",
      timezone: { value: "US/Central" },
      web_url: "",
    },
    onSubmit: onSubmit,
    validationSchema: kybValidation,
  });

  const countryList = JSON.parse(localStorage.getItem("country-list")) || [];
  const categoryList = JSON.parse(localStorage.getItem("category-list")) || [];

  const countryCodes = countryList.reduce((acc, cur) => {
    acc.push(cur.alpha2code);
    return acc;
  }, []);

  console.log("sdcdscsdc", businessInfoFormik);

  useEffect(() => {
    console.log(1);
    if (selectedCategory) {
      const selectedCategoryItem = _.find(categoryList, {
        id: selectedCategory.value,
      });
      setSubCategoryList(selectedCategoryItem.sub_category);
    }
  }, [selectedCategory]);

  useEffect(() => {
    getTimezones();
    getOccupationList();
  }, []);

  console.log("cdsdcsdc", businessInfoFormik.values);

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months start at 0
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  return (
    <Stack sx={{ width: "100%" }}>
      {errors.length > 0 && (
        <Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Iconify
              icon="fluent:error-circle-24-filled"
              color={"red"}
              width={24}
            />
            <Typography variant="h6" color={"error"}>
              Your KYB has failed.
            </Typography>
          </Stack>
          <Stack direction={"column"} ml={4}>
            {errors.map((error, index) => (
              <Typography variant="caption" color={"error"}>{`${
                index + 1
              })  ${error}`}</Typography>
            ))}
          </Stack>
        </Stack>
      )}

      <form onSubmit={businessInfoFormik.handleSubmit}>
      <Stack sx={{gap: 2}}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="business_name"
              name="business_name"
              InputLabelProps={textFieldLabelProps}
              label="Business Name*"
              value={businessInfoFormik.values.business_name}
              onChange={businessInfoFormik.handleChange}
              onBlur={businessInfoFormik.handleBlur}
              error={
                businessInfoFormik.touched.business_name &&
                Boolean(businessInfoFormik.errors.business_name)
              }
              helperText={
                businessInfoFormik.touched.business_name &&
                businessInfoFormik.errors.business_name
              }
            />
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="no_of_stores"
              name="no_of_stores"
              InputLabelProps={textFieldLabelProps}
              type="number"
              label="Number of stores*"
              value={businessInfoFormik.values.no_of_stores}
              onChange={businessInfoFormik.handleChange}
              onBlur={businessInfoFormik.handleBlur}
              error={
                businessInfoFormik.touched.no_of_stores &&
                Boolean(businessInfoFormik.errors.no_of_stores)
              }
              helperText={
                businessInfoFormik.touched.no_of_stores &&
                businessInfoFormik.errors.no_of_stores
              }
            />
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <TextField
              select
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="business_category"
              name="business_category"
              InputLabelProps={textFieldLabelProps}
              label="Business Category*"
              value={businessInfoFormik.values.business_category}
              onChange={(event) => {
                setSelectedCategory(event.target);
                businessInfoFormik.setFieldValue("business_subcategory", "");
                businessInfoFormik.handleChange(event);
              }}
              onBlur={(event) => {
                businessInfoFormik.handleChange(event);
              }}
              error={
                businessInfoFormik.touched.business_category &&
                Boolean(businessInfoFormik.errors.business_category)
              }
              helperText={
                businessInfoFormik.touched.business_category &&
                businessInfoFormik.errors.business_category
              }
            >
              {categoryList.map((category) => (
                <MenuItem value={category.id}>{category.name}</MenuItem>
              ))}
            </TextField>
            <TextField
              select
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="business_subcategory"
              name="business_subcategory"
              InputLabelProps={textFieldLabelProps}
              label="Business Subcategory*"
              value={businessInfoFormik.values.business_subcategory}
              onChange={businessInfoFormik.handleChange}
              onBlur={businessInfoFormik.handleBlur}
              error={
                businessInfoFormik.touched.business_subcategory &&
                Boolean(businessInfoFormik.errors.business_subcategory)
              }
              helperText={
                businessInfoFormik.touched.business_subcategory &&
                businessInfoFormik.errors.business_subcategory
              }
            >
              {subCategoryList.map((subCategory) => (
                <MenuItem value={subCategory.id}>{subCategory.name}</MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <TextField
              select
              fullWidth
              style={{ flexBasis: "50%" }}
              variant="outlined"
              className="mt-4 bg-white"
              id="occupation"
              name="occupation"
              InputLabelProps={textFieldLabelProps}
              label="Occupation Type*"
              value={businessInfoFormik.values.occupation}
              onChange={(event) => {
                // console.log(event);

                businessInfoFormik.handleChange(event);
              }}
              onBlur={(event) => {
                businessInfoFormik.handleChange(event);
              }}
              error={
                businessInfoFormik.touched.occupation &&
                Boolean(businessInfoFormik.errors.occupation)
              }
              helperText={
                businessInfoFormik.touched.occupation &&
                businessInfoFormik.errors.occupation
              }
            >
              {occupationList.map((occupation) => (
                <MenuItem value={occupation.value}>{occupation.label}</MenuItem>
              ))}
            </TextField>
            <Stack sx={{ width: {xs: "100%", md: "50%"} }}>
              <ReactFlagsSelect
                countries={countryCodes}
                className="mt-4 bg-white material-inp"
                style={{ lineHeight: "2rem" }}
                selected={businessInfoFormik?.values?.country?.alpha2code}
                onSelect={(code) => {
                  let country = _.find(countryList, {
                    alpha2code: code,
                  });
                  let e = {
                    target: {
                      name: "country",
                      value: country,
                    },
                  };
                  businessInfoFormik.handleChange(e);
                  const selectedCountryProvinces = countryList.filter(
                    (country) => country.alpha2code === code
                  )[0].province;
                  setProvinces(selectedCountryProvinces);
                }}
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <TextField
              select
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="province"
              name="province"
              InputLabelProps={textFieldLabelProps}
              label="Province*"
              value={businessInfoFormik.values.province}
              onChange={(event) => {
                // console.log(event);

                businessInfoFormik.handleChange(event);
              }}
              onBlur={(event) => {
                businessInfoFormik.handleChange(event);
              }}
              error={
                businessInfoFormik.touched.province &&
                Boolean(businessInfoFormik.errors.province)
              }
              helperText={
                businessInfoFormik.touched.province &&
                businessInfoFormik.errors.province
              }
            >
              {provinces.map((province) => (
                <MenuItem value={province.id}>{province.name}</MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="city"
              name="city"
              InputLabelProps={textFieldLabelProps}
              label="City*"
              value={businessInfoFormik.values.city}
              onChange={businessInfoFormik.handleChange}
              onBlur={businessInfoFormik.handleBlur}
              error={
                businessInfoFormik.touched.city &&
                Boolean(businessInfoFormik.errors.city)
              }
              helperText={
                businessInfoFormik.touched.city &&
                businessInfoFormik.errors.city
              }
            />
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="postal_code"
              name="postal_code"
              InputLabelProps={textFieldLabelProps}
              label="Postal Code*"
              value={businessInfoFormik.values.postal_code}
              onChange={businessInfoFormik.handleChange}
              onBlur={businessInfoFormik.handleBlur}
              error={
                businessInfoFormik.touched.postal_code &&
                Boolean(businessInfoFormik.errors.postal_code)
              }
              helperText={
                businessInfoFormik.touched.postal_code &&
                businessInfoFormik.errors.postal_code
              }
            />
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="address"
              name="address"
              InputLabelProps={textFieldLabelProps}
              label="Business Address*"
              value={businessInfoFormik.values.address}
              onChange={businessInfoFormik.handleChange}
              onBlur={businessInfoFormik.handleBlur}
              error={
                businessInfoFormik.touched.address &&
                Boolean(businessInfoFormik.errors.address)
              }
              helperText={
                businessInfoFormik.touched.address &&
                businessInfoFormik.errors.address
              }
            />
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <Autocomplete
              id="timezone"
              value={businessInfoFormik.values.timezone}
              onChange={(e, newValue) => {
                console.log(e, newValue);
                businessInfoFormik.setFieldValue("timezone", newValue);
                // businessInfoFormik.handleChange(e);
              }}
              options={timezoneOptions}
              getOptionLabel={(option) => option?.value ?? ""}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              sx={{ flexGrow: 1, width: "100%", mt: 3 }}
              autoSelect={true}
              renderInput={(params) => {
                return (
                  <TextField
                    placeholder="Select Timezone"
                    {...params}
                    variant="outlined"
                    name="timezone"
                    onBlur={businessInfoFormik?.handleBlur}
                    error={
                      businessInfoFormik?.touched?.timezone &&
                      Boolean(businessInfoFormik?.errors?.timezone)
                    }
                    helperText={
                      businessInfoFormik?.touched?.timezone &&
                      businessInfoFormik?.errors?.timezone
                    }
                  />
                );
              }}
            />
            <TextField
              select
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="business_type"
              name="business_type"
              InputLabelProps={textFieldLabelProps}
              label="Company Type*"
              value={businessInfoFormik.values.business_type}
              onChange={businessInfoFormik.handleChange}
              onBlur={businessInfoFormik.handleChange}
              error={
                businessInfoFormik.touched.business_type &&
                Boolean(businessInfoFormik.errors.business_type)
              }
              helperText={
                businessInfoFormik.touched.business_type &&
                businessInfoFormik.errors.business_type
              }
            >
              {businessTypes.map((businessType) => (
                <MenuItem value={businessType.id}>
                  {businessType.option}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <TextField
              select
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="role"
              name="role"
              InputLabelProps={textFieldLabelProps}
              label="Your role in the company*"
              value={businessInfoFormik.values.role}
              onChange={(event) => {
                businessInfoFormik.handleChange(event);
              }}
              onBlur={(event) => {
                businessInfoFormik.handleChange(event);
              }}
              error={
                businessInfoFormik.touched.role &&
                Boolean(businessInfoFormik.errors.role)
              }
              helperText={
                businessInfoFormik.touched.role &&
                businessInfoFormik.errors.role
              }
            >
              {roles.map((role) => (
                <MenuItem value={role.id}>{role.name}</MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              type={"date"}
              variant="outlined"
              className="mt-4 bg-white"
              id="incorporation_year"
              name="incorporation_year"
              InputLabelProps={textFieldLabelProps}
              label="Registration Date*"
              value={businessInfoFormik.values.incorporation_year}
              onChange={businessInfoFormik.handleChange}
              onBlur={businessInfoFormik.handleBlur}
              inputProps={{
                max: getTodayDate(),
              }}
              error={
                businessInfoFormik.touched.incorporation_year &&
                Boolean(businessInfoFormik.errors.incorporation_year)
              }
              helperText={
                businessInfoFormik.touched.incorporation_year &&
                businessInfoFormik.errors.incorporation_year
              }
            />
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="registration_no"
              name="registration_no"
              InputLabelProps={textFieldLabelProps}
              type="text"
              label="Registration Number*"
              value={businessInfoFormik.values.registration_no}
              onChange={businessInfoFormik.handleChange}
              onBlur={businessInfoFormik.handleBlur}
              error={
                businessInfoFormik.touched.registration_no &&
                Boolean(businessInfoFormik.errors.registration_no)
              }
              helperText={
                businessInfoFormik.touched.registration_no &&
                businessInfoFormik.errors.registration_no
              }
            />
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="web_url"
              name="web_url"
              InputLabelProps={textFieldLabelProps}
              label="Website (Optional)"
              value={businessInfoFormik.values.web_url}
              onChange={businessInfoFormik.handleChange}
              onBlur={businessInfoFormik.handleBlur}
              error={
                businessInfoFormik.touched.web_url &&
                Boolean(businessInfoFormik.errors.web_url)
              }
              helperText={
                businessInfoFormik.touched.web_url &&
                businessInfoFormik.errors.web_url
              }
            />
          </Stack>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "2rem",
            gap: 1,
          }}
        >
          <ButtonSecondary onClick={onBackClick}>Back</ButtonSecondary>

          <ButtonPrimary
            type={"submit"}
            // disabled={!businessInfoFormik.isValid}
            loading={updatingMerchantInfo}
          >
            Update and continue
          </ButtonPrimary>
        </Stack>
      </form>
    </Stack>
  );
};

function mapStateToProps({ kybReducer }) {
  const { status } = kybReducer;
  return {
    data: status.data,
  };
}

const actionCreators = {
  getStatus,
};

export default connect(mapStateToProps, actionCreators)(UpdateBusinessInfo);
