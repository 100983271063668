import React from "react";
import BulletPointer from "../../../../components/BulletPointer";
import { statusColors } from "../../../../utils/common";
import { Chip, Typography } from "@mui/material";
import { Stack } from "@mui/system";

const colors = statusColors.colors;
const bgColors = statusColors.bgColors;

const KybMenu = ({ displayText, statusColor }) => {
  return (
    <aside className="kyb-menu">
      <header className="kyb-menu-header">
        <div>
          <BulletPointer
            fg={colors[`${statusColor}`]}
            bg={bgColors[`${statusColor}`]}
          />
          <span>Status</span>
        </div>
        <span>
          <Chip
            style={{
              fontSize: "13px",
              fontWeight: "600",
              backgroundColor: bgColors[`${statusColor}`],
              color: colors[`${statusColor}`],
            }}
            label={displayText}
            // color="success"
          />
        </span>
      </header>
     {displayText === 'Failed' && <section className="kyb-menu-items">
          <Stack spacing={1}>
            <Typography variant="h6">Why is KYB Approval Important?</Typography>
            <Typography variant="body1">To aid the government in combating the financing of terrorism and money laundering activities, federal law mandates that all financial institutions acquire, verify, and document information identifying each legal entity and individual opening an account.
</Typography>
          </Stack>
      </section>}
    </aside>
  );
};

export default KybMenu;
