import React, { useEffect, useState } from "react";
import { Routes } from "../../routes";

import { Box, Stack, Typography, Skeleton, Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { blockAction, getFraudData } from "../../redux/actions/accountActions";
import ButtonPrimary from "../../components/ButtonPrimary";
import ButtonSecondary from "../../components/ButtonSecondary";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import Image from "../../components/image";
import FraudImage from "../../assets/img/fraud.svg";
import { toast } from "react-toastify";
import { startCase } from "lodash";
import { CLEAR_STORE_DATA } from "../../redux/actionTypes";
import { handleBackgroundClick } from "../../utils/common";

export default () => {
  const [fraudData, setFraudData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [blockActionLoading, setBlockActionLoading] = useState(false)
  const [showSummaryDialog, setShowSummaryDialog] = useState(false)
  const [blockRes, setBlockRes] = useState(null)

  const toggleSummaryDialog = () => {
    setShowSummaryDialog(prev => !prev)
  }

  const handleClose = (event, reaon) => {
    handleBackgroundClick(reaon, toggleSummaryDialog, false);
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(
      getFraudData(
        id,
        (res) => {
          console.log("ceorncerc", res);
          setFraudData(res);
          setLoading(false);
        },
        (err) => {
          console.log('cwcwec')
          history.replace(Routes.NotFound.path)
          setLoading(false);
        }
      )
    );
  }, [id, dispatch, history]);

  const onBlockAction = () => {
    setBlockActionLoading(true)
    const data = {
      fraud_report: id
    }
    dispatch(blockAction(data, (res) =>{
      setBlockActionLoading(false)
      toast.success(res.message)
      setBlockRes(res.data)
      if(res?.data?.same_user){
        localStorage.clear();
        dispatch({ type: CLEAR_STORE_DATA });
        history.replace({ pathname:Routes.ReportFraudSummary.path, state:{id} })
      } else {
        toggleSummaryDialog();
      }
    },
    (err)=> {
       setBlockActionLoading(false)
      toast.error(err?.response?.data?.message || "Something went wrong");
     }
     ))
  }

  return (
    <Box sx={{ padding: 10 }}>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack>
          
          {!loading && fraudData ? (
            <>
            <Typography variant="h3" sx={{mb:4}}>Report Fraud</Typography>
            <ul>
              <li>
              <Stack sx={{ flexDirection: "row", gap: 1 }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Activity Type:
                </Typography>
                <Typography>{fraudData?.activity_type}</Typography>
              </Stack>
              </li>
              <li>
              <Stack sx={{ flexDirection: "row", gap: 1 }}>
                <Typography sx={{ fontWeight: "bold" }}>
                Withdrawal Account:
                </Typography>
                <Typography>{fraudData?.withdraw_account?.email}</Typography>
              </Stack>
              </li>
              <li>  
              <Stack sx={{ flexDirection: "row", gap: 1 }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Type:
                </Typography>
                <Typography>{fraudData?.account_type}</Typography>
              </Stack>
              </li>
              <li>    
              <Stack sx={{ flexDirection: "row", gap: 1 }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Added By:
                </Typography>
                <Typography>{startCase(fraudData?.withdraw_account?.name)}</Typography>
              </Stack>
                </li>
              <li>
              <Stack sx={{ flexDirection: "row", gap: 1 }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Date: 
                </Typography>
                <Typography>{format(parseISO(fraudData?.action_datetime), "dd-MM-yyyy hh:mm a")}</Typography>
              </Stack>
              </li>
            </ul>
            <Stack
        sx={{ flexDirection: "row", justifyContent: "flex-start", gap: 1, mt: 4 }}
      >
        <ButtonSecondary
          onClick={() => history.replace(Routes.DashboardOverview.path)}
          disabled={blockActionLoading}
        >
          Go Back Home
        </ButtonSecondary>
        <ButtonPrimary loading={blockActionLoading} onClick={onBlockAction}>Take Action</ButtonPrimary>
      </Stack>
            </>
          ) : (
            <>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="rounded" width={"100%"} height={400}/>

            <Skeleton variant="rounded" width={"100%"} height={100}/>
            </>
          )}

          
        </Stack>

        <Stack sx={{display:{xs:"none", md:"block"}}}>
          <Image src={FraudImage} sx={{ width: { md: '40rem', lg: '60rem'} }} />
        </Stack>
      </Stack>

      <Dialog
      open={showSummaryDialog}
      fullWidth
      maxWidth={"xs"}
      onClose={handleClose}
      scroll={"paper"}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle>
            <Stack direction={"column"}>
              Report Issue
              <Typography variant="caption">
              Thank you for reporting the suspicious activity and taking action to block it. We appreciate your vigilance in keeping our platform safe
              </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle2">Action Taken</Typography>
            <ul>
              <li>
                <Typography variant="body2">
                We have taken immediate steps to address the reported activity and ensure your account's security.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                For your peace of mind, we have created a support ticket for your reference. Our team will be reviewing the details and may reach out if they require further information. You can also reach out to our support team directly if you have any questions or concerns.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                Additionally, if you wish to unblock user [username] in the future, you can reset their password and manage their account access through the User Management section. </Typography>
              </li>
            </ul>

           
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonPrimary onClick={() => 
              history.replace(`${Routes.UserChatGeneral.path}ticket/${blockRes?.ticket_id}`)}>
              View Support Ticket
            </ButtonPrimary>
          </DialogActions>
    </Dialog>

     
    </Box>
  );
};
