import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import userGuidePdf from "../../assets/userGuide.pdf";
import "./style.scss";

const Faqs = () => {
  return (
    <div className="page-wrapper stb-page-wrapper stb-ph-8">
      <div className="stb-h-100">
        <div className="reports stb-h-100 bg-white p-xl-4 radi">
          <div className="d-flex flex-col align-items-start gap-4">
            <div className="d-flex space-between w-100">
              <div>
                <h3 className="m-0 page-title">FAQs</h3>
              </div>
            </div>
          </div>
          <div className="faqContent">
            <div className="faqBlock">
              <h4>1. User Guide</h4>
              <object
                data={userGuidePdf}
                type="application/pdf"
                width="500px"
                height="600px"
              >
                <p>
                  Alternative text - include a link{" "}
                  <a href="http://africau.edu/images/default/sample.pdf">
                    to the PDF!
                  </a>
                </p>
              </object>
            </div>
            <div className="faqBlock">
              <h4>1. Account Creation</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: How do I create a new merchant account on the STB Cash
                    Merchant Portal?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Navigate to the [STB Cash Merchant Portal sign-up
                    page](https://cash-merchant-portal-dev.netlify.app/signin)
                    and enter your personal details, including your first and
                    last name, email, phone number, date of birth, and confirm
                    you're not a politically exposed person. Then provide your
                    business details, agree to the Terms of Use and Privacy
                    Policy, and click on "Sign Up."
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What personal information is required to sign up?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: You need to provide your first name, last name, email,
                    phone number, and date of birth.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What business information is required during account
                    creation?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: You need to provide your business name, category,
                    subcategory, occupation type, province, city, postal code,
                    business address, and time zone.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>2. Email Verification</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>Q: How do I verify my email address?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: After submitting your details, check your email for a
                    verification message from STBCash. Click on the "Click Here"
                    button in the email to confirm your address and set your
                    password.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>3. Password Setup and Login</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>Q: How do I set up my password?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: After verifying your email, you will be directed to a
                    password setup page. Enter the temporary password provided
                    in the email, then create and confirm your new password.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>Q: How do I log in to my account?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Navigate to the [STB Cash Merchant Portal login
                    page](https://cash-merchant-portal-dev.netlify.app/signin),
                    enter your registered email and password, and click on
                    "Login Now."
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>4. Creating and Managing System Users</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: How do I create a new system user account?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Log in to your STB Cash Merchant account, navigate to the
                    Administration section, select "System User," and click on
                    "Add New User." Fill in the required user details and click
                    on "Create User."
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>Q: How can I manage system users?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: In the System User section, you can view user details,
                    edit user information, and change user status between Active
                    and Inactive.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>5. Store List Management</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>Q: How do I add a new store?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: In the Administration section, select "Store" and click
                    on "Add New Store." Fill in the store details, including
                    name, ID, country, city, address, postal code, and phone
                    number, then click "Add Store."
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: How do I edit an existing store’s information?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Locate the store in the Store List, click on the "Edit"
                    icon, update the necessary fields, and click on "Save."
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>6. Store List Management</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>Q: How do I add a new store?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: In the Administration section, select "Store" and click
                    on "Add New Store." Fill in the store details, including
                    name, ID, country, city, address, postal code, and phone
                    number, then click "Add Store."
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: How do I edit an existing store’s information?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Locate the store in the Store List, click on the "Edit"
                    icon, update the necessary fields, and click on "Save."
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>7. Dashboard and Reporting</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What information is available on the Dashboard page?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: The Dashboard provides analytics on receipts, balance and
                    payouts, recent transactions, and reports overview,
                    including sales volume, team size, and customer retention.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: How can I view all financial transactions?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Navigate to the "All Transactions" page under the Reports
                    tab to monitor receipts, withdrawals, and refunds. Use
                    filters and search options to refine the transaction list.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>8. Withdrawal Account Management</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>Q: How do I add a withdrawal account?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Go to the Merchant Profile tab, select "Withdrawal," and
                    click "Add New Account." Enter the necessary Interac account
                    details and click "Add."
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: How do I activate a withdrawal account?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Once the account is verified, click the "Activate for
                    Withdraw" button next to the account on the Withdrawal
                    Accounts page.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>9. Home Screen Improvements</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What changes have been made to the home screen?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: The home screen now includes updated data graphs and a
                    recent transaction table. It has two new call-to-action
                    (CTA) buttons: Wallet and Withdraw Funds. The Sales Breakup
                    by Currency section has been removed.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>10. Transactions</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What can I do on the Transactions page?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: You can view transaction amounts for yesterday, today,
                    and the total balance. Transactions can be filtered by type
                    (All, Receipt, Withdraw, Refund) and various criteria like
                    keywords, time period, store, counter, date, and status. The
                    status is color-coded for easy identification: Success
                    (green), Failed (red), Partially Refunded (purple), and
                    Fully Refunded (purple).
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>11. Refund Transactions</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: How can I initiate a refund for a transaction?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: If a transaction is successful, a Refund link will be
                    available under the Refund Value column for 90 days.
                    Clicking this link opens a pop-up where you can enter the
                    refund amount, which must be less than or equal to the total
                    transaction amount. Detailed breakdowns and refund
                    confirmation options are provided.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>12. Report Transactions</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What features are available on the Report Transactions
                    page?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: You can filter transactions by various criteria and view
                    detailed reports. Totals for Receipt, Withdrawn, and Refund
                    amounts are updated based on the applied filters.
                    Transaction statuses are color-coded for clarity.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>13. Wallets</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What information is available on the Wallet page?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: The Wallet page displays your total wallet balance and
                    includes a graph of the past six months' transactions (or
                    from the date of joining, whichever is shorter). CTAs for
                    Transactions and Withdraw Funds are also present.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>14. Withdrawals</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>Q: How do I make a withdrawal?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Admin users can view the total available balance,
                    in-process amount, and total withdrawn amount on the
                    Withdrawal page. When clicking the Withdraw CTA, you must
                    select a verified withdrawal account, enter the withdrawal
                    amount, and complete an OTP verification. Detailed balance
                    breakdowns are provided.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>15. QR Code</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>Q: How do I use the QR Code feature?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: The QR Code feature allows you to view and print your
                    merchant QR code with pay ID and merchant name. You can
                    generate dynamic QR codes for transactions with specific
                    amounts and optional remarks. The dynamic QR codes are valid
                    for one-time use and have an expiry time that can be
                    configured.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>16. Sign-Up/Login Errors</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What happens if I encounter errors during sign-up or
                    login?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: If you face technical issues while signing up or logging
                    in, an error message will be displayed, advising you to try
                    again later or contact support at help@spendthebits.com.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>17. Password Link Message After Account Creation</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What message is shown after account creation?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: After signing up, you will see a message on the thank you
                    screen indicating that a password setup link has been sent
                    to your email. You are advised to check your inbox,
                    including the spam folder.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>18. Know Your Business (KYB) Improvements</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What information is required for KYB verification?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: KYB verification requires personal and business
                    information, including details of control persons and
                    beneficial owners. The process involves multiple steps and
                    document submissions, typically taking 1-3 working days.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>19. Withdrawal Account Management</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: How do I manage withdrawal accounts?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Administrators can add, view, and delete withdrawal
                    accounts. An OTP verification is required to add a new
                    account, and accounts are verified within 24 hours. Verified
                    accounts can be used for withdrawals.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>20. Roles and Access</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What are the access levels for different roles?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Administrators have full access to all data and features.
                    Managers/Cashiers have limited access based on their
                    store/counter assignments and can only view and manage their
                    own data. Certain CTAs and features are restricted based on
                    the user's role.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>21. Support and Profile Settings </h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: How can I open a new support ticket?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Navigate to the Support tab, click on "New Ticket," fill
                    in the ticket details, and click "Open New Ticket."
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: How do I update my profile information?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: In the Settings tab, you can view and update your
                    personal and business information, including contact
                    details, business category, address, and more.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <Typography>
              For any further assistance, please contact the support team
              through the provided channels in the STB Cash Merchant Portal.
            </Typography>
            <h3>STB Cash Merchant Portal - Fraud Management FAQ</h3>
            <div className="faqBlock">
              <h4> 1. Critical Notifications</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What are Critical Notifications and how do they work?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Critical Notifications are alerts sent to admin users for
                    important activities such as OTP verification for withdrawal
                    accounts, adding new withdrawal accounts, withdrawal
                    initiation, and withdrawal failures. For logged-in admin
                    users, these notifications include links to report issues.
                    For stakeholders not added as admin users, the notifications
                    are sent without OTPs or links.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What should I do if I receive a Critical Notification?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: If you receive a Critical Notification, you should review
                    the content and use the provided link to report any issues
                    if you are an admin. Follow the instructions in the email to
                    address the notification appropriately.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>2. Report Issue Page</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: How do I report an issue from a Critical Notification
                    email?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Click on the "report an issue" link in the email. You
                    will be directed to log in to the merchant portal and then
                    taken to the Report Issue Page. Here, you can view the
                    activity details and choose to either cancel or block the
                    action.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What options are available on the Report Issue Page?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: On the Report Issue Page, you can view the activity
                    details and either click "Cancel" to return to the dashboard
                    or "Block Action" to prevent the reported activity.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>3. Auto Raise a Support Ticket</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What happens when I click the "Block Action" button?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Clicking the "Block Action" button automatically raises a
                    support ticket with you as the reporter. The ticket is
                    categorized as "Report Fraud," given the highest priority
                    (P1), and includes details of the blocked activity.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What information is included in the support ticket?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: The support ticket includes the category (Report Fraud),
                    priority (P1), subject (Block Activity: [Activity Type]),
                    and a message detailing the blocked user and action.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>4. Reported Issue (Same User)</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What happens if I report an issue that I triggered
                    myself?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: If you block an action that you triggered, you will
                    receive a password reset email, be logged out of the
                    merchant portal, and be unable to log in with the same
                    password until it is reset. You will see a thank you page
                    confirming these actions.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What steps should I take after reporting my own issue?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Follow the instructions to reset your password to a
                    strong, unique one to ensure your account's security. A
                    support ticket will be created for further review.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>5. Reported Issue (Other User)</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What happens if I report an issue triggered by another
                    user?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: The original user who triggered the reported action will
                    be blocked from the merchant portal, logged out immediately,
                    and unable to log in or reset their password. Their status
                    in user management will be updated to "Blocked" or
                    "Disabled." A thank you page will confirm these actions.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: Can I unblock a user that I have blocked?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: Yes, you can unblock a user by resetting their password
                    and managing their account access through the User
                    Management section.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What message will I see after reporting an issue
                    triggered by another user?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: You will see a thank you page confirming that the
                    suspicious activity has been reported and blocked, along
                    with the option to view the support ticket created for this
                    issue.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="faqBlock">
              <h4>6. General Actions Taken</h4>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Q: What actions are taken when an issue is reported?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A: The user who reported the issue is logged out, and a
                    password reset is initiated. A support ticket is
                    automatically created, and the suspicious activity is
                    blocked. For issues reported by other users, the responsible
                    user is blocked from accessing the portal.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <Typography>
              These FAQs should help you navigate the Fraud Management features
              in the STB Cash Merchant Portal. If you have additional questions
              or need further assistance, please contact support at
              help@spendthebits.com.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
