import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";

import {
  getProfile,
  setUserRole,
  setSingleWallet,
  setUserPayString,
} from "../../redux/actions/auth";
import { getBusinessDetails } from "../../redux/actions/businessApi";
import { Routes } from "../../routes";
import mail from "../../assets/img/icons/mail.svg";
import stblogo from "../../assets/img/icons/stblogo.svg";

import { Stack, Typography } from "@mui/material";
import ButtonPrimary from "../../components/ButtonPrimary";

const ReportFraudSummary = () => {
    const location = useLocation();
    const history = useHistory()
    
    useEffect(() => {
        if(!location?.state?.id){
            history.replace(Routes.Signin.path)
        }
  },[location, history])

  return (
    <main>
      <section className="vh-100 position-relative background">
        <img
          src={stblogo}
          className="position-absolute top-5 start-50 translate-middle"
          alt="logo"
        />

        <a
          className="socials twitter"
          href="https://twitter.com/Spend_The_Bits"
          target="_blank"
          rel="noopener noreferrer"
        >
          @Spend_The_Bits
        </a>
        <a
          className="socials mail"
          href="mailto:help@spendthebits.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={mail} alt="mail" />
          help@spendthebits.com
        </a>
        <Dialog
          open={true}
          disableEscapeKeyDown={true}
          fullWidth
          className="wallet-configure"
          BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0)" } }}
          PaperProps={{
            style: {
              boxShadow: "none",
              borderRadius: "8px",
              width: "480px",
              minHeight: "428px",
              maxHeight: "80vh",
            },
          }}
        >
          <DialogTitle>
            <Stack direction={"column"}>
              Report Issue
              <Typography variant="caption">
                Thank you for reporting the suspicious activity and taking
                action to block it. We appreciate your vigilance in keeping our
                platform safe
              </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle2">Action Taken</Typography>
            <ul>
              <li>
                <Typography variant="body2">
                  You have been logged out due to security concern and to
                  address this concern, we have initiated a reset password for
                  your account.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  For your peace of mind, we have created a support ticket for
                  your reference. Our team will be reviewing the details and may
                  reach out if they require further information. You can also
                  reach out to our support team directly if you have any
                  questions or concerns.{" "}
                </Typography>
              </li>
            </ul>

            <Typography variant="subtitle2" sx={{mt:4}}>Action Steps to be taken by you</Typography>
            <ul>
              <li>
                <Typography variant="body2">
                As next step, please reset your password to a strong and unique one, this will ensure your account security.
                </Typography>
              </li>
            </ul>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonPrimary onClick={() => history.replace(Routes.Signin.path)}>
              Close
            </ButtonPrimary>
          </DialogActions>
        </Dialog>
      </section>
    </main>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // explicitly forwarding arguments
    getprofile: () => dispatch(getProfile()),
    setUserRole: (val) => dispatch(setUserRole(val)),
    setSingleWallet: () => dispatch(setSingleWallet()),
    setUserPayString: () => dispatch(setUserPayString()),
    getBusinessDetails: () => dispatch(getBusinessDetails()),
  };
};
export default connect(null, mapDispatchToProps)(ReportFraudSummary);
