import axios from 'axios';
import * as API from '../../ApiUrl';
import {
  GET_PROFILE,
  SET_PROFILE,
  GET_PROFILE_FAIL,
  SET_USER_ROLE,
  SET_IS_SINGLE_WALLET,
  SET_USER_PAYSTRING,
  GET_PERMISSIONS,
  SET_PERMISSIONS,
  CLEAR_STORE_DATA,
} from '../actionTypes';
import { merchantApi } from '../../redux/api/apiConfig';
import { toast } from 'react-toastify';

let token = localStorage.getItem('token');

const config = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
};


export const setUserRole = (userRole) => async (dispatch) => {
  dispatch({
    type: SET_USER_ROLE,
    payload: userRole,
  });
};

export const setUserPayString = () => async (dispatch) => {
  merchantApi
    .get(API.PAY_STRING)
    .then((response) => {
      const { data } = response;
      if (data?.data?.paystring) {
        dispatch({
          type: SET_USER_PAYSTRING,
          payload: data.data.paystring,
        });
        return;
      }
    })
    .catch((error) => {});
};

export const setSingleWallet = () => async (dispatch) => {
  merchantApi
    .get(API.CONFIG)
    .then((response) => {
      const { data } = response;
      if (data.data.store_wise_wallet === null ||
        data.data.store_wise_wallet === undefined) {
        dispatch({
          type: SET_IS_SINGLE_WALLET,
          payload: data.data.store_wise_wallet,
        });
      } else {
        dispatch({
          type: SET_IS_SINGLE_WALLET,
          payload: !data.data.store_wise_wallet,
        });
      }
    })
    .catch((error) => {});
 };

 export const getPermissions = () => async (dispatch) => {
  dispatch({ type: GET_PERMISSIONS, payload: true });

  try {
    const res = await merchantApi.get(API.MERCHANT_PERMISSIONS);
    console.log("PERMISSIONSSSSS",res.data.data);
    dispatch({
      type: SET_PERMISSIONS,
      payload: res.data.data,
    });
    let timer = setTimeout(() => {
      dispatch({ type: GET_PERMISSIONS, payload: false });
    }, 500);
  } catch (err) {
    toast.error('Unable to fetch permissions.');
    let timer = setTimeout(() => {
      localStorage.clear();
      dispatch({ type: CLEAR_STORE_DATA });
      window.location.href = '/';
    }, 1000);
  }
};

export const getProfile = () => async (dispatch) => {
  dispatch({ type: GET_PROFILE });

  const token = localStorage.getItem('token');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await axios.get(`${API.USER_PROFILE}`, config);
    dispatch({
      type: SET_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROFILE_FAIL,
    });
  }
};
