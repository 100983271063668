import React, { useEffect } from 'react';
import { Col, Row, Form, Container } from '@themesberg/react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import { Button } from '@mui/material';
import { Dropdown, ButtonToolbar } from 'rsuite';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from '../../../routes';
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as API from '../../../ApiUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@material-ui/core';
import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import DialogContentText from '@mui/material/DialogContentText';
import { handleBackgroundClick, textFieldLabelProps } from '../../../utils/common';
import InputLabel from '@mui/material/InputLabel';

import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { merchantApi } from '../../../redux/api/apiConfig';

const validationSchema = yup.object({
  message: yup
    .string('Enter your message')
    .min(1, 'Please enter valid message')
    .max(300, 'Message is only 300 words')
    .required('Message is required!'),
  subject: yup
    .string('Enter your subject')
    .min(1, 'Enter Valid Subject')
    .required('Subject is required'),
  priority: yup.string('Select Priority').required('Priority is required'),
  category: yup.string('Select Category').required('Category is required'),
});

const NewTicketModal = ({ isOpen, onClose, getTickets }) => {
  //   const [image, setImage] = useState('');
  // const [isUploaded, setIsUploaded] = useState(false);
  // function handleImageChange(e) {
  //   if(e.target.files && e.target.files[0]){
  //     let reader = new FileReader()

  //     reader.onload = function(e) {
  //       setImage(e.target.result);
  //       setIsUploaded(true)
  //     }
  //     reader.readAsDataURL(e.target.files[0])
  //   }
  // }
  const [attachment, setAttachment] = useState('');
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(null);
  const notify = () => toast();
  const [cate, setCate] = useState([]);
  const [ticketPriority, setTicketPriority] = useState([]);

  // Category Api Start
  const getCate = async () => {
    try {
      const response = await merchantApi.get(`${API.SUPPORT_CATEGORY}`);
      // console.log('get list', response.data);
      setloading(false);
      setCate(response.data);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Something went wrong');
    }
  };

  const getPriorityOptions = async () => {
    try {
      const response = await merchantApi.get(`${API.TICKET_PRIORITY}`);
      console.log(response.data);
      setTicketPriority(response.data.results.sort((a, b) => b.id - a.id));
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    getCate();
    getPriorityOptions();
  }, []);

  function handleImage(event) {
    setAttachment(event.target.files[0]);
    console.log('image file details', event.target.files[0]);
  }

  // Ticket Create Api

  const onSubmit = async (values) => {
    setloading(true);
    let user_info = JSON.parse(localStorage.getItem('user-info'));
    const data = { ...values, user: user_info.user_id };
    console.log('data', data);
    try {
      if (attachment) {
        var filedata = new FormData();
        filedata.append('attachment', attachment);
        filedata.append('user', data.user);
        filedata.append('category', data.category);
        filedata.append('subject', data.subject);
        filedata.append('message', data.message);
        const response = await merchantApi.post(`${API.TICKET}`, filedata);

        formik.resetForm();
      } else {
        formik.resetForm();
        const response = await merchantApi.post(`${API.TICKET}`, data);
        console.log(response);
      }
      formik.resetForm();
      toast.success('Ticket created successfully');
      getTickets();
      onClose();
    } catch (err) {
      setloading(false);
      toast.error(err?.response?.data?.message || 'Something went wrong');
    }

    setloading(false);
  };

  // Ticket Api end

  const formik = useFormik({
    initialValues: {
      category: '',
      priority: '',
      subject: '',
      message: '',
      // attachment:"",
    },
    validateOnBlur: true,
    isInitialValid: false,
    validateOnMount: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  function PaperComponent(props) {
    return (
      <Draggable
        // enableUserSelectHack={false}
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  const handleClose = (event, reason) => {
    handleBackgroundClick(reason, onClose, false);
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth={'sm'}
      onClose={handleClose}
      // PaperComponent={PaperComponent}
      // aria-labelledby="draggable-dialog-title"
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          width: '628px',
          minHeight: '632px',
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle className="text-center">
        <p className="dialog-title">Open New Support Ticket</p>
      </DialogTitle>

      <hr className="hr" />

      <DialogContent className="mt-3">
        <form onSubmit={() => {}}>
          <Row>
            <Col xl={12} xs={12} className="mb-3">
              {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <InputLabel>Weight</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  // value={values.weight}
                  // onChange={handleChange('weight')}
                  // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                />
                <FormHelperText id="outlined-weight-helper-text">
                  Weight
                </FormHelperText>
              </FormControl> */}
              <TextField
                key="category"
                select
                fullWidth
                required
                variant="outlined"
                className=" bg-white"
                id="category"
                name="category"
                InputLabelProps={textFieldLabelProps}
                label="Category"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.category && Boolean(formik.errors.category)
                }
                helperText={formik.touched.category && formik.errors.category}
              >
                {cate.length > 0 &&
                  cate.map((category, i) => (
                    <MenuItem key={i + category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Col>
            <Col xl={12} xs={12} className="mb-3">
              <TextField
                key="priority"
                select
                required
                fullWidth
                variant="outlined"
                className=" bg-white"
                id="priority"
                name="priority"
                InputLabelProps={textFieldLabelProps}
                label="Priority"
                value={formik.values.priority}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.priority && Boolean(formik.errors.priority)
                }
                helperText={formik.touched.priority && formik.errors.priority}
              >
                {ticketPriority.map((priority, i) => (
                  <MenuItem key={priority.id} value={priority.id}>
                    {priority.name}
                  </MenuItem>
                ))}
              </TextField>
            </Col>

            <Col xl={12} xs={12}>
              <TextField
                key="subject"
                required
                fullWidth
                variant="outlined"
                className="mb-3 bg-white"
                id="subject"
                name="subject"
                InputLabelProps={textFieldLabelProps}
                type="text"
                label="Subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
              />
            </Col>

            <Col xl={12} xs={12}>
              <TextField
                key="message"
                required
                multiline
                minRows={4}
                fullWidth
                variant="outlined"
                className="mb-3 bg-white"
                id="message"
                name="message"
                InputLabelProps={textFieldLabelProps}
                type="text"
                label="Message"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
            </Col>
            <Col xl={12} className="mb-2">
              <p className="fw-bold">Add Attachment</p>
              <div>
                <Button
                  component="label"
                  variant="outlined"
                  className={`btn action-button primary_color text-white basis-70`}
                  // startIcon={<UploadFileIcon />}
                  sx={{ marginRight: '1rem', marginLeft: '0 !important' }}
                >
                  Upload File
                  <input
                    type="file"
                    accept="image/*, application/pdf "
                    name="attachment"
                    hidden
                    id="attachment"
                    onChange={handleImage}
                  />
                </Button>
                <span>Max 12 MB in size</span>
              </div>
            </Col>

            <Col xl={2} xs={2}>
              {attachment && <ImageThumb image={attachment} />}
            </Col>
          </Row>
        </form>
      </DialogContent>
      <hr className="hr" />

      <DialogActions className="footer-content">
        <Button
          className="btn white-btn action-button basis-30"
          onClick={onClose}
          appearance="subtle"
        >
          Cancel
        </Button>
        <Button
          className={`btn action-button primary_color text-white basis-70 ${
            !formik.isValid ? 'disabled' : null
          }`}
          appearance="primary"
          onClick={formik.handleSubmit}
          loading={loading.toString()}
          disabled={loading || !formik.isValid}
        >
          {!loading ? 'Open new ticket' : 'Loading...'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ImageThumb = ({ image }) => {
  return <img src={URL.createObjectURL(image)} alt={image.name} />;
};
export default NewTicketModal;
