import * as actionTypes from '../actionTypes';
import { updateObject } from './utility';

const initialState = {
  userRole: '',
  permissionLoading: true,
  isSingleWallet: true,
  userPayString: null,
  permissions:{}
};


const setPermissions = (action, state) => {
  return updateObject(state, {
    permissions: action.payload,
    // permissionLoading: false,
  });
};

const updatePermissionLoading = (action, state) => {
  return updateObject(state, {
    permissionLoading: action.payload,
  });
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };
    case actionTypes.SET_IS_SINGLE_WALLET:
      return {
        ...state,
        isSingleWallet: action.payload,
      };
    case actionTypes.SET_USER_PAYSTRING:
      return {
        ...state,
        userPayString: action.payload,
      };

      case actionTypes.GET_PERMISSIONS:
        return updatePermissionLoading(action, state);
  
      case actionTypes.SET_PERMISSIONS:
        return setPermissions(action, state);
  

    case actionTypes.CLEAR_STORE_DATA:
      return {};
    default:
      return state;
  }
};

export default authReducer;
