import React, { useState } from "react";
import _ from "lodash";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stack from "@mui/material/Stack";
import { getStatus } from "../../../../redux/actions/kybActions";
import { connect, useDispatch, useSelector } from "react-redux";
import UpdateBusinessInfo from "./UpdateBusinessInfo";
import UpdatePersonalInfo from "./UpdatePersonalInfo";
import UploadPersonalDocument from "./UploadPersonalDocument";
import UploadBusinessDocument from "./UploadBusinessDocument";
import ShareholderList from "./ShareholderList";
import Preview from "./Preview";
import { Typography } from "@mui/material";
import IdentomatKyc from "./identomatKyc";
import useNavigationPrompt from "../../../../components/useNavigationPrompt";
import NavigationModal from "../../../../components/model/NavigationModel";
import { UPDATE_CURRENT_STEP } from "../../../../ApiUrl";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { toast } from "react-toastify";

const KybForm = ({
  setStage,
  isKycRejected = false,
  isKybRejected = false,
  currentStep,
}) => {
  // const [activeStep, setActiveStep] = useState(
  //   isKycRejected ? 0 : isKybRejected ? 3 : 0
  // );
  const [activeStep, setActiveStep] = useState(currentStep);

  const [sessionKey, setSessionKey] = useState(null);
  const kybData = useSelector((state) => state.kybReducer);
  const dispatch = useDispatch();
  const isFormCompleted = kybData?.isFormCompleted;

  useNavigationPrompt(
    !isFormCompleted,
    "You have not completed all the steps. Are you sure you want to leave?"
  );

  const onBackClick = () => {
    setStage("welcome");
  };

  const handleNext = () => {
    // if (isKycRejected && isKybRejected) {
    //   setActiveStep(activeStep + 1);
    // } else {
    //   setActiveStep(activeStep + 1);
    // }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    // if (isKycRejected && isKybRejected) {
    //   setActiveStep(activeStep - 1);
    // } else if (isKybRejected) {
    //   setActiveStep((curr) => {
    //     if (curr === 3) {
    //       setStage("welcome");
    //     } else {
    //       return curr - 1;
    //     }
    //   });
    // } else {
    //   setActiveStep(activeStep - 1);
    // }
    setActiveStep(activeStep - 1);
  };

  const getcompleted = (r) => {
    return r < activeStep;
  };

  const updateCurrentStepHandler = (step) => {
    merchantApi
      .patch(UPDATE_CURRENT_STEP, {
        current_step: step,
      })
      .then(() => {
        toast.success("Current Step updated");
      })
      .catch((error) => {
        toast.error("Error Updating Current Step");
      });
  };

  function getStepContent(step) {
    // let step = 2;
    switch (step) {
      case 0:
        return (
          <UpdatePersonalInfo
            handleNext={handleNext}
            onBackClick={onBackClick}
            setSessionKey={setSessionKey}
          />
        );
      case 1:
        return (
          // <UploadPersonalDocument
          //   handleNext={handleNext}
          //   handleBack={handleBack}
          // />
          <IdentomatKyc
            handleNext={handleNext}
            handleBack={handleBack}
            sessionKey={sessionKey}
            updateCurrentStepHandler={updateCurrentStepHandler}
          />
        );

      case 2:
        return (
          <ShareholderList
            handleNext={handleNext}
            onBackClick={handleBack}
            updateCurrentStepHandler={updateCurrentStepHandler}
          />
        );
      case 3:
        return (
          <UpdateBusinessInfo
            handleNext={handleNext}
            onBackClick={handleBack}
            updateCurrentStepHandler={updateCurrentStepHandler}
          />
        );

      case 4:
        return (
          <UploadBusinessDocument
            handleNext={handleNext}
            handleBack={handleBack}
            onCompleteFlow={onBackClick}
            updateCurrentStepHandler={updateCurrentStepHandler}
          />
        );

      case 5:
        return (
          <Preview
            handleBack={handleBack}
            onCompleteFlow={onBackClick}
            updateCurrentStepHandler={updateCurrentStepHandler}
          />
        );
      default:
        return "Unknown step";
    }
  }

  const getStepLabel = (step) => {
    switch (step) {
      case 0:
        return "Personal Info";
      case 1:
        return "Personal Documents";
      case 2:
        return "Business Ownership";
      case 3:
        return "Business Info";
      case 4:
        return "Business Documents";
      case 5:
        return "Preview";
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <Stack sx={{ width: "100%" }} direction="column" alignItems={"center"}>
        <Typography sx={{ display: { xs: "block", md: "none" } }} variant="h6">
          {getStepLabel(activeStep)}
        </Typography>
        <Stepper activeStep={activeStep}>
          <Step completed={getcompleted(0)}>
            <StepLabel>
              <span className="d-none d-sm-block">{getStepLabel(0)}</span>
            </StepLabel>
          </Step>

          <Step completed={getcompleted(1)}>
            <StepLabel>
              <span className="d-none d-sm-block">{getStepLabel(1)}</span>
            </StepLabel>
          </Step>

          <Step completed={getcompleted(2)}>
            <StepLabel>
              <span className="d-none d-sm-block">{getStepLabel(2)}</span>
            </StepLabel>
          </Step>

          <Step completed={getcompleted(3)}>
            <StepLabel>
              <span className="d-none d-sm-block">{getStepLabel(3)}</span>
            </StepLabel>
          </Step>

          <Step completed={getcompleted(4)}>
            <StepLabel>
              <span className="d-none d-sm-block">{getStepLabel(4)}</span>
            </StepLabel>
          </Step>

          <Step completed={getcompleted(5)}>
            <StepLabel>
              <span className="d-none d-sm-block">{getStepLabel(5)}</span>
            </StepLabel>
          </Step>
        </Stepper>
        <section className="kyb-form">
          <article>{getStepContent(activeStep)}</article>
        </section>
      </Stack>
      <NavigationModal />
    </>
  );
};

function mapStateToProps({ kybReducer }) {
  const { status } = kybReducer;
  return {
    data: status.data,
  };
}

const actionCreators = {
  getStatus,
};

export default connect(mapStateToProps, actionCreators)(KybForm);
