import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import DevDashboard from "./dashboard/DevDashboard";
import WalletMenu from "./merchantprofile/WalletMenu/WalletMenu";
import Withdrawl from "./merchantprofile/Withdraw/Withdrawl";
import Faqs from "./faqs/faqs";
import WithdrawAccounts from "./merchantprofile/Withdraw/WithdrawAccounts";
import { ReportTransactions } from "./transaction";
import { Transactions } from "./reports";
import UserApproval from "./administration/UserApproval";
import {
  SystemUser,
  BusinessDetails,
  StoreMenu,
  Counter,
} from "./administration";
import Support from "./support/Support";
import TicketReply from "./support/component/TicketReply";
import Settings from "./Account/Settings";
import ForgotPassword from "./stb/ForgotPassword";
import ChangePassword from "./stb/ChangePassword";
import ResetPassword from "./stb/ResetPassword";
import Thankyou from "./stb/Thankyou";
import SignupThanks from "./stb/SingupThanks";
import Lock from "./stb/Lock";
import NotFoundPage from "./stb/NotFound";
import { UserChat } from "./userChat";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Preloader from "../components/Preloader";
import Authentication from "./stb/Authentication";
import GoforBusinessUpdate from "./GoforBusinessUpdate";
import QRDialog from "../components/QRDialog";
import FullLoader from "../components/FullLoader";
import { useSelector } from "react-redux";
import CreateCustomQrModal from "../components/customQr/CreateCustomQrModal";
import ReportFraud from "./stb/ReportFraud";
import ReportFraudSummary from "./stb/ReportFraudSummary";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({
  component: Component,
  permissionLoading = true,
  isAllowed = false,
  ...rest
}) => {
  const [showQRDialog, setShowQRDialog] = useState(false);
  const [showCreateCustomQrModal, setShowCreateCustomQrModal] = useState(false);
  let token = localStorage.getItem("token");
  if (!token) {
    return <Redirect to="/signin" />;
  }
  if (permissionLoading) {
    return <FullLoader />;
  }
  if (!isAllowed) {
    return <Redirect to={Routes.NotFound.path} />;
  }

  const toggleQrModal = () => {
    setShowQRDialog(!showQRDialog);
  };

  const toggleCreateCustomQrModal = () => {
    setShowCreateCustomQrModal(!showCreateCustomQrModal);
  };

  const onCreateCustomQrClick = () => {
    toggleQrModal();
    toggleCreateCustomQrModal();
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Sidebar />
          <main className="content">
            <Navbar handleQRDialog={toggleQrModal} />
            <Component {...props} />
            <QRDialog
              open={showQRDialog}
              handleClose={toggleQrModal}
              onCreateCustomQrClick={onCreateCustomQrClick}
            />
           {showCreateCustomQrModal && <CreateCustomQrModal
              open={showCreateCustomQrModal}
              handleClose={toggleCreateCustomQrModal}
            />}
          </main>
        </>
      )}
    />
  );
};

const RoutewithupdateBusiness = ({
  component: Component,
  permissionLoading = true,
  isAllowed = false,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);
  const [showQRDialog, setShowQRDialog] = useState(false);
  const [showCreateCustomQrModal, setShowCreateCustomQrModal] = useState(false);
  let token = localStorage.getItem("token");
  let user_info = JSON.parse(localStorage.getItem("user-info"));

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  if (!token) {
    return <Redirect to="/signin" />;
  }
  if (permissionLoading) {
    return <FullLoader />;
  }
  if (!isAllowed) {
    return <Redirect to={Routes.NotFound.path} />;
  }

  const handleQRDialog = () => {
    setShowQRDialog(!showQRDialog);
  };

  const toggleQrModal = () => {
    setShowQRDialog(!showQRDialog);
  };

  const toggleCreateCustomQrModal = () => {
    setShowCreateCustomQrModal(!showCreateCustomQrModal);
  };

  const onCreateCustomQrClick = () => {
    toggleQrModal();
    toggleCreateCustomQrModal();
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Sidebar />
          <main className="content">
            <Navbar handleQRDialog={handleQRDialog} />
            {user_info.merchant_id ? (
              <Component {...props} />
            ) : (
              <GoforBusinessUpdate />
            )}
            <QRDialog
              open={showQRDialog}
              handleClose={handleQRDialog}
              onCreateCustomQrClick={onCreateCustomQrClick}
            />
           {showCreateCustomQrModal && <CreateCustomQrModal
              open={showCreateCustomQrModal}
              handleClose={toggleCreateCustomQrModal}
            />}
          </main>
        </>
      )}
    />
  );
};

export default ({ isAuthenticate }) => {
  const { userRole, permissions, permissionLoading } = useSelector(
    (state) => state.authReducer
  );

  return (
    <Switch>
      <RouteWithLoader
        exact
        path={Routes.Signin.path}
        component={Authentication}
      />
      <RouteWithLoader
        exact
        path={Routes.ForgotPassword.path}
        component={ForgotPassword}
      />
      <RouteWithLoader
        exact
        path={`${Routes.ChangePassword.path}/`}
        component={ChangePassword}
      />
      <RouteWithLoader
        exact
        path={Routes.ResetPassword.path}
        component={ResetPassword}
      />
      <RouteWithLoader exact path={Routes.Thankyou.path} component={Thankyou} />
      <RouteWithLoader
        exact
        path={Routes.Signupthanks.path}
        component={SignupThanks}
      />
      <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
      <RouteWithLoader
        exact
        path={Routes.NotFound.path}
        component={NotFoundPage}
      />

      <RouteWithLoader
        exact
        path={Routes.ReportFraud.path}
        component={ReportFraud}
      />
      <RouteWithLoader
        exact
        path={Routes.ReportFraudSummary.path}
        component={ReportFraudSummary}
      />
      {/* pages */}
      <RouteWithSidebar
        exact
        path={Routes.DashboardOverview.path}
        component={DevDashboard}
        isAllowed={permissions?.home?.permission?.visible[userRole]}
        permissionLoading={permissionLoading}
      />
      <RouteWithSidebar
        exact
        path={Routes.Menu.path}
        component={WalletMenu}
        isAllowed={
          permissions?.merchantProfile?.wallets?.permission?.visible[userRole]
        }
        permissionLoading={permissionLoading}
      />
      <RouteWithSidebar
        exact
        path={Routes.ReportTransactions.path}
        component={ReportTransactions}
        isAllowed={permissions?.transactions?.permission?.visible[userRole]}
        permissionLoading={permissionLoading}
      />

      <RouteWithSidebar
        exact
        path={Routes.Withdrawl.path}
        component={Withdrawl}
        isAllowed={
          permissions?.merchantProfile?.withdrawal?.permission?.visible[
            userRole
          ]
        }
        permissionLoading={permissionLoading}
      />

      <RouteWithSidebar
        exact
        path={Routes.WithdrawAccounts.path}
        component={WithdrawAccounts}
        isAllowed={
          permissions?.merchantProfile?.withdrawal?.permission?.visible[
            userRole
          ]
        }
        permissionLoading={permissionLoading}
      />

      <RoutewithupdateBusiness
        exact
        path={Routes.Transaction.path}
        component={Transactions}
        isAllowed={
          permissions?.reports?.transactions?.permission?.visible[userRole]
        }
        permissionLoading={permissionLoading}
      />

      <RoutewithupdateBusiness
        exact
        path={Routes.SystemUser.path}
        component={SystemUser}
        isAllowed={
          permissions?.administrations?.SystemUser?.permission?.visible[
            userRole
          ]
        }
        permissionLoading={permissionLoading}
      />
      <RoutewithupdateBusiness
        exact
        path={Routes.UserApproval.path}
        component={UserApproval}
        isAllowed={
          permissions?.administrations?.SystemUser?.permission?.change[userRole]
        }
        permissionLoading={permissionLoading}
      />
      <RouteWithSidebar
        exact
        path={Routes.BusinessDetails.path}
        component={BusinessDetails}
        isAllowed={
          permissions?.administrations?.Business?.permission?.visible[userRole]
        }
        permissionLoading={permissionLoading}
      />
      <RoutewithupdateBusiness
        // <RouteWithSidebar
        exact
        path={Routes.CounterMenu.path}
        component={Counter}
        isAllowed={
          permissions?.administrations?.Store?.counter?.permission?.visible[
            userRole
          ]
        }
        permissionLoading={permissionLoading}
      />
      <RoutewithupdateBusiness
        exact
        path={Routes.StoreMenu.path}
        component={StoreMenu}
        isAllowed={
          permissions?.administrations?.Store?.permission?.visible[userRole]
        }
        permissionLoading={permissionLoading}
      />

      {/* <RouteWithSidebar
        exact
        path={Routes.Support.path}
        component={Support}
        isAllowed={permissions?.support?.permission?.visible[userRole]}
        permissionLoading={permissionLoading}
      />
      <RouteWithSidebar
        exact
        path={Routes.TicketReply.path}
        component={TicketReply}
        isAllowed={permissions?.support?.permission?.visible[userRole]}
        permissionLoading={permissionLoading}
      /> */}
      <RouteWithSidebar
        exact
        path={Routes.Settings.path}
        component={Settings}
        isAllowed={permissions?.settings?.permission?.visible[userRole]}
        permissionLoading={permissionLoading}
      />

      <RouteWithSidebar
        exact
        path={Routes.UserChat.path}
        component={UserChat}
        isAllowed={true}
        permissionLoading={permissionLoading}
      />

      <RouteWithSidebar
        exact
        path={Routes.UserChatGeneral.path}
        component={UserChat}
        isAllowed={true}
        permissionLoading={permissionLoading}
      />

      <RouteWithSidebar
        exact
        path={Routes.Faqs.path}
        component={Faqs}
        isAllowed={true}
        permissionLoading={permissionLoading}
      />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
};
