import React, { useState, useEffect } from "react";
import "rsuite/dist/styles/rsuite-default.css";
import ButtonPrimary from "../../../components/ButtonPrimary";
import { Stack, Typography } from "@mui/material";
import AreaChartComp from "../../../components/charts/AreaChartComp";
import { useDispatch, useSelector } from "react-redux";
import { getBalance, getWalletChartData } from "../../../redux/actions/accountActions";
import {toast} from 'react-toastify'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../../../routes";
import { formatCurrency } from "../../../utils/common";
import PreviewContainer from "../../../components/preview/PreviewContainer";
import Colors from "../../../assets/colors";

const WalletMenu = () => {

  const [data, setData] = useState(null)
  const [balance, setBalance] = useState(0)

  const currency = useSelector(state => state.profileReducer?.user?.merchant?.currency)

  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    dispatch(getWalletChartData((res) => {
      console.log(res,'sdfsfsfs')
      const chartData = createReportChartData(res.data);
      setData(chartData);
    },(err)=> {
      toast.error(err?.response?.data?.message)
    }))

    dispatch(getBalance((res) => {
      console.log('cjsbjdcskdc', res);
      setBalance(res.data.total_balance)

    }, (err) => {
      console.log('oncwnckwelc', err)
      toast.error(err?.response?.data?.message)
    }))
  },[])

  const createReportChartData = (data) => {
    return data.map((item) => ({
      name: item.period.split(" ").slice(1).join(" "),
      value: Number(item.value),
    }));
  };

  return (
    <div className="page-wrapper stb-page-wrapper stb-ph-8">
      <div className="stb-h-100">
        <div className="stb-h-100 bg-white p-4 radi">
          <div className="page-header pb-4 space-between">
            <div className="col-lg-2">
              <h3 className="m-0 page-title">Wallets</h3>
              <p>View your wallets</p>
            </div>

            <div className="gap-4 tools">
              <ButtonPrimary onClick={() => {
                history.push(Routes.ReportTransactions.path)
              }}>Transactions</ButtonPrimary>
              <ButtonPrimary onClick={() => {
                history.push(Routes.Withdrawl.path)
              }}>Withdraw Funds</ButtonPrimary>
            </div>
          </div>
          <Stack>
          
            <Stack height={'70vh'} sx={{backgroundColor: Colors.cardBg, borderRadius: '8px', padding: '1rem'}}>
            <Stack>
              <Typography variant="body">Total Balance</Typography>
              <Typography variant="h4">
               {formatCurrency(
                balance || 0,
                currency.decimal_place,
                currency.symbol,
                currency.symbol_prefix
              )}
              </Typography>
              
            </Stack>
              <AreaChartComp
              data={data}
              bottom={10}
              label="Value"
              height="100%"
              />
            </Stack>
          </Stack>
          <div className="button d-flex align-items-center justify-content-center"></div>
        </div>
      </div>
    </div>
  );
};

export default WalletMenu;
