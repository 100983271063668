import React, { useState, useEffect } from "react";
import {
  Col,
  Button,
  Form,
  Row,
  Card,
  Container,
} from "@themesberg/react-bootstrap";
// import { Badge } from "@themesberg/react-bootstrap";
import { Button as RButton } from "rsuite";
import { useHistory, useParams } from "react-router-dom";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import * as API from "../../../ApiUrl";
// import { transform } from "lodash";
import { useFormik } from "formik";
import Swal from "sweetalert2";
// import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import userprofile from "../../../assets/img/user-profile.png";
import { toast } from "react-toastify";
import back from "../../../assets/img/icons/back.svg";
import { TextField } from "@material-ui/core";
import { textFieldLabelProps } from "../../../utils/common";
import { merchantApi } from "../../../redux/api/apiConfig";

const validationSchema = yup.object({
  message: yup
    .string("Enter your reply message")
    .min(1, "Please enter valid reply")
    .required("Reply Message is required!"),
});

const TicketReply = () => {
  const [loading, setloading] = useState(false);
  const [status_loading, setstatus_loading] = useState(false);
  const params = useParams();
  const history = useHistory();
  const [file, setfile] = useState("");
  const [reply, setReply] = useState([]);
  const [tusers, setTUsers] = useState({});
  const [newMessage, setNewMessage] = useState("");

  // const []
  // useEffect(() => {
  //   changeTicket();
  // }, [postData.status,]);
  const changeTicket = async (changedata) => {
    setstatus_loading(true);
    try {
    
      // { status: tusers.status === "open" ? "close" : "open" },
      const response = await merchantApi.patch(
        `${API.TICKET_STATUS}` + params.id + "/",
        changedata,
        
      );
      console.log("ticket status resposne", response);
      // setTicketValue(await response);
    } catch (error) {
      console.log("response error ", error);
    }
    // setpostData({});
    formik.resetForm();
    setstatus_loading(false);
    getReply();
  };

  let merchant = JSON.parse(localStorage.getItem("user-info"));

  const onSubmit2 = async (data) => {
    // console.log('data', data)
    setloading(true);
    try {
      const response = await merchantApi.post(`${API.TICKET_REPLY}`, data, );
      toast.success("Reply Successful");
      formik.resetForm();
      getReply();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setloading(false);
  };
  const onSubmit = (e) => {
    console.log("hello dude");
    const data = {
      ...e,
      user: merchant.user_id,
      subject: tusers.subject,
      ticket: tusers.id,
      agent: "",
    };

    if (file) {
      var filedata = new FormData();
      filedata.append("attachment", file);
      filedata.append("user", merchant.user_id);
      filedata.append("ticket", tusers.id);
      filedata.append("subject", tusers.subject);
      filedata.append("message", e.message);
      filedata.append("agent", "");

      onSubmit2(filedata);
    } else {
      onSubmit2(data);
    }
    formik.resetForm({});
    console.log(formik.values.message);
    setNewMessage("");
    setfile("");
  };
  // get reply Api Start

  const getReply = async () => {
    try {
    
      const response = await merchantApi.get(
        `${API.GET_SUPPORT}` + params.id + "/",
      );
      setloading(false);
      setReply(await response.data.replies);
      setTUsers(await response.data);

      console.log(response.data);
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    getReply();
  }, []);
  // get reply Api End

  const { id, subject, created_date, status, message } = tusers;
  const statusk = {
    open: { color: "success", label: "Open" },
    closed: { color: "danger", label: "Close" },
  };
  const statusColor = statusk[status] ? statusk[status].color : "danger",
    statusLabel = statusk[status] ? statusk[status].label : "Loading...";

  const formik = useFormik({
    initialValues: {},

    validateOnBlur: true,
    isInitialValid: false,
    validateOnMount: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <div className="page-wrapper mt-3 font-fam bg-white">
      <Col className="ps-4">
        <span onClick={() => history.goBack()} className="back mb-4">
          <img src={back} alt="back" />
          <span>Back</span>
        </span>
      </Col>
      <Container className="bg-white mt-3 pt-3 radi  pb-5 ">
        <Row>
          <Col xl={12}>
            <div className="d-flex align-items-center justify-content-between col-12">
              <h4 className="ticket_title font-fam text-capitalize">
                {subject}
              </h4>
              <RButton
                color={tusers.status === "open" ? "red" : "green"}
                appearance="primary"
                disabled={status_loading}
                onClick={() =>
                  changeTicket({
                    status: tusers.status === "open" ? "closed" : "open",
                  })
                }
                className="font-fam font-12"
              >
                {status_loading
                  ? "Updating..."
                  : tusers.status === "open"
                  ? "Close"
                  : "Re-open Ticket"}
              </RButton>
            </div>
            <div className="mt-2 mb-2">
              <span className="ticket_num text-black font-fam font-11 me-3">
                <span className="font-700">Ticket: </span> STB-{id}
              </span>
              <span className="open_date text-black  font-fam font-11 me-3">
                <span className="font-700"> Opened Date:</span>{" "}
                {moment(created_date).format("LL")}{" "}
              </span>
              <span className="open_date text-black font-fam font-11 me-3">
                <span className="font-700"> Opened Time: </span>
                {moment(created_date).format("hh:mm")}{" "}
              </span>
              <span className="open_date text-black font-fam font-11 me-3">
                <span className="font-700">Status{`  `}</span>
                <span className={`text-${statusColor}`}>● {statusLabel}</span>
              </span>
            </div>
          </Col>
          <Col xl={12} className=" mt-2">
            <div className="mb-2">
              <span className="font-fam fw-700 text-black-50 text-capitalize">
                {" "}
                {merchant.first_name} {merchant.last_name}
              </span>
              <br></br>
              <Card.Text className="font-fam font-16 mt-2 text-capitalize">
                {tusers.message}
              </Card.Text>
            </div>
          </Col>
          <Form onSubmit={formik.handleSubmit}>
            <Col xl={12}>
              <div className="form-floating mb-2 ">
                <TextField
                  multiline
                  minRows={4}
                  maxRows={Infinity}
                  fullWidth
                  variant="outlined"
                  className="mb-3 bg-white"
                  id="message"
                  name="message"
                  InputLabelProps={textFieldLabelProps}
                  type="text"
                  label="Message"
                  value={newMessage}
                  onChange={(e) => {
                    setNewMessage(e.target.value);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                />
                <span className="text-red">
                  {formik.touched.message && Boolean(formik.errors.message)}
                </span>
                <span className="text-red">
                  {formik.touched.message && formik.errors.message}
                </span>
              </div>
            </Col>

            <Button
              appearance="default"
              type="submit"
              disabled={file !== "" ? false : !formik.isValid ? true : false}
              className="btn load text-white d-flex flex-row gap-2 "
            >
              {loading && <CircularProgress size={14} />}
              {loading ? "Loading" : "Post Reply"}
            </Button>

            <input
              type="file"
              onChange={(e) => setfile(e.target.files[0])}
              accept="image/*, application/pdf "
              // value={file}
              className="mt-3"
            ></input>
          </Form>

          <h3 className="heading text-center mb-2">Ticket Replies</h3>
          {reply.length > 0 ? (
            <div class="messaging">
              <div class="inbox_msg">
                <div class="mesgs">
                  <div class="msg_history">
                    {[...reply].map((curElem) => {
                      const { id, message, created_date, agent } = curElem;
                      if (agent) {
                        return (
                          <div class="incoming_msg" key={id}>
                            <div class="incoming_msg_img">
                              {" "}
                              <img src={userprofile} alt="sunil" />{" "}
                            </div>
                            <div class="received_msg">
                              <span className="font-fam font-11">{agent}</span>
                              <div class="received_withd_msg">
                                <p>{message}</p>
                                <span class="time_date">
                                  {" "}
                                  {moment(created_date).format(
                                    "hh:mm a"
                                  )} | {moment(created_date).format("LL")}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div class="outgoing_msg " key={id}>
                            <div class="sent_msg ">
                              <p>{message}</p>
                              <span class="time_date">
                                {moment(created_date).format("hh:mm a")} |{" "}
                                {moment(created_date).format("LL")}
                              </span>{" "}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h6 style={{ textAlign: "center" }}>No Replies yet</h6>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default TicketReply;

{
  /* <textarea
                style={{ fontWeight: '400', color: 'black', width:'100%' }}
                className="light_bg h-100p "
                id="message"
                name="message"
                value={newMessage}
                onChange={(e) => {
                  console.log(e.target.value);
                  setNewMessage(e.target.value);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                placeholder="Reply"
              ></textarea> */
}
