import { Skeleton, Stack } from '@mui/material';
import React from 'react';

const ChatConversationItemSkeleton = ({ titleWidth = '30%', descriptionWidth = '85%' }) => {
  return (
    <Stack alignItems={'flex-start'} direction={'row'} sx={{ padding: 1.5, gap: 1 }}>
      <Skeleton animation={'wave'} variant='circular' width={60} height={60} />
      <Stack sx={{ flex: '1' }}>
        <Skeleton animation={'wave'} variant='text' sx={{ fontSize: '1rem', width: titleWidth }} />
        <Skeleton animation={'wave'} variant='text' sx={{ fontSize: '1rem', width: descriptionWidth }} />
      </Stack>
    </Stack>
  );
};

export default ChatConversationItemSkeleton;
