import React, { useEffect, useState } from "react";
import { Stack, IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import "rsuite/dist/styles/rsuite-default.css";
import { toast } from "react-toastify";
import ButtonPrimary from "../../../components/ButtonPrimary";
import AddWithdrawAccountModal from "../Components/AddWithdrawAccountModal";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  deleteInteracAccount,
  getInteracAccount,
  verifyInteracAccountForWithdrawOtp,
  verifyInteracAccountOtp,
} from "../../../redux/actions/accountActions";
import WithdrawAccountTable from "./WithdrawAccountTable";
import AddWithdrawAccountSuccessModal from "../Components/AddWithdrawAccountSuccessModal";
import DeleteWarningModal from "../../../components/warning/DeleteWarningModal";
import OtpModal from "../../../components/otpModal/OtpModal";

const WithdrawAccounts = ({ userRole, isSingleWallet, permissions }) => {
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showVerifyOtpModal, setShowVerifyOtpModal]= useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [addAccountRes, setAddAccountRes] = useState(null);
  const [paginateTable, setpaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
  });
  const [fetchAccountsLoading, setFetchAccountsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [withdrawVerifyId, setWithdrawVerifyId] = useState(null);

  const dispatch = useDispatch();

  const onAddAccount = (data) => {
    setAddAccountRes(data);
    toggleAddAccountModal();
    toggleOtpModal();
  };

  const getInteracAccounts = () => {
    setFetchAccountsLoading(true);
    const params = {
      limit: paginateTable.pageSize,
      offset: paginateTable.currentPage * paginateTable.pageSize,
    };
    dispatch(
      getInteracAccount(
        params,
        (res) => {
          console.log("cwkcbhr", res);
          setAccounts(res.results);
          setFetchAccountsLoading(false);
        },
        (err) => {
          console.log("cwecwc3rc34", err);
          setFetchAccountsLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    getInteracAccounts();
  }, [paginateTable.currentPage, paginateTable.pageSize, dispatch]);

  const toggleAddAccountModal = () => {
    setShowAddAccountModal(!showAddAccountModal);
  };

  const toggleOtpModal = () => {
    setShowOtpModal(prev => !prev);
  };

  const toggleWithdrawVerifyOtpModal = (id) => {
    if(id){
      setWithdrawVerifyId(id)
    }
    setShowVerifyOtpModal(prev => !prev);
  };

  const toggleSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
  };

  const onDeleteClick = (id) => {
    setAccountToDelete(id);
    setShowDeleteModal(true);
  };

  const deleteAccount = () => {
    setDeleteLoading(true);
    dispatch(
      deleteInteracAccount(
        accountToDelete,
        (res) => {
          getInteracAccounts();
          setShowDeleteModal(false);
          toast.success(res.message);
          setDeleteLoading(false);
        },
        (err) => {
          toast.error(err?.response?.data?.message);
          setDeleteLoading(false);
        }
      )
    );
  };

  const onOtpSubmit = (otp) => {
    setOtpLoading(true)
     const requestData = {
      otp,
    };
    dispatch(
      verifyInteracAccountOtp(
        addAccountRes?.id,
        requestData,
        (res) => {
          getInteracAccounts();
          toast.success(res.message);
          setOtpLoading(false);

          toggleOtpModal();
          toggleSuccessModal();
        },
        (err) => {
          setOtpLoading(false);
          toast.error(err?.response?.data?.message);
        }
      )
    );
  }

  const onWithdrawVerifyOtpSubmit = (otp) => {
    setOtpLoading(true)
     const requestData = {
      otp,
    };
    dispatch(
      verifyInteracAccountForWithdrawOtp(
        withdrawVerifyId,
        requestData,
        (res) => {
          getInteracAccounts();
          toast.success(res?.message);
          setOtpLoading(false);
          toggleWithdrawVerifyOtpModal();
        },
        (err) => {
          setOtpLoading(false);
          toast.error(err?.response?.data?.message);
        }
      )
    );
  }

  

  return (
    <>
      {/* {loading && <Loader backdrop content="loading..." vertical />} */}
      <div className="page-wrapper stb-page-wrapper stb-ph-8">
        <div className="stb-h-100">
          <div className="reports stb-h-100 bg-white p-xl-4 radi">
            <div className="d-flex flex-col align-items-start gap-4">
              <div className="page-header space-between w-100">
                <div>
                  <h2 className="m-0 page-title">Withdrawal Accounts</h2>
                </div>
                {permissions?.merchantProfile?.withdrawal?.permission
                  ?.canWithdraw?.[userRole] ? (
                  <Stack gap={2} direction={"row"}>
                    <ButtonPrimary onClick={() => toggleAddAccountModal()}>
                      Add New Account
                    </ButtonPrimary>
                  </Stack>
                ) : (
                  ""
                )}
              </div>
              <Stack sx={{ width: "100%" }}>
                <WithdrawAccountTable
                  accounts={accounts}
                  paginateTable={paginateTable}
                  setpaginateTable={setpaginateTable}
                  fetchAccountsLoading={fetchAccountsLoading}
                  getInteracAccounts={getInteracAccounts}
                  onDeleteClick={onDeleteClick}
                  toggleWithdrawVerifyOtpModal={toggleWithdrawVerifyOtpModal}
                />
              </Stack>
              {showAddAccountModal && (
                <AddWithdrawAccountModal
                  isOpen={showAddAccountModal}
                  onClose={toggleAddAccountModal}
                  onAddAccount={onAddAccount}
                />
              )}
              {showOtpModal && (
                <OtpModal
                  isOpen={showOtpModal}
                  onClose={toggleOtpModal}
                  onOtpSubmit={onOtpSubmit}
                  loading={otpLoading}
                  title="Enter OTP"
                  subTitle="Please enter OTP"
                  bottomMessage={ <Stack direction={"row"} alignItems={"center"}>
                  <IconButton>
                    <AccessTimeIcon color="action" fontSize="medium" />
                  </IconButton>
                  <Typography variant="caption" sx={{ lineHeight: "110%" }}>
                    We have sent you and all the admin users of the portal an OTP to
                    their respective email address. Please check the inbox and enter
                    OTP.
                  </Typography>
                </Stack>}
                />
              )}

              {showSuccessModal && (
                <AddWithdrawAccountSuccessModal
                  isOpen={showSuccessModal}
                  onClose={toggleSuccessModal}
                  addAccountRes={addAccountRes}
                />
              )}

              {showDeleteModal && (
                <DeleteWarningModal
                  isOpen={showDeleteModal}
                  onClose={() => setShowDeleteModal(false)}
                  onDelete={() => deleteAccount()}
                  loading={deleteLoading}
                />
              )}

{showVerifyOtpModal && (
                <OtpModal
                  isOpen={showVerifyOtpModal}
                  onClose={toggleWithdrawVerifyOtpModal}
                  onOtpSubmit={onWithdrawVerifyOtpSubmit}
                  loading={otpLoading}
                  title="Enter OTP"
                  subTitle="Please enter OTP"
                  bottomMessage={ <Stack direction={"row"} alignItems={"center"}>
                  <IconButton>
                    <AccessTimeIcon color="action" fontSize="medium" />
                  </IconButton>
                  <Typography variant="caption" sx={{ lineHeight: "110%" }}>
                     Please check the inbox and enter OTP.
                  </Typography>
                </Stack>}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps({ authReducer }) {
  const { userRole, isSingleWallet, permissions } = authReducer;
  return {
    userRole,
    isSingleWallet,
    permissions,
  };
}

export default connect(mapStateToProps, null)(WithdrawAccounts);

// if(coin?.length !== 3) {
//   coin =  Buffer.from(coin, 'utf-8').toString('hex');
//   coin = coin.padEnd(40, "0");
// }
