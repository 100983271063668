import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import ButtonPrimary from "../ButtonPrimary";
import ButtonSecondary from "../ButtonSecondary";
import { handleBackgroundClick, statusColors } from "../../utils/common";

const DeleteWarningModal = ({
  isOpen,
  onClose,
  onDelete,
  loading
}) => {

  const handleClose = (event, reaon) => {
    handleBackgroundClick(reaon, onClose, false);
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={"xs"}
      onClose={handleClose}
      scroll={"paper"}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        Are you sure?
      </DialogTitle>
      <DialogContent
        sx={{ paddingTop: "5px !important", justifyContent: "center" }}
      >
      <Typography variant="subtitle2">
        Are you sure you want to delete this account?
      </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          paddingX: "24px",
          paddingBottom: "15px",
          gap:1
        }}
      >
         <ButtonSecondary onClick={onClose}>
          Cancel
        </ButtonSecondary>
        <ButtonPrimary onClick={onDelete} loading={loading} style={{backgroundColor: statusColors.bgColors.rejected, color: statusColors.colors.rejected}}>
          Delete
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};

function mapStateToProps({ authReducer }) {
  const { userPayString } = authReducer;
  return {
    userPayString,
  };
}

export default connect(mapStateToProps, null)(DeleteWarningModal);
