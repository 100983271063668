import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";

import { Dialog, Stack, Box, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PreviewContainer from "../../../../../components/preview/PreviewContainer";
import { formatCurrency } from "../../../../../utils/common";
import ButtonSecondary from "../../../../../components/ButtonSecondary";
import ButtonPrimary from "../../../../../components/ButtonPrimary";
import { createRefund } from "../../../../../redux/actions/transactionActions";
import {toast} from 'react-toastify'

function RefundDialog({
  open,
  handleSendRefund,
  onClose: handleClose,
  title,
  transactionData,
  userCurrency,
}) {

  const dispatch = useDispatch()

  const [data, setdata] = useState({
    amountCAD:  transactionData?.receive_amount,
  });

  const [loading, setLoading] = useState(false)

  const validationSchema = yup.object({
    amountCAD: yup
      .number(`Enter your amount.`)
      .moreThan(
        0,
        `Amount must be greater than 0`
      )
      .max(
        Number(transactionData?.receive_amount),
        'Refund amount cannot be more than transaction amount'
      )

      .required(`Amount is required`)
  });

  const onSubmit = async (values) => {
    setLoading(true)
    const finalData = {
      type: "refund",
      related_transaction: transactionData?.id,
      amount: values.amountCAD,
      sender_coin: transactionData?.receiver_coin.id,
      receiver_coin: transactionData?.receiver_coin.id,
    };

    dispatch(createRefund(finalData,(res) => {
      handleClose();
      setLoading(false)
      handleSendRefund(res.data)
    }, (err) => {
      setLoading(false)
      console.log('asdasdasd23d2', err)
      toast.error(err?.response?.data?.message || 'Refund failed, please try again')
    }))
  };

  const handleChange = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };
  const formik = useFormik({
    initialValues: data,
    validateOnBlur: true,
    onSubmit,
    handleChange,
    validationSchema: validationSchema,
  });

  const currencyValueChangeHandler = (e) => {
    const currencyValue = e.target.value;
    formik.setValues({
      ...formik.values,
      amountCAD: currencyValue,
    });
    console.log(formik.values);
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth={"xs"}
        onClose={handleClose}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
            width: "628px",
            minHeight: "450px",
            maxHeight: "90vh",
          },
        }}
      >
        <DialogTitle className="text-center">
          <p className="dialog-title">{title}</p>
        </DialogTitle>

        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Stack direction={"column"}>
              <TextField
                className="w-100 mb-3"
                name="amountCAD"
                id="amountCAD"
                label={`Amount in ${userCurrency.symbol}`}
                type="text"
                variant="outlined"
                value={formik.values.amountCAD}
                onChange={currencyValueChangeHandler}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.amountCAD && Boolean(formik.errors.amountCAD)
                }
                helperText={formik.touched.amountCAD && formik.errors.amountCAD}
              />
              <PreviewContainer>
                <Stack gap={2}>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle2">
                      Total Transaction Amount
                    </Typography>
                    <Typography variant="subtitle2">
                      {formatCurrency(
                        transactionData?.receive_amount,
                        transactionData?.receiver_coin?.decimal_place,
                        transactionData?.receiver_coin?.symbol,
                        transactionData?.receiver_coin?.symbol_prefix
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle2">Refund Amount</Typography>
                    <Typography variant="subtitle2">
                      {formatCurrency(
                        formik.values.amountCAD,
                        transactionData?.receiver_coin?.decimal_place,
                        transactionData?.receiver_coin?.symbol,
                        transactionData?.receiver_coin?.symbol_prefix
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle2">Processing Fee</Typography>
                    <Typography variant="subtitle2">
                      {formatCurrency(
                        0,
                        transactionData?.receiver_coin?.decimal_place,
                        transactionData?.receiver_coin?.symbol,
                        transactionData?.receiver_coin?.symbol_prefix
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle2">STB Fee</Typography>
                    <Typography variant="subtitle2">
                      {formatCurrency(
                        0,
                        transactionData?.receiver_coin?.decimal_place,
                        transactionData?.receiver_coin?.symbol,
                        transactionData?.receiver_coin?.symbol_prefix
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      Total Refund Amount
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      {formatCurrency(
                        formik.values.amountCAD,
                        transactionData?.receiver_coin?.decimal_place,
                        transactionData?.receiver_coin?.symbol,
                        transactionData?.receiver_coin?.symbol_prefix
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              </PreviewContainer>
            </Stack>
            <Stack mt={4}>
              <Typography variant="subtitle2">
              Clicking Refund will initiate a refund and transfer the refunded amount to the customer’s source of payment.
              </Typography>
            </Stack>
          </DialogContent>
            <DialogActions>
              <ButtonSecondary
                onClick={handleClose}
              >
                Cancel
              </ButtonSecondary>
              <ButtonPrimary
              type="submit"
                loading={loading}
              >
                {!loading ? "Refund" : "Loading..."}
              </ButtonPrimary>
            </DialogActions>
          
        </form>
      </Dialog>
    </>
  );
}

export default RefundDialog;
