
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import forg from '../../assets/img/forgotpassword.gif';
import { Routes } from "../../routes";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import * as API from "../../ApiUrl";
import axios from "axios";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "rsuite";



const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export default () => {
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      const response = await axios.post(API.FORGOT_PASSWORD, values);

      const token = await response.data;
      localStorage.setItem("user-info", JSON.stringify(token));
      localStorage.setItem("token", token.token);

      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: "Email send successfully",
      });
      history.push("/signin");
      console.log(response);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Something went wrong');
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-white d-flex align-items-center">
        <Container>
          <Row className="justify-content-center d-flex align-items-center">

            <Col xl={6} >
              <img src={forg} />
            </Col>
            <Col xl={6} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white  border rounded border-light p-4 p-lg-5 w-100 ">
                <h3 className="font-fam">Forgot your password?</h3>
                <p className="mb-4 mt-4 font-fam">Don't fret! Just type in your email and we will send you a link to reset your password!</p>
                {loading ? (
                  <Loader backdrop content="loading..." />
                ) : null}
                <Form onSubmit={formik.handleSubmit}>

                  <TextField
                    fullWidth
                    variant="outlined"
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />



                  <Button
                    variant="primary"
                    onClick={() => {
                      setIsButtonLoading(true);
                      setTimeout(() => {
                        setIsButtonLoading(false);
                      }, 2000);
                    }}
                    isLoading={isButtonLoading}
                    disabled={!formik.isValid}
                    className="mt-4 w-100"
                    type="submit"
                  >
                    Recover password
                  </Button>
                </Form>
                <p className="text-center mt-3">
                  <Card.Link as={Link} to={Routes.Signin.path} className="font-fam text-gray-700">
                    <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
                  </Card.Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
