import React, { useEffect, useState, useRef } from "react";
import { Button } from "rsuite";
import { Col, Row } from "@themesberg/react-bootstrap";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import _ from "lodash";

import { merchantApi } from "../../redux/api/apiConfig";
import * as API from "../../ApiUrl";
import copy_white from "../../assets/img/icons/copy_white.svg";
import axios from "axios";

import { formatCurrency } from "../../utils/common";
import PaymentModal from "../PaymentModal";
import { async } from "validate.js";
import { useHistory } from "react-router-dom";
import MessageModal from "../MessageModal";

const MerchantWalletAddress = (props) => {
  const {
    open,
    handleClose,
    userCurrency,
    togglePayModal,
    toggleMessageModal,
  } = props;

  const [isLoading, setisLoading] = useState(false);
  const [walletDetails, setWalletDetails] = useState(null);
  const [XRPrate, setXRPrate] = useState(0);
  const [checkIfFundedLoading, setCheckIfFundedLoading] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  // const [walletList, setWalletList]
  const walletAddressRef = useRef(null);

  const history = useHistory();

  const data = JSON.parse(localStorage.getItem("accountDetail"));
  useEffect(() => {
    getWalletDetails();
    if (data && Object.keys(data).length > 0) {
      setWalletDetails(data);
    }
    getCoinType();
  }, []);

  let token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const getWalletDetails = async () => {
    try {
      const response = await axios.get(
        API.GET_MERCHENT_WALLET_LIST + "/",
        config
      );
      const wallet = response.data.results.filter(
        (wallet) => wallet.coin.symbol === "XRP"
      )[0];
      if (!walletDetails && wallet) {
        setWalletDetails(wallet);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };
  const handleDepositedClicked = async () => {
    setCheckIfFundedLoading(true);
    try {
      const response = await axios.get(API.GET_MERCHENT_WALLET_LIST, config);
      const isFunded = response.data.results.filter(
        (coin) => coin.coin.symbol === "XRP"
      )[0].is_funded;
      if (isFunded) {
        setCheckIfFundedLoading(false);
        handleClose();
        history.push("/merchantprofile/wallets");
      } else {
        setCheckIfFundedLoading(false);
        toast.info(
          "We do not seem to have received your funds. You can try with another option or wait for a few seconds and click again."
        );
      }
    } catch (error) {
      toast.error(error.response.data.message || "Something went wrong");
      setCheckIfFundedLoading(false);
    }
  };

  const activateLaterClicked = () => {
    toggleMessageModal();
    handleClose();
  };

  const getCoinType = async () => {
    try {
      const response = await axios.get(`${API.COIN}`);
      const XRPrate = response.data.data.find(
        (coin) => coin.symbol === "XRP"
      ).rate;
      setXRPrate(XRPrate);
    } catch (error) {}
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(walletDetails.address);
    toast.success("Address copied successfully!");
  };

  return (
    <>
      <Dialog
        open={open}
        disableEscapeKeyDown={true}
        // onClose={handleClose}
        // onBackdropClick="false"
        className="wallet-configure"
        maxWidth="md"
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
            width: "560px",
            // minHeight: '824px',
            maxHeight: "80vh",
          },
        }}
      >
        <DialogTitle className="text-center">
          <p className="dialog-title">Congratulations, we're all set!</p>
        </DialogTitle>
        {isLoading || walletDetails == null ? (
          <DialogContent className="mt-3 merchant-wallet wallet-modal">
            <p className="dialog-title">Loading...</p>
          </DialogContent>
        ) : (
          <DialogContent className="mt-3 merchant-wallet wallet-modal">
            <p className="instructions">
              <p className="heading">
                Welcome to a journey of next-gen transactions. You are all set
                to go.
              </p>

              <div className="instruction flex-row">
                <span className="circle">1</span>
                <span>
                  Please note down your XRPL address which stores all your
                  funds. This is not a secret and can be re accessed via the
                  Profile section in accounts.
                </span>
              </div>
              <br />
              <div className="instruction flex-row">
                <span className="circle">2</span>
                <span>
                  To activate your wallet, you need to deposit a minimum of
                  10XRP to this XRPL address. To know why this is needed click{" "}
                  <a
                    href="https://xrpl.org/reserves.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </span>
              </div>
              <br />
              <div className="instruction flex-row">
                <span className="circle">3</span>
                <span>
                  For your convenience, STB also provides an option to fund your
                  wallet from SpendTheBits for an easy start. You need to make a
                  payment worth 10XRP for us to fund the XRP and activate Your
                  wallet.
                </span>
              </div>
            </p>
            <div className="merchant-wallet-key">
              <QRCode
                size={180}
                value={walletDetails.address}
                className="mt-3 mb-4 center"
              />
            </div>
            <div className="merchant-wallet-key">
              <p ref={walletAddressRef} className="secretkey">
                {walletDetails.address}
              </p>
              <Button
                className="primary_color text-white copy-btn"
                onClick={copyToClipboard}
              >
                <img src={copy_white} alt="copy" />
                Copy
              </Button>
            </div>
            {/* <hr  style={{marginBottom:'16px !important'}} className="hr" /> */}
            <DialogActions className="footer-content">
              <Button
                className="primary_color text-white w-100"
                disabled={true}
                onClick={() => {
                  togglePayModal();
                  handleClose();
                }}
              >
                <span className="wallet-activate">
                  Pay{" "}
                  {formatCurrency(
                    XRPrate * 10,
                    userCurrency.decimalPlace,
                    userCurrency.symbol,
                    userCurrency.symbolPrefix
                  )}{" "}
                  (Equivalent to 10XRP) and activate my wallet
                </span>
                <span className="wallet-activate-alt">Activate my wallet</span>
              </Button>
            </DialogActions>
            <DialogActions className="footer-content">
              <Button
                className="primary_color text-white basis-70"
                disabled={checkIfFundedLoading}
                loading={checkIfFundedLoading}
                // onClick={handleClose}
                onClick={handleDepositedClicked}
              >
                Already activated
              </Button>

              <Button
                className="primary_color text-white basis-30"
                onClick={activateLaterClicked}
              >
                Later
              </Button>
            </DialogActions>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default MerchantWalletAddress;
