import * as actionTypes from "../actionTypes";

export const openModal = (nextPath) => {
  return {
    type: actionTypes.OPEN_MODAL,
    payload: nextPath,
  };
};

export const closeModal = () => {
  return {
    type: actionTypes.CLOSE_MODAL,
  };
};
