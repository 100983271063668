import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Skeleton,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import ButtonPrimary from "../../../components/ButtonPrimary";
import ButtonSecondary from "../../../components/ButtonSecondary";
import * as yup from "yup";
import { merchantApi } from "../../../redux/api/apiConfig";
import { useHistory } from "react-router-dom";
import {
  GET_MERCHENT_WALLET_LIST,
  TRANS_STORE,
  WITHDRAW,
  WITHDRAW_FEE,
} from "../../../ApiUrl";
import { useFormik } from "formik";
import {
  formatCurrency,
  handleBackgroundClick,
  textFieldLabelProps,
} from "../../../utils/common";
import InteracDetailsCard from "../../../components/cards/InteracDetailsCard";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { getInteracAccount } from "../../../redux/actions/accountActions";
import { Routes } from "../../../routes";
import Empty from "../../../assets/img/empty.svg";
import Image from "../../../components/image";

const WithdrawModal = ({
  isOpen,
  onClose,
  getTrans,
  onSuccess
}) => {
  const [walletList, setWalletList] = useState([]);
  const [interacLoading, setInteracLoading] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [selectedDepositType, setSelectedDepositType] = useState(null);
  const [fees, setFees] = useState({ handler_fee: 0, stb_fee: 0 });
  const [walletFetchingLoading, setWalletFetchingLoading] = useState(true);
  const [step, setStep] = useState(3);
  const [interacAccounts, setInteracAccounts] = useState([]);
  const [fetchingInteracAccount, setFetchingInteracAccount] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    getWalletMenu();
  }, []);

  useEffect(() => {
    if (!walletFetchingLoading && walletList.length === 1) {
      handleSelectWallet(walletList[0]);
    }
  }, [walletFetchingLoading, walletList]);

  const getWalletMenu = async () => {
    setWalletFetchingLoading(true);
    try {
      const response = await merchantApi.get(
        `${GET_MERCHENT_WALLET_LIST}?limit=100`
      );
      setWalletFetchingLoading(false);
      if (response.data) {
        console.log(response.data);
        setWalletList(response.data.results);
      }
    } catch (error) {}
  };

  const interacValidationSchema = yup.object({
    amount: yup.number("Enter your amount").required("Amount is required"),
    account: yup
      .string("Select Interac account")
      .required("Interac account is required"),
    description: yup
      .string("Enter description")
      .min(5, "Too Short")
      .max(300, "Too Long "),
  });

  const handleSelectWallet = (wallet) => {
    setSelectedWallet(wallet);
    if (wallet?.coin?.is_withdraw_available) {
      if (wallet?.coin?.withdraw_options?.length === 0) {
        toast.error("No withdraw methods available");
      } else if (wallet?.coin?.withdraw_options?.length === 1) {
        handleSelectDepositType(wallet?.coin?.withdraw_options[0], wallet);
      } else {
        console.log("wssss", wallet);
        setStep(3);
      }
    } else {
      toast.error(wallet?.coin?.withdraw_error_message);
    }
  };

  const handleSelectDepositType = (item, wallet = selectedWallet) => {
    setSelectedDepositType(item);
    if (item?.withdraw_type?.key === "interac") {
      setFetchingInteracAccount(true);
      dispatch(
        getInteracAccount(
          { limit: 100, offset: 0 },
          (res) => {
            setInteracAccounts(res?.results?.filter((ac) => ac.is_approved_for_withdrawal));
            setFetchingInteracAccount(false);
          },
          (err) => {
            toast.error(
              err?.response?.data?.message || "Failed to fetch interac accounts"
            );
            setFetchingInteracAccount(false);
          }
        )
      );
      setStep("interac");
    } else {
      setStep(1);
      // setStep("interac");
    }
  };

  const interacSubmit = async (values) => {
    if (
      Number(interacFormik.values.amount) +
        Number(fees.handler_fee) +
        Number(fees.stb_fee) >
      Number(selectedWallet?.balance)
    ) {
      toast.error("Insufficient balance");
      return;
    }
    const requestData = {
      amount: Number(interacFormik.values.amount),
      stb_fee: fees?.stb_fee,
      handler_fee: fees?.handler_fee,
      coin: selectedWallet?.coin?.id,
      withdraw_option: selectedDepositType?.id,
      description: interacFormik.values.description,
      interac_customer_account: interacFormik.values.account,
    };
    try {
      setInteracLoading(true);
      const response = await merchantApi.post(
        `${WITHDRAW}${selectedDepositType?.withdraw_type?.key}/`,
        requestData
      );

      toast.success(response?.data?.message || "Withdraw request sent!");
      setInteracLoading(false);
      console.log('wexwsxacsc', response, 'dssdcscsdc',response?.data)
      onSuccess(response?.data?.data);
    } catch (error) {
      setInteracLoading(false);
      toast.error(error.data.data.message || "Request failed, try again later");
    }
  };

  const interacFormik = useFormik({
    initialValues: {
      account: "",
      amount: "",
      description: "",
    },
    validateOnBlur: true,
    validationSchema: interacValidationSchema,
    isInitialValid: false,
    validateOnMount: true,
    onSubmit: interacSubmit,
  });

  const getFees = useCallback(
    async (amount, signal) => {
      try {
        const response = await merchantApi.post(
          WITHDRAW_FEE,
          {
            coin: selectedWallet?.coin?.id,
            amount: Number(amount),
          },
          { signal }
        );
        setFees({ ...response?.data?.data });
      } catch (error) {
        console.log("errrr", error, axios.isCancel(error));
        if (axios.isCancel(error)) {
          return;
        } else {
          toast.error(
            error?.data?.data?.message ||
              "Could not retrieve fees, try again later"
          );
        }
      }
    },
    [selectedWallet]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (interacFormik?.values?.amount > 0) {
      getFees(interacFormik?.values?.amount, signal);
    } else {
    }
    return () => {
      controller.abort();
    };
  }, [interacFormik.values.amount, getFees]);


  let content = "";
  let title = "";
  if (walletFetchingLoading) {
    title = new Array(5).fill(0).map((item, index) => {
      return <Skeleton variant="rounded" width={"100%"} height={80} />;
    });
  } else {
    switch (step) {
      case 1: {
        title = (
          <Stack direction={"column"}>
            Select Withdraw Method
            <Typography variant="caption">
              for {selectedWallet?.coin?.name}
            </Typography>
          </Stack>
        );
        console.log("slctdwlt", selectedWallet);
        content = (
          <>
            <DialogContent sx={{ paddingTop: "5px !important" }}>
              <Stack spacing={2} direction={"column"} alignItems={"center"}>
                <>
                  {selectedWallet?.coin?.withdraw_options?.map((item) => (
                    <Stack
                      className="method-card"
                      direction={"row"}
                      gap={"1rem"}
                      onClick={() => handleSelectDepositType(item)}
                      key={item?.id}
                    >
                      <Stack>
                        <img
                          src={item?.logo}
                          alt="deposit type"
                          style={{
                            width: "80px",
                            height: "50px",
                            objectFit: "contain",
                          }}
                        />
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle1" className="title">
                          {item?.name}
                        </Typography>
                        <Typography variant="caption" className="desc">
                          {item?.description}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
                </>
              </Stack>
            </DialogContent>
            <DialogActions
              sx={{
                paddingX: "24px",
                paddingBottom: "15px",
                gap: 1
              }}
            >
              <ButtonSecondary
                onClick={() => {
                  setStep((step) => step - 1);
                }}
              >
                Back
              </ButtonSecondary>
            </DialogActions>
          </>
        );
        break;
      }

      case "interac": {
        title = (
          <Stack direction={"column"}>
            Withdraw Funds
            <Typography variant="caption">via Interac e-Transfer®</Typography>
          </Stack>
        );
        content =
        (!fetchingInteracAccount && interacAccounts?.length === 0) ? (
          <>
          <DialogContent sx={{ paddingTop: "5px !important" }}>
            <Typography variant="h6">
              No active withdraw account found
            </Typography>
            <Image src={Empty} alt="Empty" />

            <Typography variant="subtitle1" sx={{ color: "#d32f2f" }}>
              Add or activate existing account to withdraw funds
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
              gap: 1
            }}
          >
             <ButtonSecondary
              disabled={interacLoading}
              onClick={onClose}
            >
              Back
            </ButtonSecondary>
            <ButtonPrimary
              onClick={() => history.push(Routes.WithdrawAccounts.path)}
            >
              Go to withdraw accounts
            </ButtonPrimary>
          </DialogActions>
        </>         
          ) : (
            <form onSubmit={interacFormik.handleSubmit}>
              <DialogContent sx={{ paddingTop: "5px !important" }}>
                <Stack spacing={2} direction={"column"} alignItems={"center"}>
                  <Stack spacing={1} direction={"column"} alignItems={"center"}>
                    <TextField
                      select
                      required
                      variant="outlined"
                      className="w-100 mb-3"
                      id="account"
                      name="account"
                      InputLabelProps={textFieldLabelProps}
                      label="Interac Account"
                      value={interacFormik.values.account}
                      onChange={interacFormik.handleChange}
                      onBlur={interacFormik.handleBlur}
                      error={
                        interacFormik.touched.account &&
                        Boolean(interacFormik.errors.account)
                      }
                      helperText={
                        interacFormik.touched.account &&
                        interacFormik.errors.account
                      }
                    >
                      {interacAccounts.map((ac) => (
                        <MenuItem value={ac.id}>{ac.interac_email}</MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      className="w-100 mb-3"
                      required
                      variant="outlined"
                      name="amount"
                      type="number"
                      id="amount"
                      InputLabelProps={textFieldLabelProps}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {selectedWallet?.coin?.symbol}
                          </InputAdornment>
                        ),
                      }}
                      label="Withdraw Amount"
                      value={interacFormik.values.amount}
                      onChange={(e) => {
                        // getFees(e.target.value)
                        interacFormik.handleChange(e);
                      }}
                      onBlur={interacFormik.handleBlur}
                      error={
                        interacFormik.touched.amount &&
                        Boolean(interacFormik.errors.amount)
                      }
                      helperText={
                        interacFormik.touched.amount &&
                        interacFormik.errors.amount
                      }
                    />
                    <InteracDetailsCard
                      balance={selectedWallet?.balance}
                      wallet={selectedWallet}
                      // rate={`1 ${selectedWallet?.coin?.symbol} =${selectedWallet?.coin?.rate} ${selectedWallet?.currency?.code}`}
                      // network={selectedBlockchainNetwork.name}
                      amount={interacFormik.values.amount}
                      stbFee={fees?.stb_fee}
                      handlerFee={fees?.handler_fee}
                    />
                    <TextField
                      className="w-100 mb-3"
                      multiline
                      rows={4}
                      variant="outlined"
                      name="description"
                      id="description"
                      InputLabelProps={textFieldLabelProps}
                      label="Description"
                      type="text"
                      value={interacFormik.values.description}
                      onChange={interacFormik.handleChange}
                      onBlur={interacFormik.handleBlur}
                      error={
                        interacFormik.touched.description &&
                        Boolean(interacFormik.errors.description)
                      }
                      helperText={
                        interacFormik.touched.description &&
                        interacFormik.errors.description
                      }
                    />
                    <Stack direction={"row"} alignItems={"center"}>
                      <IconButton>
                        <WarningAmberIcon color="error" fontSize="medium" />
                      </IconButton>
                      <Typography
                        variant="caption"
                        sx={{ color: "#d32f2f", lineHeight: "110%" }}
                      >
                        INTERAC e-Transfer requests must be sent from a bank
                        account in the same name as your STB account. Funds sent
                        from a bank account under any other name will be held
                        pending ID verification.
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      style={{ marginTop: "1rem" }}
                    >
                      <IconButton>
                        <AccessTimeIcon color="action" fontSize="medium" />
                      </IconButton>
                      <Typography
                        variant="caption"
                        style={{ lineHeight: "110%" }}
                      >
                        If funds don't go through after an hour of accepting the
                        transfer, please call your bank for further inquiries
                        before contacting support.
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </DialogContent>
              <DialogActions
                sx={{
                  paddingX: "24px",
                  paddingBottom: "15px",
                  gap: 1
                }}
              >
                <ButtonSecondary
                  disabled={interacLoading}
                  onClick={() => {
                    interacFormik.resetForm();
                    if (selectedWallet?.coin?.withdraw_options?.length === 1) {
                      if (walletList?.length === 1) {
                        onClose();
                      } else {
                        setStep(2);
                      }
                    } else {
                      setStep(3);
                    }
                  }}
                >
                  Back
                </ButtonSecondary>
                <ButtonPrimary type={"submit"} loading={interacLoading}>
                  Withdraw
                </ButtonPrimary>
              </DialogActions>
            </form>
          );
        break;
      }

      default: {
        content = "";
      }
    }
  }

  const handleClose = (event, reaon) => {
    handleBackgroundClick(reaon, onClose, false);
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={"xs"}
      onClose={handleClose}
      scroll={"paper"}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "" }}>{title}</DialogTitle>
      {/* <Typography variant="subtitle1"></Typography> */}
      {content}
    </Dialog>
  );
};

function mapStateToProps({ authReducer }) {
  const { userPayString } = authReducer;
  return {
    userPayString,
  };
}

export default connect(mapStateToProps, null)(WithdrawModal);
