import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import { handleBackgroundClick } from "../../utils/common";
import ButtonSecondary from "../ButtonSecondary";
import ButtonPrimary from "../ButtonPrimary";

const OtpModal = ({
  isOpen,
  onClose,
  onOtpSubmit,
  loading,
  title="Enter OTP",
  subTitle = "Please enter OTP",
  bottomMessage = ""
}) => {
  const [otp, setOtp] = useState("");

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  console.log(matches,'ocnewinwjce')


  const otpSubmit = async () => {
    onOtpSubmit(otp);
  };

  const handleClose = (event, reaon) => {
    handleBackgroundClick(reaon, onClose, false);
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={"xs"}
      onClose={handleClose}
      scroll={"paper"}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        <Stack direction={"column"}>
         {title}
          <Typography variant="caption">
           {subTitle}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{ paddingTop: "5px !important", justifyContent: "center" }}
      >
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          containerStyle={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
          inputStyle={{
            width: matches ? "3rem" : "2rem",
            height: matches ? "3rem" : "2rem",
            margin: "0 5px",
            fontSize: "1rem",
            borderRadius: "8px",
            border: "1px solid #C4C4C4",
          }}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
        {bottomMessage}
      </DialogContent>
      <DialogActions
        sx={{
          paddingX: "24px",
          paddingBottom: "15px",
          gap:1
        }}
      >
        <ButtonSecondary disabled={loading} onClick={handleClose}>
          Cancel
        </ButtonSecondary>
        <ButtonPrimary type="submit" loading={loading} onClick={otpSubmit}>
          Next
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};

function mapStateToProps({ authReducer }) {
  const { userPayString } = authReducer;
  return {
    userPayString,
  };
}

export default connect(mapStateToProps, null)(OtpModal);
