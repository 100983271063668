import axios from "axios";
import React, { useEffect, useState } from "react";
import { async } from "validate.js";
import { COUNTRY_LIST, CATEGORY_LIST, OCCUPATION_LIST } from "../../ApiUrl";
import { authApi } from "../../redux/api/apiConfig";
import Signin from "./Signin";
import Signup from "./Signup";
import { toast } from "react-toastify";

const Authentication = () => {
  const [openPage, setopenPage] = useState("signin");
  const [countryList, setCountryList] = useState(
    JSON.parse(localStorage.getItem("country-list")) || []
  );
  const [categoryList, setCategoryList] = useState(
    JSON.parse(localStorage.getItem("category-list")) || []
  );

  const [occupationList, setOccupationList] = useState(
    JSON.parse(localStorage.getItem("category-list")) || []
  );

  const changepage = (p) => {
    setopenPage(p);
  };

  useEffect(() => {
    getCountryList();
    getCategoryList();
    getOccupationList();
  }, []);

  const getCountryList = () => {
    authApi
      .get(COUNTRY_LIST)
      .then((response) => {
        const {
          data: { data },
        } = response || { data: { data: {} } };
        setCountryList(data);
        localStorage.setItem("country-list", JSON?.stringify(data));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Something went wrong");
      });
  };

  const getOccupationList = async () => {
    const response = await axios.get(OCCUPATION_LIST);
    const {
      data: { data },
    } = response;

    console.log(data);
    setOccupationList(data);
    localStorage.setItem("occupation-list", JSON.stringify(data));
  };

  const getCategoryList = () => {
    authApi
      .get(CATEGORY_LIST)
      .then((response) => {
        const {
          data: { data },
        } = response || { data: { data: {} } };
        setCategoryList(data);
        localStorage.setItem("category-list", JSON.stringify(data));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Something went wrong");
      });
  };

  return (
    <>
      {openPage === "signin" && <Signin changepage={changepage} />}
      {openPage === "signup" && (
        <Signup
          countryList={countryList}
          changepage={changepage}
          categoryList={categoryList}
          occupationList={occupationList}
        />
      )}
    </>
  );
};

export default Authentication;
